import {
  ServiceCenterAddress,
  ServiceCenterBusinessHours,
  ServiceCenterContact,
  ServiceCenterGeneralData,
} from '@/components/reseller-details';
import { Flex } from '@grupoboticario/flora-react';

const ServiceCenterOverview = (): JSX.Element => (
  <Flex direction="column" gap="$6">
    <ServiceCenterGeneralData />
    <ServiceCenterContact />
    <ServiceCenterAddress />
    <ServiceCenterBusinessHours />
  </Flex>
);

export { ServiceCenterOverview };
