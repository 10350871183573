import { Flex } from '@grupoboticario/flora-react';
import {
  CheckCircleIcon,
  CrossCircleIcon,
  ExclamationMarkChatBubbleIcon,
  PencilIcon,
} from '@grupoboticario/flora-react-icons';
import { ResellerDocumentEnum, ResellerDocumentStatusEnum } from '@/types';
import { useResellerDetails } from '@/contexts';
import { useResellerDataEditEnabler } from '@/components/reseller-details/hooks';
import { EditDocumentStatus, EditResellerDrawerFooter } from '@/components/reseller-details/';
import { DocumentStatusValueLabelMap, DocumentValueLabelMap } from '@/components/reseller-details/labels';
import { DataWrapper, DataWrapperButton, InfoWrapper } from '@/components/shared';
import { events, useEventTracker } from '@/api';

const documentStatusIcon = (status: ResellerDocumentStatusEnum) => {
  switch (status) {
    case ResellerDocumentStatusEnum.CHECKED:
      return <CheckCircleIcon color="$success-standard" size="16" />;
    case ResellerDocumentStatusEnum.PENDING:
      return <ExclamationMarkChatBubbleIcon color="$alert-standard" size="16" />;
    default:
      return <CrossCircleIcon color="$error-standard" size="16" />;
  }
};
// ver memo
export const ResellerDocumentStatus = (): JSX.Element => {
  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();
  const { resellerRegistry, resellerDocumentStatus } = useResellerDetails();
  const { clickedResellerInfoButtons } = useEventTracker();
  const attendanceInfoData = {
    geraId: resellerRegistry.data?.geraId,
    socialName: resellerRegistry.data?.socialName,
    cpCs: resellerRegistry.data?.commercialStructure?.parentStructure.name,
  };

  const getDocumentsData = resellerDocumentStatus.data?.documentos.filter(document => {
    return [ResellerDocumentEnum.ADDRESS_PROOF, ResellerDocumentEnum.CPF, ResellerDocumentEnum.RG].includes(
      document.tipoDocumento,
    );
  });

  const documentsData = getDocumentsData?.map(document => ({
    document: DocumentValueLabelMap[document.tipoDocumento],
    icon: documentStatusIcon(document.status),
    status: DocumentStatusValueLabelMap[document.status],
  }));

  const wrapperButtons: DataWrapperButton[] = [
    {
      label: 'Validar documentos',
      tooltipText: disabledButtonTooltip,
      disabled: isEditingDisabled,
      action: () => {
        clickedResellerInfoButtons({
          section: 'validar-documentos',
        });
        events.emit('OPEN_DRAWER_INFO', {
          size: 'medium',
          title: 'Valide os documentos do cadastro:',
          content: (
            <EditDocumentStatus attendanceInfo={attendanceInfoData} initialDocumentStatusData={getDocumentsData} />
          ),
          footer: <EditResellerDrawerFooter />,
        });
      },
      icon: <PencilIcon size={18} data-testid="icon-button" />,
    },
  ];

  return (
    <DataWrapper title="Status de validação cadastral" wrapperButtons={wrapperButtons}>
      <InfoWrapper isFullWidth>
        <Flex css={{ marginTop: '$2' }} direction="column" gap="$4" data-testid="list-item">
          {documentsData?.map(item => {
            return (
              <Flex
                align="center"
                justify="start"
                gap="$2"
                data-testid="document-status"
                key={`${item.document}__${item.status}`}
              >
                {item.icon}
                {`Envio do ${item.document} ${item.status}`}
              </Flex>
            );
          })}
        </Flex>
      </InfoWrapper>
    </DataWrapper>
  );
};
