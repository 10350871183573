export const formatPhoneNumber = (phoneNumber: string | undefined) => {
  const onlyDigitsNumber = phoneNumber?.replace(/\D+/g, '');

  if (onlyDigitsNumber?.length === 10) {
    return onlyDigitsNumber?.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3');
  }

  if (onlyDigitsNumber?.length === 11) {
    return onlyDigitsNumber.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, '($1) $2$3-$4');
  }

  return phoneNumber;
};
