import { fetcher } from '@/api';
import { ResellerRiskAnalysisResponse, RiskAnalysis } from '@/types';
import useSWR from 'swr';

interface GetRiskAnalysisResponse {
  cpf: string;
  riskAnalysis: RiskAnalysis;
}

async function getRiskAnalysis(cpf: string | undefined): Promise<GetRiskAnalysisResponse> {
  const response = await fetcher.post('risks', { json: { cpf } });
  return response.json();
}

export function useGetResellerRisk(cpf: string): ResellerRiskAnalysisResponse {
  const { data, error } = useSWR<GetRiskAnalysisResponse>(cpf ? ['risks', cpf] : null, () => getRiskAnalysis(cpf), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  return {
    isLoading: !data && !error,
    riskAnalysis: data?.riskAnalysis,
    error,
  };
}
