import { InstallmentConditionData, InstallmentCondition } from '@/types';
import { formatCurrency } from '@/utils';

export const getInstallmentConditionTexts = (installmentConditionData: InstallmentConditionData) => {
  const { condition, value } = installmentConditionData;
  const formattedValue = formatCurrency(Number(value));
  const maximumInstallments = installmentConditionData.maximumInstallments ?? 1;
  const maximumInstallmentsText = `Em até ${maximumInstallments} vezes`;

  if (condition === InstallmentCondition.INDEPENDENT) {
    return {
      conditionText: 'Independente do valor de compra',
      maximumInstallmentsText,
    };
  }

  if (condition === InstallmentCondition.PURCHASES_FROM) {
    return {
      conditionText: `Compras a partir de ${formattedValue}`,
      maximumInstallmentsText,
    };
  }

  if (condition === InstallmentCondition.PURCHASES_UNTIL) {
    return {
      conditionText: `Compras até ${formattedValue}`,
      maximumInstallmentsText,
    };
  }

  return undefined;
};
