import { Button, Flex, Text, Toaster, useClipboard } from '@grupoboticario/flora-react';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

interface ProductListRowProps {
  text: string;
  rowIndex: number;
}

const ProductListRow = ({ text, rowIndex }: ProductListRowProps): JSX.Element => {
  const clipboard = useClipboard();
  const isEvenRow = rowIndex % 2 === 0;

  const onCopy = () => {
    clipboard.copy(text);

    Toaster.notify({
      kind: 'success',
      duration: 3000,
      button: 'Fechar',
      closeWhenClick: true,
      description: `Dado copiado`,
      origin: 'right-top',
    });
  };

  return (
    <Flex
      data-testid={`product-list-row__${rowIndex}`}
      data-even={isEvenRow}
      css={{
        height: '$14',
        backgroundColor: isEvenRow ? '$light-dark-1' : undefined,
        borderRadius: '10px',
        px: '$8',
      }}
      gap="$2"
      align="center"
    >
      <Text size="bodyLargeShortRegular">{text}</Text>
      <Button
        hierarchy="tertiary"
        has="iconOnly"
        data-testid={`product-list-row-copy-btn__${rowIndex}`}
        css={{
          marginLeft: '$1',
          width: '$5',
          height: '$5',
          borderRadius: '$pill',
        }}
        icon={
          <TwoPiledSquaresIcon
            css={{
              color: '$brand-1',
              '& svg': {
                width: '$4',
              },
            }}
          />
        }
        onClick={onCopy}
        aria-label="Copiar produto"
      />
    </Flex>
  );
};

export { ProductListRow };
