import { ProductCategory } from '@/types';

export const productCategoryValueLabelMap: {
  [key in ProductCategory]: string;
} = Object.freeze({
  [ProductCategory.PERFUMARIA]: 'Perfumaria',
  [ProductCategory.MAQUIAGEM]: 'Maquiagem',
  [ProductCategory.CORPO_E_BANHO]: 'Corpo e Banho',
  [ProductCategory.CABELOS]: 'Cabelos',
  [ProductCategory.PELE]: 'Pele',
});
