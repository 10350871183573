import { Box, Button, Flex, Tag, Text, TagProps } from '@grupoboticario/flora-react';
import { CrossIcon, ShoppingBagIcon } from '@grupoboticario/flora-react-icons';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Service, ServiceStatus } from '@/types';
import { events, useHandleCloseService } from '@/api';
import { nameHandler } from '@/utils';
import { Avatar } from '@/components';

interface UserListItemProps {
  user: Service;
  isSelected?: boolean;
}

const UserListItem = ({ user, isSelected }: UserListItemProps) => {
  const itemRef = useRef<HTMLLIElement | null>(null);
  const { _id: serviceId, status, reseller } = user;
  const { handleCloseService } = useHandleCloseService({
    serviceId,
    serviceStatus: status,
    geraId: reseller.geraId,
    registryName: reseller.name,
  });

  const navigate = useNavigate();
  const tagShape = isSelected ? undefined : 'ghost';
  const tagVariant: TagProps['variant'] = isSelected ? 'disabled' : 'assorted9';

  const onItemClick = useCallback(() => {
    navigate(`/inicios/atendimento/${serviceId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, reseller.geraId, serviceId]);

  useEffect(() => {
    events.on('SELECT_RESELLER_IN_LIST', (resellerId: string) => {
      if (reseller.geraId === resellerId && itemRef.current?.scrollIntoView) {
        itemRef.current?.scrollIntoView();
      }
    });

    return () => {
      events.off('SELECT_RESELLER_IN_LIST');
    };
  }, [reseller.geraId]);

  return (
    <Flex
      as="li"
      align="center"
      data-testid="user-list-item"
      css={{
        background: isSelected ? '$backgroundPrimary' : '$bax',
        border: isSelected ? '1px solid transparent' : '1px solid $nonInteractiveAltAuxiliar',
        borderLeftColor: isSelected ? '$8' : undefined,
        borderLeftWidth: '4px',
        borderRadius: '$small',
        cursor: 'pointer',
        height: '4.5rem',
        position: 'relative',
        transition: 'background-color 100ms ease',
        minWidth: '0',
        width: '100%',
        padding: '$2 0 $2 $3',
      }}
    >
      <Flex
        align="center"
        css={{ width: '100%' }}
        onClick={onItemClick}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={itemRef}
      >
        <Avatar name={reseller.name} variant="ghost" />
        <Flex
          direction="column"
          justify="space-between"
          css={{
            flexGrow: 1,
            marginLeft: '$4',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Text
            color="$nonInteractivePredominant"
            size="bodyLargeShortRegular"
            isTruncated
            css={{
              textTransform: 'lowercase',
              '&::first-letter': {
                textTransform: 'uppercase',
              },
              '&::first-line': {
                textTransform: 'capitalize',
              },
            }}
          >
            {nameHandler(reseller.name)}
          </Text>
          <Box css={{ marginBottom: '$1' }}>
            <Tag size="small" shape={tagShape} variant={tagVariant}>
              {reseller.geraId}
            </Tag>
            {status === ServiceStatus.IN_ATTENDANCE && (
              <Tag size="small" shape={tagShape} variant={tagVariant} css={{ marginLeft: '$3' }}>
                Em atendimento
              </Tag>
            )}
          </Box>
        </Flex>
        <Flex
          gap="$4"
          css={{
            color: isSelected ? '$success-standard' : '$nonInteractiveAltAuxiliar',
            marginLeft: 'auto',
            padding: '0 $8',
          }}
        >
          {reseller.hasFirstOrder && (
            <ShoppingBagIcon
              data-testid="first-order-completed"
              css={{ color: isSelected ? '$success-standard' : undefined }}
              aria-labelledby="Primeiro pedido realizado."
            />
          )}
        </Flex>
      </Flex>
      <Button
        css={{
          background: 'transparent',
          color: '$nonInteractiveAuxiliar',
          height: '$3',
          padding: '$3',
          position: 'absolute',
          right: '$1',
          top: '$1',
          width: '$3',
        }}
        hierarchy="tertiary"
        has="iconOnly"
        icon={
          <CrossIcon
            css={{
              '& svg': {
                width: '$4',
              },
            }}
          />
        }
        aria-label="Fechar contato"
        onClick={handleCloseService}
      />
    </Flex>
  );
};

export { UserListItem };
