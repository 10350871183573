import { rest } from 'msw';
import { postalServiceMockData, postalServiceWithOutStreetAndDistrictMockData } from '@/mocks/data';
import { apiPath } from '@/mocks/utils/apiPath';
import { delayedResponse } from '@/mocks/utils/delayedResponse';

const getPostalService = rest.get(apiPath('v1/utils/postalservice'), (req, _res, ctx) => {
  if (req.url.searchParams.get('zipCode') === '50050050') {
    return delayedResponse(ctx.status(500), ctx.json({ message: 'Request failed with status code 500' }));
  }

  if (req.url.searchParams.get('zipCode') === '40040040') {
    return delayedResponse(ctx.status(400), ctx.json({ message: 'Request failed with status code 400' }));
  }

  if (req.url.searchParams.get('zipCode') === '13290000') {
    return delayedResponse(ctx.status(200), ctx.json(postalServiceWithOutStreetAndDistrictMockData));
  }

  return delayedResponse(ctx.status(200), ctx.json(postalServiceMockData));
});

export const postalService = [getPostalService];
