import { InfoWrapper } from '@/components/shared';
import { Text } from '@grupoboticario/flora-react';
import { RiskAnalysis } from '@/types';
import { useResellerDetails } from '@/contexts';
import { RiskAnalysisTag } from '@/components';

export const RiskAnalysisTagInfo = () => {
  const { riskAnalysis } = useResellerDetails().riskAnalysisData;

  return (
    <InfoWrapper>
      <Text size="exceptionsRestrictedRegular" color="$nonInteractiveAuxiliar">
        Risco
      </Text>
      <RiskAnalysisTag riskAnalysis={riskAnalysis ?? RiskAnalysis.NOT_PROCESSED} />
    </InfoWrapper>
  );
};
