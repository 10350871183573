import { InstallmentConditionData, PaymentLocationInfo } from '@/types';
import { sortInstallmentConditions } from '@/components/reseller-details/';
import { getInstallmentConditionTexts } from '../get-installment-condition-texts';
import { paymentLocationValueLabelMap } from '@/components/reseller-details/labels';

export const installmentOptionsClipboardText = (installmentOptions: InstallmentConditionData[] | undefined) => {
  if (!installmentOptions || installmentOptions.length === 0) {
    return '';
  }
  const installmentOptionsText = installmentOptions
    .filter(({ condition }) => condition !== undefined)
    .sort(sortInstallmentConditions)
    .map(installmentConditionData => {
      const installmentConditionText = getInstallmentConditionTexts(installmentConditionData);
      if (installmentConditionText === undefined) {
        return '';
      }
      return `${
        installmentConditionText.conditionText
      } ${installmentConditionText.maximumInstallmentsText.toLowerCase()}`;
    })
    .join('\r\n');
  return `Condições especiais de parcelamento:\r\n${installmentOptionsText}`;
};

export const generalPaymentConditionsClipboardText = (
  paymentLocations: PaymentLocationInfo[] | undefined,
  cardOption: 'crédito' | 'débito',
) => {
  if (!paymentLocations || paymentLocations.length === 0) {
    return '';
  }
  const paymentLocationsToCopyText = paymentLocations?.map(condition => paymentLocationValueLabelMap[condition]);
  return paymentLocationsToCopyText
    ? `Condições gerais para pagamento por cartão de ${cardOption}:\r\n${paymentLocationsToCopyText.join('\r\n')}`
    : '';
};

export const notesClipBoardText = (note: string | undefined) => {
  return note ? `Observações:\r\n${note}` : '';
};
