import { Box, Flex, Grid, Tag, Text, useMediaQuery } from '@grupoboticario/flora-react';
import { MouseEventHandler } from 'react';
import { Avatar, ResellerTagStatus } from '@/components';
import { ResellerVO } from '@/types';
import { formatCpf } from '@/utils';

interface SearchResultItemProps {
  resultData: ResellerVO;
  onClick: MouseEventHandler<HTMLElement>;
}

const SearchResultItem = ({ resultData, onClick }: SearchResultItemProps): JSX.Element => {
  const isLargeViewPort = useMediaQuery('(max-width: 1200px)');
  const { cpf, email, geraId, name, hasFirstOrder } = resultData;
  const hasTagStatus = !!hasFirstOrder;

  return (
    <Box
      as="li"
      role="listitem"
      onClick={onClick}
      css={{
        padding: '$4 $3',
      }}
    >
      <Grid templateColumns={!isLargeViewPort ? '24px 1fr' : '1fr'}>
        {!isLargeViewPort && <Avatar name={name} size="24px" variant="ghost" textSize="auxiliarMini" />}
        <Text
          size="bodyLargeShortRegular"
          css={{
            color: '$brand-1',
            marginLeft: '0',
            textTransform: 'lowercase',
            '&::first-letter': {
              textTransform: 'uppercase',
            },
            '&::first-line': {
              textTransform: 'capitalize',
            },
            '@fullscreen': {
              marginLeft: '$2',
            },
          }}
        >
          {name}
        </Text>
      </Grid>
      <Flex
        direction={isLargeViewPort ? 'column' : 'inherit'}
        css={{
          margin: '$2 0 0 0',
          '@fullscreen': {
            margin: '$2 0 $2 $8',
          },
        }}
      >
        <Tag
          size="small"
          shape="ghost"
          css={{
            padding: '$2',
            width: 'fit-content',
          }}
        >
          {geraId}
        </Tag>
        <Text
          color="$nonInteractiveAuxiliar"
          size="auxiliarMini"
          css={{
            margin: '$2 0 0',
            '@fullscreen': {
              margin: '0 0 0 $2',
            },
          }}
        >
          CPF {formatCpf(cpf)}
        </Text>
      </Flex>

      <Text
        color="$nonInteractiveAuxiliar"
        size="auxiliarMini"
        css={{
          marginLeft: '0',
          textTransform: 'lowercase',
          '@fullscreen': {
            marginLeft: '$8',
          },
        }}
      >
        {email}
      </Text>
      {hasTagStatus && (
        <Box
          css={{
            margin: '$4 $4 0 0',
            '@fullscreen': {
              margin: '$4 $4 0 $8',
            },
          }}
        >
          <ResellerTagStatus hasFirstOrder={hasFirstOrder} />
        </Box>
      )}
    </Box>
  );
};

export { SearchResultItem };
