import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type TabType = 'tab' | 'sub-tab';
interface GTMTrackerState {
  assistantID: string;
  intentionID: string;
  sessionTabName: string;
  setSessionTabName: (tabName: string, tabType?: TabType) => void;
  setAssistantID: (assistantID: string) => void;
  setIntentionID: (intentionID: string) => void;
}

export const useGTMTrackerStore = create<GTMTrackerState>()(
  devtools(set => ({
    assistantID: '',
    intentionID: '',
    sessionTabName: 'tab:dados-da-ir',
    setSessionTabName: (tabName: string, tabType: TabType = 'tab') => {
      if (tabType === 'sub-tab') {
        return set({ sessionTabName: `sub-tab:${tabName}` });
      }
      set({ sessionTabName: `tab:${tabName}` });
    },

    setAssistantID: (assistantID: string) => set({ assistantID }),
    setIntentionID: (intentionID: string) => set({ intentionID }),
  })),
);
