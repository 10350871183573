import { cleanupSpecialCharacters, formatCnpj } from '@/utils';
import { formatCpf } from '../formatCpf/formatCpf';

const CPF_LENGTH = 11;

export const formatCpfOrCnpj = (cpfOrCnpj: string | undefined) => {
  if (!cpfOrCnpj) {
    return cpfOrCnpj;
  }
  const cleanCpfCnpj = cleanupSpecialCharacters(cpfOrCnpj);

  return cleanCpfCnpj.length > CPF_LENGTH ? formatCnpj(cpfOrCnpj) : formatCpf(cpfOrCnpj);
};
