import * as yup from 'yup';
import { HTTPError } from 'ky';
import { searchReseller } from '@/api';
import { SearchType } from '@/types';
import { PhoneNumber, ContactFormData } from '../../../types';

const INPUT_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const EMAIL_ERROR_MESSAGE = 'Por favor, utilize um e-mail válido';
const PHONE_ERROR_MESSAGE = 'Por favor, utilize um celular válido';
const EMAIL_NOT_AVAILABLE_MESSAGE = 'E-mail já cadastrado para outra IR ou RE.';
const MIN_PHONES_CHARACTERS = 10;

async function checkEmailAvailability(email: string, resolve: (value: boolean) => void, geraId: string) {
  try {
    const foundResellerByEmail = (await searchReseller(email, SearchType.EMAIL)).find(
      reseller => reseller.email === email,
    );

    if (foundResellerByEmail?.geraId === geraId) {
      resolve(true);
    }
  } catch (error) {
    if (error && (error as HTTPError).response.status === 404) {
      resolve(true);
    }
  }
  resolve(false);
}

const phoneFormSchema: yup.ObjectSchema<PhoneNumber> = yup.object({
  number: yup.string().required(INPUT_ERROR_MESSAGE).min(MIN_PHONES_CHARACTERS, PHONE_ERROR_MESSAGE),
  smsAccepted: yup.boolean().optional(),
});

// ignorando TS por problema de validação do schema https://github.com/jquense/yup/issues/959
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editResellerContactFormSchema: yup.ObjectSchema<ContactFormData> = yup.object({
  mobilePhoneNumber: yup.object({ ...phoneFormSchema.fields }) as yup.ObjectSchema<PhoneNumber>,
  homePhoneNumber: yup.object({ ...phoneFormSchema.fields }) as yup.ObjectSchema<PhoneNumber>,
  email: yup
    .string()
    .email(EMAIL_ERROR_MESSAGE)
    .required(INPUT_ERROR_MESSAGE)
    .test({
      message: EMAIL_NOT_AVAILABLE_MESSAGE,
      test(value: string | undefined) {
        if (!value) {
          return false;
        }
        return new Promise(resolve => {
          checkEmailAvailability(value, resolve, this.parent.resellerGeraId);
        });
      },
    }),
});
