import { PaymentLocationGeneralConditions } from '@/components/reseller-details';
import { DataWrapper, NoteField } from '@/components/shared';
import { CashPayment } from '@/types';

interface CashPaymentViewProps {
  cashPaymentData: CashPayment;
}

const CashPaymentView = ({ cashPaymentData }: CashPaymentViewProps) => {
  const { paymentLocations, note } = cashPaymentData;

  return (
    <DataWrapper title="Dinheiro" data-testid="cash-payment">
      {paymentLocations && paymentLocations?.length > 0 && (
        <PaymentLocationGeneralConditions conditions={paymentLocations} />
      )}
      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { CashPaymentView };
