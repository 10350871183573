import { RequirementsList } from '@/components/reseller-details';
import { paymentLocationValueLabelMap } from '@/components/reseller-details/labels';
import { PaymentLocationInfo } from '@/types';

interface PaymentLocationGeneralConditionsProps {
  conditions: PaymentLocationInfo[] | undefined;
}

const PaymentLocationGeneralConditions = ({
  conditions = [],
}: PaymentLocationGeneralConditionsProps): JSX.Element | null => {
  const paymentLocationsData = conditions.map(condition => ({
    text: paymentLocationValueLabelMap[condition],
  }));

  return paymentLocationsData.length > 0 ? (
    <RequirementsList title="Condições Gerais" listItems={paymentLocationsData} />
  ) : null;
};

export { PaymentLocationGeneralConditions };
