import { InstructionBox } from '@/components/reseller-details';
import { TextInfo } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { Flex } from '@grupoboticario/flora-react';
import { CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterDocumentation = (): JSX.Element => {
  const { csData } = useResellerServiceCenter();
  const { documentationNote, hasAdditionalDocumentation } = csData.documentation;

  const REQUIRED_DOCUMENT_TEXT = 'A Central de Serviços solicita documentação adicional para inicio da IR.';
  const NOT_REQUIRED_DOCUMENT_TEXT = 'A Central de Serviços não solicita documentação adicional para inicio da IR.';

  const requiredDocumentProps = () => {
    if (hasAdditionalDocumentation) {
      return {
        title: REQUIRED_DOCUMENT_TEXT,
        icon: <CheckCircleIcon data-testid="check-circle-icon-testid" size="32" color="$8" />,
        tagTitle: 'Obrigatório',
      };
    }
    return {
      title: NOT_REQUIRED_DOCUMENT_TEXT,
      icon: <CrossCircleIcon data-testid="cross-circle-icon-testid" size="32" color="$1" />,
    };
  };

  return (
    <Flex direction="column" gap="$6">
      <InstructionBox isFullWidth {...requiredDocumentProps()} />
      {hasAdditionalDocumentation ? (
        <TextInfo title="Documentação Necessária" text={documentationNote} isFullWidth canCopyToClipboard />
      ) : null}
    </Flex>
  );
};

export { ServiceCenterDocumentation };
