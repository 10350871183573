import { NOT_SPECIFIED } from '@/components/reseller-details/';
import { TextInfo } from '@/components/shared';
import { Address } from '@/types';
import { formatCep } from '@/utils';

interface ResellerAddressProps {
  address?: Address;
  addressHeaderLabel?: 'Residencial' | 'Entrega';
}

const ResellerAddress = ({ address, addressHeaderLabel }: ResellerAddressProps): JSX.Element => (
  <>
    <TextInfo headerText={addressHeaderLabel} isFullWidth title="CEP" text={formatCep(address?.zipCode)} />
    <TextInfo isCapitalized title="Logradouro" text={address?.street} />
    <TextInfo title="Número" text={address?.number} />
    <TextInfo isCapitalized title="Bairro" text={address?.landmark} />
    <TextInfo isCapitalized title="Complemento" text={address?.complement} fallbackText={NOT_SPECIFIED} />
    <TextInfo isCapitalized title="Cidade" text={address?.city} />
    <TextInfo title="Estado" text={address?.state} />
    <TextInfo isFullWidth title="Ponto de referência" text={address?.reference} fallbackText={NOT_SPECIFIED} />
  </>
);

export { ResellerAddress };
