import React from 'react';
import { Box, CSS } from '@grupoboticario/flora-react';

interface InfoWrapperProps {
  isFullWidth?: boolean;
  children: React.ReactNode;
  css?: CSS;
}

const InfoWrapper = ({ children, isFullWidth, css }: InfoWrapperProps): JSX.Element => (
  <Box
    css={{
      backgroundColor: '$backgroundPrimary',
      borderRadius: '$medium',
      gridColumn: isFullWidth ? '1 / span 2' : 'unset',
      padding: '$3 $8',
      width: '100%',
      ...css,
    }}
  >
    {children}
  </Box>
);

export { InfoWrapper };
