import { DropdownItemsProps } from '@/components';
import { Dropdown, DropdownItem, DropdownProps } from '@grupoboticario/flora-react';
import { Controller, useFormContext } from 'react-hook-form';

interface ControlledSelectProps extends DropdownProps {
  registerName: string;
  dropdownItems: DropdownItemsProps[];
  onCheckedChange?: (value: boolean) => void;
  required?: boolean;
}
const ControlledSelect = ({
  registerName,
  dropdownItems,
  defaultValue = '',
  required = false,
  ...rest
}: ControlledSelectProps): JSX.Element => {
  const { control } = useFormContext();

  return (
    <Controller
      name={registerName}
      control={control}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <Dropdown
          invalid={!!fieldState.error}
          data-testid={`dropdown-${registerName}`}
          required={required}
          {...rest}
          {...field}
        >
          {dropdownItems.map(item => (
            <DropdownItem key={item.key} value={item.value} checked>
              {item.name}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    />
  );
};

export { ControlledSelect };
