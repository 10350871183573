import { events, useEventTracker } from '@/api';
import {
  useResellerDataEditEnabler,
  EditResellerDrawerFooter,
  EditResellerName,
  NOT_SPECIFIED,
  ResellerNameInfo,
  RiskAnalysisTagInfo,
  resellerOriginValueLabelMap,
} from '@/components/reseller-details/';
import { DataWrapper, DataWrapperButton, InfoWrapper, TextInfo } from '@/components/shared';
import { useResellerDetails } from '@/contexts';
import { capitalizeText, cleanupSpecialCharacters, formatCpf, formatCurrency } from '@/utils';
import { Button, Flex, Grid, useToggle } from '@grupoboticario/flora-react';
import { PencilIcon, ArrowUpIcon, ArrowDownIcon } from '@grupoboticario/flora-react-icons';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

const buttonIconCss = { '> svg': { width: '$4', height: '$4' } };
const formatDate = (isoDateToFormat: string | undefined) => {
  if (!isoDateToFormat) {
    return undefined;
  }

  const timeZone = 'UTC';
  const dateObj = parseISO(isoDateToFormat);
  const zonedDate = utcToZonedTime(dateObj, timeZone);

  return format(zonedDate, 'dd/MM/yyyy', { timeZone });
};

const ResellerGeneralData = (): JSX.Element => {
  const [isOpen, toggle] = useToggle(false);
  const { data } = useResellerDetails().resellerRegistry;
  const { data: creditData } = useResellerDetails().resellerCredit;
  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();
  const { clickedResellerInfoButtons } = useEventTracker();

  const attendanceInfoData = {
    geraId: data?.geraId,
    socialName: data?.socialName,
    cpCs: data?.commercialStructure?.parentStructure.name,
  };

  const resellerNames = {
    name: capitalizeText(data?.name ?? ''),
    socialName: capitalizeText(data?.socialName ?? ''),
  };

  const handleSeeMoreClickButton = () => {
    toggle();
    clickedResellerInfoButtons({
      section: 'mostrar-mais-identificacao',
    });
  };

  const wrapperButtons: DataWrapperButton[] = [
    {
      label: 'Alterar dados',
      tooltipText: disabledButtonTooltip,
      disabled: isEditingDisabled,
      action: () => {
        clickedResellerInfoButtons({
          section: 'alterar-dados-nome',
        }),
          events.emit('OPEN_DRAWER_INFO', {
            size: 'medium',
            title: 'Altere os dados de nome:',
            content: <EditResellerName attendanceInfo={attendanceInfoData} resellerNames={resellerNames} />,
            footer: <EditResellerDrawerFooter />,
          });
      },
      icon: <PencilIcon size={18} data-testid="icon-button" />,
    },
  ];

  return (
    <>
      <DataWrapper title="Nome" data-testid="reseller-general-data-name" wrapperButtons={wrapperButtons}>
        <ResellerNameInfo name={data?.socialName as string} />
        <TextInfo
          helpTooltip="Nome presente nos registros oficiais. Se necessário, utilize este dado para validação cadastral."
          title="Nome de Registro"
          text={data?.name}
          isFullWidth
          isCapitalized
        />
      </DataWrapper>

      <DataWrapper title="Identificação" data-testid="reseller-general-data-identification">
        <InfoWrapper css={{ backgroundColor: 'unset', padding: 0 }} isFullWidth>
          <Grid templateColumns="repeat(3, 1fr)" gap="0.125rem">
            <TextInfo canCopyToClipboard title="Código" text={data?.geraId} />
            <TextInfo title="Crédito" text={formatCurrency(creditData?.availableCreditLimit)} />
            <RiskAnalysisTagInfo />
          </Grid>
        </InfoWrapper>

        <TextInfo
          isCapitalized
          canCopyToClipboard
          title="CP / CS"
          text={data?.commercialStructure?.parentStructure.name}
        />
        <TextInfo title="Origem" text={resellerOriginValueLabelMap[data?.registrationOrigin]} />
        {isOpen && (
          <>
            <TextInfo
              title="CPF"
              text={formatCpf(data?.cpf)}
              canCopyToClipboard
              textToCopy={cleanupSpecialCharacters(data?.cpf ?? '')}
            />
            <TextInfo title="RG" text={data?.rg} />
            <TextInfo title="Data de Nascimento" fallbackText={NOT_SPECIFIED} text={formatDate(data?.birthday)} />
            <TextInfo title="Data do Cadastro" fallbackText={NOT_SPECIFIED} text={formatDate(data?.registrationDate)} />
          </>
        )}
      </DataWrapper>
      <Flex justify="center">
        <Button
          hierarchy="secondary"
          data-testid="general-data-toggle"
          size="small"
          onClick={handleSeeMoreClickButton}
          has="iconRight"
          icon={isOpen ? <ArrowUpIcon css={buttonIconCss} /> : <ArrowDownIcon css={buttonIconCss} />}
        >
          {isOpen ? 'Mostrar menos' : 'Mostrar mais'}
        </Button>
      </Flex>
    </>
  );
};

export { ResellerGeneralData };
