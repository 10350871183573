import { PostalServiceData } from '@/types';

export const postalServiceMockData: PostalServiceData = {
  streetAddress: 'Rua João Cassimiro Magalhães Glória',
  district: 'Parque Mandaqui',
  addressLocality: 'São Paulo',
  addressRegion: 'SP',
  postalCode: '02420050',
  localityCode: 3550308,
  capital: true,
  macroRegion: 'Sudeste',
  geolocation: {
    latitude: '-23.4755041',
    longitude: '-46.6376103',
  },
  links: [
    {
      rel: 'self',
      href: 'http://api-gateway.blz.eksprod.io/postal-service/addresses?postalCode=02420050',
    },
  ],
};

export const postalServiceWithOutStreetAndDistrictMockData: PostalServiceData = {
  streetAddress: '',
  district: '',
  addressLocality: 'São Paulo',
  addressRegion: 'SP',
  postalCode: '02420050',
  localityCode: 3550308,
  capital: true,
  macroRegion: 'Sudeste',
  geolocation: {
    latitude: '-23.4755041',
    longitude: '-46.6376103',
  },
  links: [
    {
      rel: 'self',
      href: 'http://api-gateway.blz.eksprod.io/postal-service/addresses?postalCode=02420050',
    },
  ],
};
