import { useRequest } from '@/api';
import { ResellerRegistryResponse, ResellerRegistry, resellerRegistryKey } from '@/types';
import { mutate } from 'swr';

export function useGetResellerRegistry(geraId?: string): ResellerRegistryResponse {
  const { data, error } = useRequest<ResellerRegistry>(
    geraId ? [resellerRegistryKey, geraId] : null,
    `resellers/${geraId}/cadastro`,
  );

  return {
    isLoading: !data && !error,
    data,
    error,
    retry: () => mutate([resellerRegistryKey, geraId]),
  };
}
