import { InfoWrapper } from '@/components';
import { Box, Flex, Tag, TagProps, Text } from '@grupoboticario/flora-react';
import React from 'react';

interface InstructionBoxProps {
  isFullWidth?: boolean;
  icon?: React.ReactElement;
  tagTitle?: string;
  tagVariant?: TagProps['variant'];
  title: string;
}

const InstructionBox = ({
  isFullWidth = false,
  icon,
  tagVariant = 'alert',
  title,
  tagTitle,
}: InstructionBoxProps): JSX.Element => (
  <InfoWrapper isFullWidth={isFullWidth}>
    <Flex align="center" gapX="$3">
      {icon && <Box data-testid="instruction-box-icon">{icon}</Box>}
      <Text as="p" css={{ fontWeight: '$medium' }}>
        {title}
      </Text>
      {!!tagTitle?.length && (
        <Tag variant={tagVariant} css={{ fontWeight: '$medium' }}>
          {tagTitle}
        </Tag>
      )}
    </Flex>
  </InfoWrapper>
);

export { InstructionBox };
