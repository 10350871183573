import {
  DeliveryValuesAndConditions,
  RequirementsList,
  RequirementsListButtonLabel,
  TagList,
} from '@/components/reseller-details/';
import { deliveryThirdPartiesValueLabelMap } from '@/components/reseller-details/labels';
import { DataWrapper, InfoWrapper, NoteField, TextInfo } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { DeliveryCondition } from '@/types';
import { formatCurrency, generateSpecialConditionTitle } from '@/utils';
import { Flex, Text } from '@grupoboticario/flora-react';

interface SpecialConditionRangeTypes {
  hasFreeDelivery: boolean;
  minimumPurchaseValue: number;
  until: number | undefined;
  deliveryCost: number | undefined;
}

export const specialConditionsRange = (specialConditions: DeliveryCondition[] = []) => {
  const deliverySpecialConditions = specialConditions;
  deliverySpecialConditions.sort((a, b) => Number(a.minimumPurchaseValue) - Number(b.minimumPurchaseValue));
  const ranges = deliverySpecialConditions.map((element, index: number, array: DeliveryCondition[]) => {
    const untilValue = array[index + 1] ? Number(array[index + 1]?.minimumPurchaseValue) : undefined;

    const deliveryCostValue = element.deliveryCost ? Number(element.deliveryCost) : undefined;

    return {
      hasFreeDelivery: element.hasFreeDelivery,
      minimumPurchaseValue: Number(element.minimumPurchaseValue),
      until: untilValue,
      deliveryCost: deliveryCostValue,
    };
  });
  return ranges.reverse();
};

const buttonLabel: RequirementsListButtonLabel = {
  showMore: 'Mostrar mais condições',
  showLess: 'Mostrar menos condições',
};

const ServiceCenterDelivery = () => {
  const { csData } = useResellerServiceCenter();

  if (csData?.delivery === undefined) {
    return null;
  }

  const {
    hasFreeDelivery,
    hasFreeDeliveryOnFirstOrder,
    notAllowedDeliveryRegions,
    note: deliveryObservations,
    deliveryToThirdPartiesRules,
    specialConditions,
    deliveryCost,
  } = csData.delivery;

  const specialConditionsDeliveryRange = specialConditionsRange(specialConditions);

  const thirdPartyRules =
    deliveryToThirdPartiesRules?.map(observation => ({
      text: deliveryThirdPartiesValueLabelMap[observation],
    })) ?? [];

  return (
    <DataWrapper title="Entrega" data-testid="delivery-wrapper">
      {hasFreeDeliveryOnFirstOrder && <DeliveryValuesAndConditions deliveryTitle="Frete cortesia" />}
      {hasFreeDelivery && (
        <DeliveryValuesAndConditions
          deliveryTitle="Permite frete gratuito"
          normalDeliveryCost="Gratuito"
          specialDeliveryCost="Gratuito"
        />
      )}

      {!hasFreeDelivery && !specialConditions && (
        <>
          <TextInfo canCopyToClipboard title="Valor do frete normal" text={formatCurrency(Number(deliveryCost))} />
          <TextInfo canCopyToClipboard title="Valor do frete especial" text="" />
        </>
      )}

      {!hasFreeDelivery && specialConditionsDeliveryRange?.length === 1 && (
        <DeliveryValuesAndConditions
          deliveryTitle={`Permite frete ${
            specialConditionsDeliveryRange[0].hasFreeDelivery ? 'gratuito' : 'especial'
          } a partir de ${formatCurrency(Number(specialConditionsDeliveryRange[0].minimumPurchaseValue))}`}
          normalDeliveryCost={formatCurrency(Number(deliveryCost))}
          specialDeliveryCost={
            specialConditionsDeliveryRange[0].hasFreeDelivery
              ? 'Gratuito'
              : formatCurrency(Number(specialConditionsDeliveryRange[0].deliveryCost))
          }
        />
      )}

      {!hasFreeDelivery &&
        specialConditionsDeliveryRange.length > 1 &&
        specialConditionsDeliveryRange.map((condition: SpecialConditionRangeTypes) => {
          return (
            <DeliveryValuesAndConditions
              deliveryTitle={generateSpecialConditionTitle({
                higherRangeValue: condition.until,
                smallerRangeValue: condition.minimumPurchaseValue,
                hasFreeDeliveryOnSpecialCondition: condition.hasFreeDelivery,
              })}
              key={condition.deliveryCost}
              normalDeliveryCost={formatCurrency(Number(csData?.delivery?.deliveryCost))}
              specialDeliveryCost={formatCurrency(Number(condition.deliveryCost))}
            />
          );
        })}

      {notAllowedDeliveryRegions && (
        <InfoWrapper isFullWidth>
          <Text as="h6" size="exceptionsRestrictedRegular" color="$nonInteractiveAuxiliar" css={{ marginBottom: '$4' }}>
            Regiões não permitidas
          </Text>
          <Flex align="center" gap="$2" data-testid="blocked-regions">
            <TagList tagsValue={notAllowedDeliveryRegions ?? []} tagVariant="error" />
          </Flex>
        </InfoWrapper>
      )}

      <RequirementsList
        title="Documentação exigida em retirada por terceiros"
        listItems={thirdPartyRules}
        buttonLabel={buttonLabel}
        copyBlockToClipboard={{
          copyButtonLabel: 'Copiar documentos',
          copyTitleMessage: 'documentação exigida em retirada por terceiros',
          copyTextSuccess: 'Lista de documentos copiada',
        }}
      />

      {deliveryObservations && <NoteField text={deliveryObservations} />}
    </DataWrapper>
  );
};

export { ServiceCenterDelivery };
