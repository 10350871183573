import { Button, Flex, Text } from '@grupoboticario/flora-react';
import { ArrowDownIcon, ArrowUpIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { DataWrapper, InfoWrapper } from '@/components';
import { ProductListRow, useCopyDataWrapperInfo, useListContentHandler } from '@/components/reseller-details';

type ProductType = string;

interface ServiceCenterProductsListProps {
  products: ProductType[];
  title?: string;
}

const ServiceCenterProductList = ({
  products,
  title = 'Produtos permitidos',
}: ServiceCenterProductsListProps): JSX.Element => {
  const { isListExpanded, toggleListItems, itemsToShow, isListExpansible } =
    useListContentHandler<ProductType>(products);

  const { onCopy } = useCopyDataWrapperInfo({
    text: `Produtos permitidos:\r\n${(products ?? []).join('\r\n')}`,
    successMessage: 'Lista de produtos copiada',
    section: 'produtos',
  });

  return (
    <DataWrapper
      css={{ gridColumn: 'span 2' }}
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar produtos',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      <InfoWrapper isFullWidth>
        <Text size="exceptionsRestrictedRegular" color="$nonInteractiveAuxiliar">
          {title}
        </Text>
        <Flex css={{ marginTop: '$4' }} direction="column" data-testid="product-list">
          {itemsToShow.map((product, idx) => (
            <ProductListRow key={`product-list__${product}`} rowIndex={idx} text={product} />
          ))}
        </Flex>
        {isListExpansible && (
          <Flex css={{ marginTop: '$6' }} justify="center">
            <Button
              hierarchy="secondary"
              data-testid="product-list-toggle-btn"
              onClick={toggleListItems}
              icon={
                isListExpanded ? (
                  <ArrowUpIcon
                    size="16"
                    css={{
                      '& svg': {
                        width: '$4',
                      },
                    }}
                  />
                ) : (
                  <ArrowDownIcon
                    css={{
                      '& svg': {
                        width: '$4',
                      },
                    }}
                  />
                )
              }
              has="iconLeft"
            >
              {isListExpanded ? 'Mostrar menos produtos' : 'Mostrar mais produtos'}
            </Button>
          </Flex>
        )}
      </InfoWrapper>
    </DataWrapper>
  );
};

export { ServiceCenterProductList };
