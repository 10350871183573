import { Loading } from '@/components';
import {
  ServiceCenterOverview,
  ServiceCenterProductIncrement,
  ServiceCenterDeliveryAndPickUp,
  ServiceCenterLockAndLimit,
  ServiceCenterPaymentMethods,
  ResellerTabs,
  ErrorMessage,
  ServiceCenterDocumentation,
} from '@/components/reseller-details';
import { useResellerServiceCenter } from '@/contexts';
import { ResellerTab } from '@/types';

enum ResellerTabsValue {
  SERVICE_CENTER_OVERVIEW = 'service-center-overview',
  SERVICE_CENTER_DOCUMENTATION = 'service-center-documentation',
  SERVICE_CENTER_ORDER_INCREMENT = 'service-center-order-increment',
  SERVICE_CENTER_PICK_UP_STORE_DELIVERY = 'service-center-pick-up-store-and-delivery',
  SERVICE_CENTER_LOCK_LIMIT = 'service-center-lock-and-limit',
  SERVICE_CENTER_PAYMENT = 'service-center-payment',
}

const ServiceCenterInfo = (): JSX.Element => {
  const { error, retry, isLoading } = useResellerServiceCenter();

  if (isLoading) {
    return (
      <Loading
        size="xl"
        thickness="3px"
        message="Carregando as informações"
        subtitle="Por favor, aguarde um instante."
      />
    );
  }

  if (error?.status === 404) {
    return (
      <ErrorMessage
        title="Não foi possível encontrar os dados da Central de Serviços buscada"
        subTitle="Por favor, verifique com seu líder operacional"
      />
    );
  }

  if (error) {
    return (
      <ErrorMessage title="Não foi possível carregar os dados" subTitle="Por favor, tente novamente." onRetry={retry} />
    );
  }

  const resellerTabList: ResellerTab[] = [
    {
      title: 'Visão geral',
      value: ResellerTabsValue.SERVICE_CENTER_OVERVIEW,
      component: <ServiceCenterOverview />,
      tabType: 'sub-tab',
    },
    {
      title: 'Documentação',
      value: ResellerTabsValue.SERVICE_CENTER_DOCUMENTATION,
      component: <ServiceCenterDocumentation />,
      tabType: 'sub-tab',
    },
    {
      title: 'Inclusão de produto',
      value: ResellerTabsValue.SERVICE_CENTER_ORDER_INCREMENT,
      component: <ServiceCenterProductIncrement />,
      tabType: 'sub-tab',
    },
    {
      title: 'Retirada e entrega',
      value: ResellerTabsValue.SERVICE_CENTER_PICK_UP_STORE_DELIVERY,
      component: <ServiceCenterDeliveryAndPickUp />,
      tabType: 'sub-tab',
    },
    {
      title: 'Bloqueio e limite',
      value: ResellerTabsValue.SERVICE_CENTER_LOCK_LIMIT,
      component: <ServiceCenterLockAndLimit />,
      tabType: 'sub-tab',
    },
    {
      title: 'Pagamento',
      value: ResellerTabsValue.SERVICE_CENTER_PAYMENT,
      component: <ServiceCenterPaymentMethods />,
      tabType: 'sub-tab',
    },
  ];

  return <ResellerTabs tabDefaultValue={ResellerTabsValue.SERVICE_CENTER_OVERVIEW} tabList={resellerTabList} />;
};

export { ServiceCenterInfo };
