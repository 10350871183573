import { DeliveryThirdPartyObservation } from '@/types';

export const deliveryThirdPartiesValueLabelMap: {
  [key in DeliveryThirdPartyObservation]: string;
} = Object.freeze({
  [DeliveryThirdPartyObservation.CONTRATO_ASSINADO]: 'Necessário assinar o contrato',
  [DeliveryThirdPartyObservation.APOS_SEGUNDA_COMPRA]: 'Somente a partir da segunda compra',
  [DeliveryThirdPartyObservation.DOCUMENTO_TITULAR]: 'Apresentar documento original com foto do titular',
  [DeliveryThirdPartyObservation.COPIA_DOCUMENTO_TITULAR]: 'Apresentar cópia do documento com foto do titular',
  [DeliveryThirdPartyObservation.TERMO_AUTORIZACAO_RETIRADA]: 'Apresentar termo de autorização de retirada',
});
