import { isValid as isValidCpf } from '@fnando/cpf';
import { ValidateSearch } from '@/components/user-search/types';
import { cleanupSpecialCharacters, validateEmail, validateGera } from '@/utils';
import { SearchType } from '@/types';

export const validateSearchType = (queryString: string): ValidateSearch => {
  const value = queryString.trim().toLowerCase();
  const valueWithoutSpecialCharacters = cleanupSpecialCharacters(value);
  if (isValidCpf(valueWithoutSpecialCharacters.padStart(11, '0'))) {
    return {
      type: SearchType.CPF,
      value: valueWithoutSpecialCharacters.padStart(11, '0'),
      isValid: true,
    };
  }

  if (validateEmail(value)) {
    return {
      type: SearchType.EMAIL,
      value,
      isValid: true,
    };
  }

  if (validateGera(value)) {
    return {
      type: SearchType.GERA_ID,
      value: valueWithoutSpecialCharacters,
      isValid: true,
    };
  }

  return {
    type: SearchType.UNKNOWN,
    value,
    isValid: false,
  };
};
