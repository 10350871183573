import { FranchiseAddress, Address } from '@/types';
import { compact } from 'lodash';

export const generateAddressOnCopyText = (address: FranchiseAddress | Address) => {
  if (!address) {
    return 'Endereço:\r\nEndereço não informado';
  }

  const addressFormatted = {
    ...address,
    reference: (address as FranchiseAddress).referencePoint || (address as Address).reference,
  };

  const addressStringStart = compact([
    addressFormatted.street,
    addressFormatted.number,
    addressFormatted.complement,
  ]).join(', ');

  const addressStringEnd = [
    '',
    addressFormatted.landmark,
    addressFormatted.city,
    addressFormatted.state.toLocaleUpperCase(),
  ].join(' - ');

  const addressString = `${addressStringStart}${addressStringEnd}`;
  const referenceString = addressFormatted.reference ? `\r\n${addressFormatted.reference}` : '';

  return `Endereço:\r\n${addressString}${referenceString}, CEP: ${addressFormatted.zipCode}`;
};
