import { Button, Text, Tooltip } from '@grupoboticario/flora-react';
import { ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons';
import { ColorThemeProps } from '@grupoboticario/flora';
import { RiskAnalysis } from '@/types';

interface RiskAnalysisProps {
  riskAnalysis: RiskAnalysis;
}

const handleRiskAnalysisColor = (type: RiskAnalysis) => {
  const RiskType = {
    [RiskAnalysis.LOW_RISK]: '$success-standard',
    [RiskAnalysis.MEDIUM_RISK]: '$alert-standard',
    [RiskAnalysis.HIGH_RISK]: '$error-standard',
    [RiskAnalysis.VERY_HIGH_RISK]: '$error-standard',
    [RiskAnalysis.NOT_PROCESSED]: '$nonInteractiveAltAuxiliar',
  };

  return RiskType[type] as ColorThemeProps;
};

const riskAnalysisToString = new Map<RiskAnalysis, string>([
  [RiskAnalysis.LOW_RISK, 'Baixo risco'],
  [RiskAnalysis.MEDIUM_RISK, 'Médio risco'],
  [RiskAnalysis.HIGH_RISK, 'Alto risco'],
  [RiskAnalysis.VERY_HIGH_RISK, 'Altíssimo risco'],
  [RiskAnalysis.NOT_PROCESSED, '- - -'],
]);

const RiskAnalysisTag = ({ riskAnalysis }: RiskAnalysisProps): JSX.Element => {
  const isVeryHighRisk = riskAnalysis === RiskAnalysis.VERY_HIGH_RISK;
  const isNotProcessed = riskAnalysis === RiskAnalysis.NOT_PROCESSED;

  return (
    <Text
      color={isNotProcessed ? '$nonInteractivePredominant' : handleRiskAnalysisColor(riskAnalysis)}
      size="bodyMediumStandard"
      css={{
        alignItems: 'center',
        background: isVeryHighRisk ? 'rgba(222, 22, 22, 0.1)' : 'unset',
        display: 'flex',
        height: '$6',
        width: 'fit-content',
        '&:before': {
          background: handleRiskAnalysisColor(riskAnalysis),
          borderRadius: '$large',
          content: ' ',
          height: '$6',
          marginRight: '$2',
          width: '$1',
        },
      }}
    >
      {riskAnalysisToString.get(riskAnalysis)}
      {isVeryHighRisk && (
        <Tooltip direction="top" text="Altíssimo Risco">
          <Button
            hierarchy="tertiary"
            has="iconOnly"
            css={{
              margin: '0 $1',
            }}
            icon={
              <ExclamationMarkHexagonIcon
                css={{
                  '& svg': {
                    width: '$4',
                  },
                }}
              />
            }
            aria-label="Altíssimo Risco"
          />
        </Tooltip>
      )}
    </Text>
  );
};

export { RiskAnalysisTag };
