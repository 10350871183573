import React from 'react';
import { Box, Input, InputProps } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';
import { formatCurrencyStringToNumber } from '@/utils';

export interface FormInputProps extends InputProps {
  registerName: string;
  isCurrency?: boolean;
  errorMessage?: string;
  onBlur?: (event: unknown) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValueAs?: (value: string) => unknown;
  isRequired?: boolean;
}

const FormInput = ({
  registerName,
  isCurrency = false,
  onBlur,
  onChange,
  setValueAs,
  disabled,
  readOnly,
  optionalText = '',
  isRequired,
  errorMessage = 'Campo obrigatório',
  ...rest
}: FormInputProps): JSX.Element => {
  const { register, getFieldState, formState } = useFormContext();
  const fieldState = getFieldState(registerName, formState);

  return (
    <Box
      css={{
        '& > div': {
          height: 'auto',
        },
      }}
    >
      <Input
        invalidText={disabled ? undefined : fieldState.error?.message}
        invalid={disabled ? undefined : !!fieldState.error}
        data-valid={disabled ? undefined : fieldState.isDirty && !fieldState.error}
        mask={
          isCurrency
            ? {
                currency: 'BRL',
                style: 'currency',
                type: 'number',
              }
            : undefined
        }
        css={
          readOnly
            ? {
                backgroundColor: '$actionNavigationStandardDisabled',
                color: '$nonInteractiveAuxiliar',
              }
            : rest.css
        }
        optionalText={isCurrency ? '' : optionalText}
        placeholder={isCurrency ? 'R$ 0,00' : undefined}
        readOnly={readOnly}
        {...rest}
        {...register(registerName, {
          disabled,
          onBlur,
          onChange,
          setValueAs: isCurrency ? formatCurrencyStringToNumber : setValueAs,
          required: isRequired ? errorMessage : undefined,
        })}
      />
    </Box>
  );
};

export { FormInput };
