import {
  IdentificationRequirement,
  PickUpLimitInfo,
  PickUpStoreInfoInstruction,
  ProofOfAddressRequirement,
  ProofOfAddressSpecificationsRequirement,
  ThirdPartySupportRequirement,
} from '@/types';

export const pickUpInfoInstructionsLabelMap: {
  [key in PickUpStoreInfoInstruction]: string;
} = Object.freeze({
  [PickUpStoreInfoInstruction.APENAS_RETIRADA]: 'Apenas retirar pedidos, não permite entregas',
  [PickUpStoreInfoInstruction.SOMENTE_PRIMEIRO_PEDIDO]: 'Somente no primeiro pedido',
  [PickUpStoreInfoInstruction.A_PARTIR_SEGUNDO_PEDIDO]: 'Somente a partir do segundo pedido',
});

export const identificationRequirementsLabelMap: {
  [key in IdentificationRequirement]: string;
} = Object.freeze({
  [IdentificationRequirement.DOCUMENTO_ORIGINAL_FOTO]: 'Documento original com foto',
  [IdentificationRequirement.COPIA_DOCUMENTO_FOTO]: 'Cópia do documento com foto',
});

export const proofOfAddressRequirementsLabelMap: {
  [key in ProofOfAddressRequirement]: string;
} = Object.freeze({
  [ProofOfAddressRequirement.CONTAS_CONSUMO]: 'Contas de consumo (água, luz, telefone, gás, internet etc)',
  [ProofOfAddressRequirement.CONTRATO_LOCACAO]: 'Contrato de locação',
  [ProofOfAddressRequirement.DECLARACAO_IMPOSTO_RENDA]: 'Declaração de Imposto de Renda',
  [ProofOfAddressRequirement.DECLARACAO_RESIDENCIA]: 'Declaração de residência',
  [ProofOfAddressRequirement.FATURA_CARTAO_CREDITO]: 'Fatura de cartão de crédito',
  [ProofOfAddressRequirement.IPTU_OU_IPVA]: 'IPTU e/ou IPVA',
});

export const proofOfAddressSpecificationsRequirementsLabelMap: {
  [key in ProofOfAddressSpecificationsRequirement]: string;
} = Object.freeze({
  [ProofOfAddressSpecificationsRequirement.COMPROVANTE_NOME_PARENTE_CADASTRO]:
    'Comprovante no nome de parente de primeiro grau (mãe, pai, filhos etc) do titular do cadastro',
  [ProofOfAddressSpecificationsRequirement.COMPROVANTE_TITULAR_CADASTRO]: 'Comprovante no nome do titular do cadastro',
  [ProofOfAddressSpecificationsRequirement.COPIA]: 'Cópia',
  [ProofOfAddressSpecificationsRequirement.ORIGINAL]: 'Original',
});

export const thirdPartySupportRequirementsLabelMap: {
  [key in ThirdPartySupportRequirement]: string;
} = Object.freeze({
  [ThirdPartySupportRequirement.APRESENTAR_DOCUMENTO_ORIGINAL_TITULAR]:
    'Apresentar documento original com foto do titular',
  [ThirdPartySupportRequirement.APRESENTAR_COPIA_DOCUMENTO_TITULAR]:
    'Apresentar cópia do documento com foto do titular',
  [ThirdPartySupportRequirement.APRESENTAR_TERMO_AUTORIZACAO_RETIRADA]: 'Apresentar termo de autorização de retirada',
});

export const pickUpLimitInfoLabelMap: { [key in PickUpLimitInfo]: string } = Object.freeze({
  [PickUpLimitInfo.DAYS]: 'dia(s)',
  [PickUpLimitInfo.MONTHS]: 'mês(es)',
  [PickUpLimitInfo.WEEKS]: 'semana(s)',
  [PickUpLimitInfo.NONE]: 'Não especificado',
});
