import { Flex, Text } from '@grupoboticario/flora-react';
import { format } from 'date-fns';

interface ServiceDateProps {
  openedDate?: Date;
}

const serviceNotStartedString = '--/--/--';

export const ServiceDate = ({ openedDate }: ServiceDateProps) => {
  return (
    <Flex
      css={{
        textAlign: 'center',
        color: '$nonInteractiveAuxiliar',
      }}
      direction="column"
      align="flex-start"
      gap="$1"
    >
      <Text size="exceptionsRestrictedRegular">Data de abertura</Text>
      <Text
        css={{ fontSize: '$2xl' }}
        color={openedDate ? '$nonInteractivePredominant' : '$nonInteractiveAuxiliar'}
        data-testid="date-text"
      >
        {openedDate ? format(openedDate, 'dd/MM/yyyy') : serviceNotStartedString}
      </Text>
    </Flex>
  );
};
