import { fetcher } from '@/api';
import { PostalServiceData } from '@/types';

export const getPostalService = (zipCodeInfo: string): Promise<PostalServiceData> => {
  return fetcher.get<PostalServiceData>('utils/postalservice', {
    searchParams: {
      zipCode: zipCodeInfo,
    },
  });
};
