import { events, updateResellerDocumentStatus, useEventTracker } from '@/api';
import { DropdownItemsProps, ControlledSelect, FormInput } from '@/components/form';
import {
  ResellerDocumentStatusEnum,
  ResellerDocumentEnum,
  ResellerDocumentStatus,
  ResellerDocuments,
  formName,
} from '@/types';
import { capitalizeText } from '@/utils';
import { Flex, Box, Text } from '@grupoboticario/flora-react';
import { FormProvider } from 'react-hook-form';
import { DocumentStatusValueLabelMap } from '@/components/reseller-details/labels';
import { FormWrapper } from '@/components/shared';
import { useEditReseller } from '@/components/reseller-details/hooks';

interface EditResellerDocumentStatusFormProps {
  resellerGeraId?: string;
  initialDocumentStatusData: ResellerDocumentStatus[] | undefined;
}

const checkboxDocumentStatusValues: DropdownItemsProps[] = [
  {
    key: ResellerDocumentStatusEnum.NOT_CHECKED,
    value: ResellerDocumentStatusEnum.NOT_CHECKED,
    name: capitalizeText(DocumentStatusValueLabelMap[ResellerDocumentStatusEnum.NOT_CHECKED]),
  },
  {
    key: ResellerDocumentStatusEnum.PENDING,
    value: ResellerDocumentStatusEnum.PENDING,
    name: capitalizeText(DocumentStatusValueLabelMap[ResellerDocumentStatusEnum.PENDING]),
  },
  {
    key: ResellerDocumentStatusEnum.CHECKED,
    value: ResellerDocumentStatusEnum.CHECKED,
    name: capitalizeText(DocumentStatusValueLabelMap[ResellerDocumentStatusEnum.CHECKED]),
  },
];

const generateResellerDocumentsDefaultValues = (
  documentType: ResellerDocumentEnum,
  initialDocumentStatusData: ResellerDocumentStatus[] | undefined,
) => {
  const indexNotFound = -1;

  if (!initialDocumentStatusData) {
    return ResellerDocumentStatusEnum.NOT_CHECKED;
  }

  const documentIndex = initialDocumentStatusData?.findIndex(document => document.tipoDocumento === documentType);
  return documentIndex !== indexNotFound
    ? initialDocumentStatusData[documentIndex].status
    : ResellerDocumentStatusEnum.NOT_CHECKED;
};

const defineDocumentStatus = (
  documentType: ResellerDocumentEnum,
  initialDocumentStatusData: ResellerDocumentStatus[] | undefined,
) => {
  const resellerDocumentType = generateResellerDocumentsDefaultValues(documentType, initialDocumentStatusData);
  return resellerDocumentType === ResellerDocumentStatusEnum.NOT_DEFINED
    ? ResellerDocumentStatusEnum.NOT_CHECKED
    : resellerDocumentType;
};

const EditResellerDocumentStatusForm = ({
  resellerGeraId,
  initialDocumentStatusData,
}: EditResellerDocumentStatusFormProps) => {
  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();
  const resellerDocumentsDefaultValues = {
    documentos: [
      {
        tipoDocumento: ResellerDocumentEnum.RG,
        status: defineDocumentStatus(ResellerDocumentEnum.RG, initialDocumentStatusData),
      },
      {
        tipoDocumento: ResellerDocumentEnum.CPF,
        status: defineDocumentStatus(ResellerDocumentEnum.CPF, initialDocumentStatusData),
      },
      {
        tipoDocumento: ResellerDocumentEnum.ADDRESS_PROOF,
        status: defineDocumentStatus(ResellerDocumentEnum.ADDRESS_PROOF, initialDocumentStatusData),
      },
    ],
  };

  const { methods, handleSubmit, hasChangesOnForm, setHasFormError, hasFormError, submittedResellerFormMessage } =
    useEditReseller({
      initialData: resellerDocumentsDefaultValues,
      geraId: resellerGeraId,
      registryKey: 'resellers/documents',
    });

  const onSubmit = async (data: ResellerDocuments) => {
    if (resellerGeraId === undefined) {
      setHasFormError(true);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.documentStatus,
        errorMessage: 'Gera ID não pode ser nulo',
      });
      return hasFormError;
    }
    if (!hasChangesOnForm) {
      events.emit('CLOSE_DRAWER_INFO');
      return null;
    }

    try {
      const response = await updateResellerDocumentStatus(resellerGeraId, data);
      submittedResellerFormMessage();
      resellerInfoEditDataSubmitted({
        editFormName: formName.documentStatus,
      });
      return response;
    } catch (err) {
      setHasFormError(!!err);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.documentStatus,
        errorMessage: err.message,
      });
      throw err;
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-document-status">
      <Text as="h3" size="bodyMediumShort" css={{ paddingLeft: '$4' }}>
        Documentos cadastrais
      </Text>

      <FormProvider {...methods}>
        <Box as="form" id="reseller-form" onSubmit={handleSubmit(onSubmit)} data-testid="edit-document-status-form">
          <FormWrapper css={{ background: '$light-dark-1', padding: '$4' }}>
            <Box css={{ gridColumn: 'span 6' }}>
              <ControlledSelect
                registerName="documentos[0].status"
                dropdownItems={checkboxDocumentStatusValues}
                data-testid="select-rg"
                id="tipoDocumento"
                labelText="RG:"
                optionalText=""
              />
              <FormInput registerName="documentos[0].tipoDocumento" id="input-rg" labelText="" hidden />
            </Box>
            <Box css={{ gridColumn: 'span 6' }}>
              <ControlledSelect
                registerName="documentos[1].status"
                dropdownItems={checkboxDocumentStatusValues}
                data-testid="select-cpf"
                id="tipoDocumento"
                labelText="CPF:"
                optionalText=" "
              />
              <FormInput registerName="documentos[1].tipoDocumento" id="input-cpf" labelText="" hidden />
            </Box>
            <Box css={{ gridColumn: 'span 12' }}>
              <ControlledSelect
                registerName="documentos[2].status"
                dropdownItems={checkboxDocumentStatusValues}
                data-testid="select-address-proof"
                id="tipoDocumento"
                labelText="Comprovante de residência:"
                optionalText=""
              />
              <FormInput registerName="documentos[2].tipoDocumento" id="input-address-proof" labelText="" hidden />
            </Box>
          </FormWrapper>
        </Box>
      </FormProvider>
    </Flex>
  );
};

export { EditResellerDocumentStatusForm };
