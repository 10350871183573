import { ServiceCenterDelivery, ServiceCenterPickUp } from '@/components/reseller-details';
import { TagStatus } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { Flex } from '@grupoboticario/flora-react';
import { ExclamationMarkTriangleFillIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterDeliveryAndPickUp = () => {
  const { csData } = useResellerServiceCenter();

  const hasDelivery = csData?.delivery?.hasDelivery;
  const allowPickUpStore = csData?.pickUpStore?.allowPickUpStore;

  return (
    <Flex direction="column" data-testid="delivery-and-pickup-tab">
      {(!allowPickUpStore || !hasDelivery) && (
        <Flex direction="column" css={{ marginBottom: '$1' }}>
          {!allowPickUpStore && (
            <TagStatus
              variant="warning"
              statusText="Não permite retirada"
              statusIcon={<ExclamationMarkTriangleFillIcon css={{ color: '$3' }} />}
            />
          )}

          {!hasDelivery && (
            <TagStatus
              variant="warning"
              statusText="Não permite entrega."
              statusIcon={<ExclamationMarkTriangleFillIcon css={{ color: '$3' }} />}
            />
          )}
        </Flex>
      )}

      {(allowPickUpStore || hasDelivery) && (
        <Flex direction="column" gap="$6">
          {allowPickUpStore && <ServiceCenterPickUp />}
          {hasDelivery && <ServiceCenterDelivery />}
        </Flex>
      )}
    </Flex>
  );
};

export { ServiceCenterDeliveryAndPickUp };
