import { useFeatureFlagContext, useResellerDetails } from '@/contexts';
import { RiskAnalysis } from '@/types';
import { Box, Flex } from '@grupoboticario/flora-react';
import {
  ErrorMessage,
  ResellerAddresses,
  ResellerContact,
  ResellerDocumentStatus,
  ResellerGeneralData,
} from '@/components/reseller-details';
import { Loading, ResellerTagStatus } from '@/components/shared';
// eslint-disable-next-line import/no-unresolved
import { TabulationHistory } from '@vdi/tabulation';

const ResellerInfo = (): JSX.Element => {
  const { data, error, retry, isLoading } = useResellerDetails().resellerRegistry;
  const { riskAnalysis } = useResellerDetails().riskAnalysisData;
  const { viewTabulateHistory } = useFeatureFlagContext();

  const hasTagStatus =
    !!data &&
    ([RiskAnalysis.NOT_PROCESSED, RiskAnalysis.VERY_HIGH_RISK].includes(riskAnalysis as RiskAnalysis) ||
      data.hasFirstOrder);

  if (error) {
    return (
      <ErrorMessage title="Não foi possível carregar os dados" subTitle="Por favor, tente novamente." onRetry={retry} />
    );
  }

  if (isLoading) {
    return (
      <Loading
        size="xl"
        thickness="3px"
        message="Carregando as informações"
        subtitle="Por favor, aguarde um instante."
      />
    );
  }

  return (
    <Box>
      {hasTagStatus && (
        <Box data-testid="reseller-tag-status">
          <ResellerTagStatus hasFirstOrder={data.hasFirstOrder} />
        </Box>
      )}
      <Flex css={{ paddingBottom: '$4', paddingRight: '$4' }} direction="column" gap="$6">
        <ResellerGeneralData />
        <ResellerContact />
        {viewTabulateHistory.isFeatureEnabled && (
          <TabulationHistory
            apiConfig={{
              baseUrl: process.env.BFF_BASE_URL,
              historyEndpoint: `/v1/services/tab/${data?.geraId}/history`,
            }}
            hideContactPurpose
          />
        )}

        <ResellerAddresses />
        <ResellerDocumentStatus />
      </Flex>
    </Box>
  );
};

export { ResellerInfo };
