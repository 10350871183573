import { NOT_SPECIFIED, getInstallmentConditionTexts, sortInstallmentConditions } from '@/components/reseller-details';
import { TextInfo } from '@/components';
import { AcceptsInstallmentPayments } from '@/types';
import { formatCurrency } from '@/utils';
import React from 'react';

type InstallmentOptionsViewPropsType = AcceptsInstallmentPayments;

const InstallmentOptionList = ({
  hasInstallmentOptions,
  hasMinimumInstallmentValue,
  minimumInstallmentValue,
  installmentOptions = [],
}: InstallmentOptionsViewPropsType): JSX.Element => {
  const options = installmentOptions.filter(({ condition }) => condition !== undefined).sort(sortInstallmentConditions);

  return (
    <>
      {hasInstallmentOptions && options.length > 0 ? (
        <>
          {options.map(installmentConditionData => {
            const texts = getInstallmentConditionTexts(installmentConditionData);
            return (
              <React.Fragment key={`${texts.conditionText}-${texts.maximumInstallmentsText}`}>
                <TextInfo title="Condição de Parcelamento" text={texts?.conditionText} canCopyToClipboard />
                <TextInfo
                  title="Quantidade máxima de Parcelas"
                  text={texts?.maximumInstallmentsText}
                  canCopyToClipboard
                />
              </React.Fragment>
            );
          })}
        </>
      ) : (
        <>
          <TextInfo title="Condição de Parcelamento" fallbackText={NOT_SPECIFIED} />
          <TextInfo title="Quantidade máxima de Parcelas" fallbackText={NOT_SPECIFIED} />
        </>
      )}
      {hasMinimumInstallmentValue && (
        <TextInfo isFullWidth title="Valor mínimo da parcela" text={formatCurrency(Number(minimumInstallmentValue))} />
      )}
    </>
  );
};

export { InstallmentOptionList };
