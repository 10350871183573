import { InfoWrapper } from '@/components';
import { TagList } from '@/components/reseller-details';
import { Flex, Text } from '@grupoboticario/flora-react';

interface AllowedCategoriesProps {
  tagsValue?: string[];
}

const AllowedCategories = ({ tagsValue }: AllowedCategoriesProps): JSX.Element => {
  const hasTagValue = !!tagsValue?.length || undefined;

  return (
    <InfoWrapper isFullWidth>
      <Text
        as="h6"
        size="exceptionsRestrictedRegular"
        color="$nonInteractiveAuxiliar"
        css={{ marginBottom: hasTagValue ? '$4' : '$1' }}
      >
        Categorias permitidas
      </Text>
      <Flex align="center" gap="$2">
        {hasTagValue ? <TagList tagsValue={tagsValue ?? []} tagVariant="assorted-8" /> : <Text>Não especificado</Text>}
      </Flex>
    </InfoWrapper>
  );
};

export { AllowedCategories };
