import { useGTMTrackerStore } from '@/store';
import { ServiceStatus, type TrackerEvents, type Event } from '@/types';
// eslint-disable-next-line import/no-unresolved
import { GtmTracker, IEventTracker } from '@vdi/event-tracker';
import { useShallow } from 'zustand/react/shallow';

const useEventTracker = (): TrackerEvents => {
  const { track } = GtmTracker as unknown as IEventTracker<Event, unknown, unknown>;
  const { sessionTabName, assistantID, intentionID } = useGTMTrackerStore(useShallow(state => state));

  return {
    userSearched: ({ searchCode }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'callback_busca_atendimento_sucesso',
          cd_codigo_atendimento: searchCode,
          cd_user_id: assistantID,
          cd_codigo_representante: assistantID,
        },
      });
    },
    userSearchedError: ({ errorMessage }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'callback_busca_atendimento_erro',
          cd_error_message: errorMessage,
          cd_user_id: assistantID,
          cd_codigo_representante: assistantID,
        },
      });
    },
    clickedUserSearched: intentionID => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: 'click:atendimento',
          cd_gera_id: intentionID,
          cd_user_id: assistantID,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
        },
      });
    },
    startFinishService: ({ interactionDetail }) => {
      const interactionDetailText = interactionDetail === ServiceStatus.OPEN ? 'iniciar' : 'finalizar';
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: `click:${interactionDetailText}-atendimento`,
          cd_gera_id: intentionID,
          cd_user_id: assistantID,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
        },
      });
    },
    infoCopyButtonClicked: ({ section }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: `click:copiar-${section}`,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
          cd_section: `${sessionTabName}:${section}`,
        },
      });
    },
    clickedTabs: (tabName, tabType) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: `click:${tabType}:${tabName}`,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
          cd_section: `${tabType}:${tabName}`,
        },
      });
    },
    clickedResellerInfoButtons: ({ section }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: `click:${section}`,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
          cd_section: `visao-geral:${section}`,
        },
      });
    },

    resellerInfoEditDataSubmitted: ({ editFormName }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: `callback_alteracao_dados_sucesso`,
          cd_section: editFormName,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
        },
      });
    },

    resellerInfoEditDataErrorSubmitted: ({ editFormName, errorMessage }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: `callback_exibicao_dados_erro`,
          cd_codigo_atendimento: assistantID,
          cd_codigo_representante: intentionID,
          cd_error_message: `${errorMessage}_${editFormName}`,
        },
      });
    },
  };
};

export { useEventTracker };
