import { InstructionBox } from '@/components/reseller-details';
import { SendPaymentVoucher } from '@/types';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';
import { sendPaymentVouchersValueLabelMap } from '@/components/reseller-details/labels';

interface PaymentVoucherInstructionProps {
  requestsPaymentVoucher: boolean;
  sendTo: SendPaymentVoucher[] | undefined;
  requestPaymentTitle: string;
}

const PaymentVoucherInstruction = ({
  requestsPaymentVoucher,
  sendTo,
  requestPaymentTitle,
}: PaymentVoucherInstructionProps) => {
  const isRequiredSendVoucherOperationalManage = sendTo?.includes(SendPaymentVoucher.OPERATIONAL_MANAGER);
  const instructionBoxBaseProps = {
    icon: <CheckCircleIcon css={{ color: '$8' }} size="32" />,
    isFullWidth: true,
  };

  return (
    <>
      {requestsPaymentVoucher ? (
        <InstructionBox title={requestPaymentTitle} {...instructionBoxBaseProps} />
      ) : (
        <InstructionBox
          title="Permitir depósito ou transferência sem envio de comprovante"
          {...instructionBoxBaseProps}
        />
      )}

      {requestsPaymentVoucher && isRequiredSendVoucherOperationalManage && (
        <InstructionBox
          title={sendPaymentVouchersValueLabelMap[SendPaymentVoucher.OPERATIONAL_MANAGER]}
          {...instructionBoxBaseProps}
        />
      )}
    </>
  );
};

export { PaymentVoucherInstruction };
