import { DataWrapper, TextInfo } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';

const ServiceCenterGeneralData = (): JSX.Element => {
  const { csData } = useResellerServiceCenter();
  return (
    <DataWrapper title="Informações gerais">
      <TextInfo title="Código do CS" text={csData?.franchise?.address.csCode} />
      <TextInfo title="Nome do franqueado" text={csData?.franchise?.name} isCapitalized />
      {!!csData?.franchise?.owner && (
        <TextInfo title="Responsável operacional" text={csData?.franchise?.owner} isFullWidth isCapitalized />
      )}
    </DataWrapper>
  );
};

export { ServiceCenterGeneralData };
