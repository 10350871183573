import { events } from '@/api';
import { resellerRegistryKey } from '@/types';
import { Toaster } from '@grupoboticario/flora-react';
import { useState } from 'react';
import { useSWRConfig } from 'swr';

export const useUpdateResellerFormHandler = (geraId?: string, registryKey: string = resellerRegistryKey) => {
  const [hasFormError, setHasFormError] = useState(false);
  const { mutate } = useSWRConfig();
  const submittedResellerFormMessage = () => {
    events.emit('CLOSE_DRAWER_INFO');
    Toaster.notify({
      kind: 'success',
      description: 'Dados alterados com sucesso',
      origin: 'right-top',
      duration: 60000,
      button: 'Fechar',
      closeWhenClick: true,
    });

    mutate([registryKey, geraId]);
  };

  return {
    submittedResellerFormMessage,
    setHasFormError,
    hasFormError,
  };
};
