import { DEFAULT_TRANSITION } from '@/types';
import { Flex, Heading, Text } from '@grupoboticario/flora-react';
// import {  EmptyIr } from '@/assets/images/';

const ResellerEmptyState = (): JSX.Element => {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      css={{
        transition: DEFAULT_TRANSITION,
        width: '100%',
      }}
    >
      {/* <EmptyIr aria-hidden="true" /> */}
      <Heading
        level="6"
        color="$nonInteractiveAuxiliar"
        css={{
          padding: '$12 0 $4',
        }}
      >
        Nenhum contato selecionado
      </Heading>
      <Text color="$nonInteractiveAuxiliar" size="bodyLargeShortRegular" align="center" css={{ width: '464px' }}>
        Escolha um contato com atendimento aberto para visualizar as informações detalhadas.
      </Text>
    </Flex>
  );
};

export { ResellerEmptyState };
