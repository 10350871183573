import { useEventTracker } from '@/api';
import { useClipboard, Toaster } from '@grupoboticario/flora-react';

interface UseCopyDataWrapperInfoParams {
  text: string;
  successMessage: string;
  errorMessage?: string;
  section?: string;
}

export const useCopyDataWrapperInfo = ({
  text,
  successMessage,
  errorMessage = 'Não foi possível copiar o dado!',
  section,
}: UseCopyDataWrapperInfoParams) => {
  const { infoCopyButtonClicked } = useEventTracker();
  const clipboard = useClipboard();

  const onCopy = () => {
    try {
      clipboard.copy(text);
      infoCopyButtonClicked({
        section: section,
      });
      Toaster.notify({
        kind: 'success',
        duration: 3000,
        button: 'Fechar',
        closeWhenClick: true,
        description: successMessage,
        origin: 'right-top',
      });
    } catch (err) {
      Toaster.notify({
        kind: 'error',
        duration: 3000,
        closeWhenClick: false,
        description: errorMessage,
        origin: 'right-top',
        button: 'Tentar novamente',
        callback: () => {
          onCopy();
          return {}; // Flora obriga o retorno de um objeto no callback
        },
      });
    }
  };

  return { onCopy };
};
