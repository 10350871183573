import React, { ReactNode, useMemo } from 'react';
import { useResellerDetails } from '@/contexts';
import { useGetServiceCenterByCsCode } from '@/api';
import { ServiceCenter } from '@/types';

export interface ResellerServiceCenterValue {
  csData: ServiceCenter | undefined;
  isLoading: boolean;
  error?: ResellerServiceCenterError;
  retry?: () => void;
}

export interface ResellerServiceCenterError {
  status: number;
  message: string;
  ok: boolean;
}

interface ResellerServiceCenterProviderProps {
  children: ReactNode;
}

const ResellerServiceCenterContext = React.createContext<ResellerServiceCenterValue | undefined>(undefined);

const ResellerServiceCenterProvider = ({ children }: ResellerServiceCenterProviderProps) => {
  const { csId } = useResellerDetails();
  const { data, isLoading, error, retry } = useGetServiceCenterByCsCode(csId, 'done');
  let responseError: ResellerServiceCenterError | undefined;

  if (error) {
    responseError = {
      status: error?.response.status,
      message: error?.message,
      ok: error?.response.ok,
    };
  }
  const contextValue: ResellerServiceCenterValue = useMemo(
    () => ({
      csData: data,
      isLoading,
      error: responseError,
      retry,
    }),
    [data, responseError, isLoading, retry],
  );

  return <ResellerServiceCenterContext.Provider value={contextValue}>{children}</ResellerServiceCenterContext.Provider>;
};

const useResellerServiceCenter = (): ResellerServiceCenterValue => {
  const context = React.useContext(ResellerServiceCenterContext);

  if (context === undefined) {
    throw new Error('useResellerServiceCenter deve ser utilizado dentro de um ResellerChecklistContext');
  }

  return context;
};

export { ResellerServiceCenterContext, ResellerServiceCenterProvider, useResellerServiceCenter };
