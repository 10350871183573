import { UserListItem, OpenServicesCounter } from '@/components/user-list/components';
import { Flex, ScrollArea } from '@grupoboticario/flora-react';
import { EmptyState, Loading } from '@/components';
import { useUserList } from '@/components/user-list/context';
import { ChatBubbleOffIcon } from '@grupoboticario/flora-react-icons';

const UserList = (): JSX.Element => {
  const { hasUsers, isLoading, users, selectedUserId } = useUserList();

  if (isLoading) {
    return (
      <Loading
        size="xl"
        message="Carregando..."
        thickness="3px"
        containerCss={{
          height: '100%',
          align: 'center',
          justifyContent: 'center',
        }}
      />
    );
  }

  if (!hasUsers) {
    return (
      <EmptyState
        title="Nenhum atendimento aberto"
        text="Faça uma busca e selecione um contato para abrir um atendimento."
        icon={<ChatBubbleOffIcon size="4em" />}
      />
    );
  }

  return (
    <Flex
      direction="column"
      css={{
        minHeight: 0,
        height: '100%',
      }}
    >
      <OpenServicesCounter openedServices={users.length} />
      <ScrollArea css={{ height: '100%' }} orientation="vertical">
        <Flex
          direction="column"
          as="ul"
          css={{
            margin: '$0 $4 $2 $4',
          }}
          gap="$2"
        >
          {users.map(user => (
            <UserListItem key={user._id} user={user} isSelected={selectedUserId === user._id} />
          ))}
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export { UserList };
