import { InstallmentCondition, InstallmentConditionData } from '@/types';

const orderedAcceptedConditions: InstallmentCondition[] = [
  InstallmentCondition.PURCHASES_UNTIL,
  InstallmentCondition.PURCHASES_FROM,
  InstallmentCondition.INDEPENDENT,
];

export const sortInstallmentConditions = (
  { condition: conditionA, value: valueA }: InstallmentConditionData,
  { condition: conditionB, value: valueB }: InstallmentConditionData,
) => {
  const conditionAIndex = orderedAcceptedConditions.indexOf(conditionA as InstallmentCondition);
  const conditionBIndex = orderedAcceptedConditions.indexOf(conditionB as InstallmentCondition);
  const valueAAsNumber = Number(valueA);
  const valueBAsNumber = Number(valueB);

  if (conditionAIndex > conditionBIndex) {
    return 1;
  }

  if (conditionAIndex < conditionBIndex) {
    return -1;
  }

  if (valueAAsNumber > valueBAsNumber) {
    return 1;
  }

  if (valueAAsNumber < valueBAsNumber) {
    return -1;
  }

  return 0;
};
