import { Button, Flex, Text } from '@grupoboticario/flora-react';
import { PlayCircleFillIcon, StopIcon } from '@grupoboticario/flora-react-icons';
import { parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useGetService, useHandleCloseService, startCustomerService, events, useEventTracker } from '@/api';
import { ServiceRouteParamsType, ServiceStatus, Service, myAttendancesKey } from '@/types';
import { ServiceDate, ServiceTimer } from './components';
import { Loading } from '../shared';

const convertStringToDate = (dataString: string | undefined) => {
  if (dataString) {
    return parseISO(dataString);
  }

  return undefined;
};

export const ServiceControl = () => {
  const { mutate } = useSWRConfig();
  const { serviceId } = useParams<ServiceRouteParamsType>();
  const { service, isLoading } = useGetService(serviceId);
  const { startFinishService } = useEventTracker();

  const { handleCloseService } = useHandleCloseService({
    serviceId,
    serviceStatus: service?.status,
    geraId: service?.reseller.geraId,
    registryName: service?.reseller.name,
  });

  const isServiceStarted = service?.status === ServiceStatus.IN_ATTENDANCE;
  const timeServiceStarted = isServiceStarted ? convertStringToDate(service.updatedAt) : undefined;

  const handleUpdateServiceList = async (startedService: Service) => {
    await mutate(
      myAttendancesKey,
      (cachedServices: Service[] | undefined) => {
        const services = cachedServices ?? [];
        const serviceIndex = services.findIndex(entry => entry._id === serviceId);

        const serviceToUpdate = {
          ...services[serviceIndex],
          status: startedService.status,
        };
        services[serviceIndex] = serviceToUpdate;

        return [...services];
      },
      false,
    );
  };

  const handleStartService = async () => {
    if (serviceId) {
      try {
        const resp = await startCustomerService(serviceId);
        handleUpdateServiceList(resp);
        mutate(['service', serviceId], resp);
        startFinishService({
          interactionDetail: ServiceStatus.OPEN,
        });
      } catch (error) {
        events.error({
          duration: 60000,
          description: 'Não foi possível iniciar o atendimento.',
        });
      }
    }
  };

  return (
    <Flex
      key={serviceId}
      css={{
        backgroundColor: '$light-light',
        borderRadius: '0 0 $medium $medium',
        padding: '$4 $8',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.12)',
      }}
      direction="column"
    >
      {isLoading ? (
        <Flex css={{ padding: '$6 0' }} justify="center">
          <Loading />
        </Flex>
      ) : (
        <>
          <Text
            css={{
              borderBottom: '1px solid $nonInteractiveAltAuxiliar',
              paddingBottom: '$3',
              marginBottom: '$4',
            }}
            size="bodyMediumShort"
          >
            Controle de atendimento
          </Text>
          <Flex>
            <ServiceDate openedDate={timeServiceStarted} />
            <ServiceTimer startedTime={timeServiceStarted} />
            <Button
              styleSemantic={!isServiceStarted ? 'success' : 'warning'}
              icon={!isServiceStarted ? <PlayCircleFillIcon /> : <StopIcon />}
              has="iconLeft"
              css={{ padding: '0 $7', marginLeft: 'auto' }}
              onClick={!isServiceStarted ? handleStartService : handleCloseService}
            >
              {!isServiceStarted ? 'Iniciar atendimento' : 'Finalizar atendimento'}
            </Button>
          </Flex>
        </>
      )}
    </Flex>
  );
};
