import { DataWrapper, NoteField, TextInfo } from '@/components';
import { PaymentVoucherInstruction, PaymentVoucherView } from '@/components/reseller-details';
import { pixKeyTypeValueLabelMap } from '@/components/reseller-details/labels';
import { PixKey, PixPayment } from '@/types';
import { formatCpfOrCnpj, formatPhoneNumber } from '@/utils';

export interface PixPaymentViewProps {
  pixPaymentData: PixPayment;
}

const pixKeyFormatter = (pixKeyToFormat: PixKey, pixKeyType: string) => {
  if (pixKeyType === PixKey.CPF_CNPJ) {
    return formatCpfOrCnpj(pixKeyToFormat);
  }
  if (pixKeyType === PixKey.PHONE_NUMBER) {
    return formatPhoneNumber(pixKeyToFormat);
  }
  return pixKeyToFormat;
};

const PixPaymentView = ({ pixPaymentData }: PixPaymentViewProps) => {
  const {
    specifications,
    sendTo,
    keyType,
    pixKeyValue,
    paymentVoucherEmail,
    paymentVoucherWhatsapp,
    requestsPaymentVoucher,
    note,
  } = pixPaymentData;

  return (
    <DataWrapper title="Pix" data-testid="pix-payment">
      <PaymentVoucherInstruction
        requestPaymentTitle="Solicitar o envio do comprovante do Pix feito"
        requestsPaymentVoucher={requestsPaymentVoucher}
        sendTo={sendTo}
      />
      <TextInfo title="tipo de chave permitida" text={pixKeyTypeValueLabelMap[keyType as PixKey]} />
      <TextInfo title="dados da chave para envio" text={pixKeyFormatter(pixKeyValue as PixKey, keyType as string)} />

      <PaymentVoucherView
        toSendData={sendTo}
        proofOfPaymentOptions={specifications}
        phoneNumber={paymentVoucherWhatsapp}
        email={paymentVoucherEmail}
      />
      {note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { PixPaymentView };
