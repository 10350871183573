import { Tooltip, Heading, Button } from '@grupoboticario/flora-react';
import { QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { useGetConfigData } from '@/api';
import { Loading } from '@/components';

interface CountOpenedServicesProps {
  openedServices: number;
}

const openServicesText = 'Consultas Abertas';

const OpenServicesCounter = ({ openedServices }: CountOpenedServicesProps) => {
  const { data, isLoading } = useGetConfigData();

  if (isLoading) {
    return (
      <Loading
        size="xxs"
        containerCss={{
          height: '20%',
        }}
      />
    );
  }
  const maxOpenServices = data?.services?.maxOpenServices ?? '--';
  const tooltipText = `O limite é de ${maxOpenServices} consultas abertas.  Novas consultas podem ser abertas até essa quantidade.`;

  return (
    <Heading
      level={2}
      css={{
        display: 'block',
        marginLeft: '$4',
        lineHeight: '$2',
        fontSize: '$xs',
      }}
      data-testid="list-heading"
    >
      {data ? (
        <>
          {`${openServicesText} (${openedServices}/${maxOpenServices})`}
          <Tooltip direction="bottom" text={tooltipText}>
            <Button
              css={{ width: '$6', height: 'auto' }}
              hierarchy="tertiary"
              has="iconOnly"
              icon={
                <QuestionMarkCircleIcon
                  css={{
                    '& svg': {
                      width: '$3',
                    },
                  }}
                />
              }
              aria-label={tooltipText}
            />
          </Tooltip>
        </>
      ) : (
        openServicesText
      )}
    </Heading>
  );
};

export { OpenServicesCounter };
