import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { useCopyDataWrapperInfo, generateAddressOnCopyText, NOT_SPECIFIED } from '@/components/reseller-details';
import { formatCep } from '@/utils';
import { useResellerServiceCenter } from '@/contexts';
import { DataWrapper, TextInfo } from '@/components';

interface AddressDataProps {
  title: string;
  text: string | undefined;
  isFullWidth?: boolean;
  capitalized?: boolean;
}

const ServiceCenterAddress = (): JSX.Element => {
  const { csData } = useResellerServiceCenter();
  const addressInfo = csData?.franchise?.address;

  const { onCopy } = useCopyDataWrapperInfo({
    text: generateAddressOnCopyText(addressInfo),
    successMessage: 'Dados de endereço copiados',
    section: 'endereço',
  });

  const addressData: AddressDataProps[] = [
    {
      title: 'Cep',
      text: formatCep(addressInfo?.zipCode),
      isFullWidth: true,
    },
    {
      title: 'Logradouro',
      text: addressInfo?.street,
      capitalized: true,
    },
    {
      title: 'Número',
      text: addressInfo?.number,
    },
    {
      title: 'Bairro',
      text: addressInfo?.landmark,
      capitalized: true,
    },
    {
      title: 'Complemento',
      text: addressInfo?.complement ?? '---',
      capitalized: true,
    },
    {
      title: 'Estado',
      text: addressInfo?.state,
    },
    {
      title: 'Cidade',
      text: addressInfo?.city,
      capitalized: true,
    },
    ...(addressInfo?.referencePoint
      ? [
          {
            title: 'Ponto de referência',
            text: addressInfo.referencePoint,
            isFullWidth: true,
            capitalized: true,
          },
        ]
      : []),
  ];

  return (
    <DataWrapper
      title="Endereço"
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar endereço',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      {addressData.length &&
        addressData.map(address => (
          <TextInfo
            key={address.title}
            isFullWidth={address?.isFullWidth}
            title={address.title}
            text={address.text}
            isCapitalized={address?.capitalized}
            fallbackText={NOT_SPECIFIED}
          />
        ))}
    </DataWrapper>
  );
};

export { ServiceCenterAddress };
