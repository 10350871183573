import { StarterKitInstruction } from '@/types';

export const starterKitInstructionValueLabelMap: {
  [key in StarterKitInstruction]: string;
} = Object.freeze({
  [StarterKitInstruction.ADQUIRIDO_POSTERIORMENTE]:
    'Informar IR que o kit inicial deverá ser adquirido posteriormente.',
  [StarterKitInstruction.INFORMAR_INDISPONIBILIDADE]:
    'Informar líder operacional sobre a indisponibilidade do kit inicial.',
});
