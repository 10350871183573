import { Button } from '@grupoboticario/flora-react';
import { ArrowRightIcon } from '@grupoboticario/flora-react-icons';

export const SearchIconButton = (): JSX.Element => (
  <Button
    hierarchy="tertiary"
    has="iconOnly"
    icon={
      <ArrowRightIcon
        css={{
          '& svg': {
            width: '$6',
          },
        }}
      />
    }
    aria-label="Buscar IR"
    type="submit"
  />
);
