import { useRequest } from '@/api';
import { Service } from '@/types';

export function useGetService(serviceId?: string) {
  const { data, error } = useRequest<Service>(serviceId ? ['service', serviceId] : null, `services/${serviceId}`);

  return {
    isLoading: !data && !error,
    service: data,
    error,
  };
}
