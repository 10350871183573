import { useGetPostalService } from '@/api';
import { cleanupSpecialCharacters } from '@/utils';
import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { InitialAddressData } from '../../types';

export const useZipCodeUpdateHandler = (
  initialData: InitialAddressData | undefined,
  formMethods: UseFormReturn<InitialAddressData>,
) => {
  const [cep, setCep] = useState(cleanupSpecialCharacters(initialData?.zipCode ?? ''));
  const [isReadOnly, setIsReadOnly] = useState({
    landmark: false,
    street: false,
  });
  const { data: address } = useGetPostalService(cep);
  const { setValue } = formMethods;

  useEffect(() => {
    const isZipCodeChanged = initialData?.zipCode !== cep;
    if (!!address && isZipCodeChanged) {
      setValue('street', address.streetAddress ?? '');
      setValue('landmark', address.district ?? '');
      setValue('city', address.addressLocality ?? '');
      setValue('state', address.addressRegion ?? '');
    }
    setIsReadOnly({
      landmark: !!address?.district,
      street: !!address?.streetAddress,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cep, address]);

  const onZipCodeChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCep = cleanupSpecialCharacters(evt.target.value ?? '');
    if (updatedCep.length === 8) {
      setCep(updatedCep);
    }
  }, []);

  return {
    onZipCodeChange,
    isReadOnly,
  };
};
