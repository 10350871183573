import { DayOfTheWeek, BusinessHoursTimeRange, BusinessHoursDay } from '@/types';
import { sortBy } from 'lodash';

interface DayRange {
  startingDay: number;
  endingDay: number;
  hours: string;
}

const dayOfTheWeekValue = {
  [DayOfTheWeek.MONDAY]: 'Segunda',
  [DayOfTheWeek.TUESDAY]: 'Terça',
  [DayOfTheWeek.WEDNESDAY]: 'Quarta',
  [DayOfTheWeek.THURSDAY]: 'Quinta',
  [DayOfTheWeek.FRIDAY]: 'Sexta',
  [DayOfTheWeek.SATURDAY]: 'Sábado',
  [DayOfTheWeek.SUNDAY]: 'Domingo',
};

const getDayOfTheWeekIndex = (day: DayOfTheWeek) => {
  const indexDays = Object.keys(dayOfTheWeekValue);
  return indexDays.findIndex(value => value === day);
};

const orderedDayIndexes = (data: DayOfTheWeek[]) =>
  data.map(dayOfTheWeek => getDayOfTheWeekIndex(dayOfTheWeek)).sort((value1, value2) => value1 - value2);

export const hourFormatter = (dayData: BusinessHoursTimeRange[]) =>
  dayData.map(hour => `${hour.opensAt} - ${hour.closesAt}`).join(' e ');

const getRanges = (indexes: number[], dayData: BusinessHoursDay) => {
  const ranges: DayRange[] = [];

  indexes.forEach((dayIndex, index) => {
    if (index === 0) {
      ranges.push({
        startingDay: dayIndex,
        endingDay: dayIndex,
        hours: hourFormatter(dayData.hours),
      });
      return;
    }

    const lastRange = ranges[ranges.length - 1];
    if (lastRange.endingDay === dayIndex - 1) {
      lastRange.endingDay = dayIndex;
      return;
    }

    ranges.push({
      startingDay: dayIndex,
      endingDay: dayIndex,
      hours: hourFormatter(dayData.hours),
    });
  });

  return ranges;
};

export const businessHoursFormatter = (businessHoursData: BusinessHoursDay[] | undefined) => {
  if (!businessHoursData) {
    return [];
  }
  const dayOfTheWeekValues = Object.values(dayOfTheWeekValue);
  const orderedDays = sortBy(
    businessHoursData.flatMap(dayData => {
      const orderedDaysIdx = orderedDayIndexes(dayData.day);
      return getRanges(orderedDaysIdx, dayData);
    }),
    'startingDay',
  );

  return orderedDays.map((value, index) => ({
    id: index,
    days:
      value.startingDay === value.endingDay
        ? dayOfTheWeekValues[value.startingDay]
        : `${dayOfTheWeekValues[value.startingDay]} à ${dayOfTheWeekValues[value.endingDay]}`,
    hours: value.hours || 'Fechado',
  }));
};
