import { searchReseller } from '@/api';
import { ResellerVO, SearchType } from '@/types';
import { useQuery } from '@tanstack/react-query';
import { HTTPError } from 'ky';

export function useSearchReseller(value?: string, type?: SearchType) {
  const { data, error, refetch } = useQuery<ResellerVO[], HTTPError>({
    queryKey: [type, value, 'RESELLER_SEARCH'],
    queryFn: () => searchReseller(value, type),
    gcTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!(value && type),
    retry: false,
  });

  const noResultsFound = error?.response?.status === 404;
  const isForbidden = error?.response?.status === 403;

  return {
    isLoading: !data && !error,
    resellers: data,
    error: !noResultsFound && !isForbidden && !!error,
    errorStatus: error?.response?.status,
    noResultsFound,
    isForbidden,
    refetch,
  };
}
