import { SquareListIcon, StoreIcon } from '@grupoboticario/flora-react-icons';
import { ResellerTab } from '@/types';
import { ResellerDetailsProvider, ResellerServiceCenterProvider, useResellerDetails } from '@/contexts';
import { ResellerInfo, ServiceCenterInfo, ResellerTabs } from '@/components/reseller-details';

enum ResellerTabsValue {
  RESELLER_INFO = 'reseller-info',
  SERVICE_CENTER_INFO = 'service-center-info',
}

const ResellerDetails = (): JSX.Element | null => {
  const { csId } = useResellerDetails();

  const resellerTabList: ResellerTab[] = [
    {
      title: 'Dados da IR',
      value: ResellerTabsValue.RESELLER_INFO,
      icon: <SquareListIcon />,
      component: <ResellerInfo />,
    },
    {
      title: 'Dados da CS',
      value: ResellerTabsValue.SERVICE_CENTER_INFO,
      icon: <StoreIcon />,
      component: (
        <ResellerServiceCenterProvider>
          <ServiceCenterInfo />
        </ResellerServiceCenterProvider>
      ),
      disabled: csId === undefined,
      tabType: 'sub-tab',
    },
  ];

  return <ResellerTabs tabDefaultValue={ResellerTabsValue.RESELLER_INFO} tabList={resellerTabList} />;
};

const ResellerDetailsWrapper = (): JSX.Element => (
  <ResellerDetailsProvider>
    <ResellerDetails />
  </ResellerDetailsProvider>
);

export { ResellerDetailsWrapper as ResellerDetails };
