import { DataWrapper, NoteField } from '@/components';
import {
  InstallmentOptionList,
  PaymentLocationGeneralConditions,
  generalPaymentConditionsClipboardText,
  installmentOptionsClipboardText,
  notesClipBoardText,
  useCopyDataWrapperInfo,
} from '@/components/reseller-details';
import { CreditCardPayment } from '@/types';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

interface CreditCardPaymentViewProps {
  creditCardPaymentData: CreditCardPayment;
}
const CreditCardPaymentView = ({ creditCardPaymentData }: CreditCardPaymentViewProps) => {
  const {
    hasInstallmentOptions,
    installmentOptions,
    hasMinimumInstallmentValue,
    minimumInstallmentValue,
    note,
    paymentLocations,
  } = creditCardPaymentData;

  const { onCopy } = useCopyDataWrapperInfo({
    text: [
      `${installmentOptionsClipboardText(installmentOptions)}`,
      '',
      `${generalPaymentConditionsClipboardText(paymentLocations, 'crédito')}`,
      '',
      `${notesClipBoardText(note)}`,
    ].join(`\r\n`),
    successMessage: 'Lista de condições copiada',
    section: 'condições',
  });

  return (
    <DataWrapper
      title="Cartão de crédito"
      data-testid="credit-card-payment"
      css={{ gridColumn: 'span 2' }}
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar Condições',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      <PaymentLocationGeneralConditions conditions={paymentLocations} />
      <InstallmentOptionList
        installmentOptions={installmentOptions}
        hasInstallmentOptions={hasInstallmentOptions}
        hasMinimumInstallmentValue={hasMinimumInstallmentValue}
        minimumInstallmentValue={minimumInstallmentValue}
      />
      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};
export { CreditCardPaymentView };
