import { events, useEventTracker } from '@/api';
import {
  EditResellerAddress,
  EditResellerDrawerFooter,
  ResellerAddress,
  generateAddressOnCopyText,
  useCopyDataWrapperInfo,
} from '@/components/reseller-details';
import { useResellerDataEditEnabler } from '@/components/reseller-details/hooks/';
import { DataWrapper, DataWrapperButton } from '@/components/shared';
import { useResellerDetails } from '@/contexts';
import { Address, ResellerAddressType } from '@/types';
import { Checkbox, Flex, Text } from '@grupoboticario/flora-react';
import { PencilIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { isEqual, pick } from 'lodash';
import { useCallback } from 'react';

const COPY_ADDRESS_BUTTON_LABEL = 'Copiar endereço';
const COPY_ADDRESS_BUTTON_ICON = <TwoPiledSquaresIcon size={16} />;

const formatterInitialAddressData = (homeAddress: Address | undefined, deliveryAddress: Address | undefined) => {
  const address = deliveryAddress ?? homeAddress;
  if (address) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { type, ...rest } = address;
    return rest;
  }
  return undefined;
};

const formatterDeliveryAddress = (deliveryAddress: Address | undefined) => {
  const address = deliveryAddress;
  if (deliveryAddress?.complement?.length === 0) {
    delete address?.complement;
  }

  return address;
};

const ResellerAddresses = (): JSX.Element => {
  const { resellerRegistry } = useResellerDetails();
  const { clickedResellerInfoButtons } = useEventTracker();

  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();

  const getAddressByType = useCallback(
    (type: ResellerAddressType) => {
      const addresses = resellerRegistry.data?.addresses ?? [];
      return addresses.find(address => address.type === type);
    },
    [resellerRegistry.data?.addresses],
  );

  const homeAddress = getAddressByType(ResellerAddressType.HOME);
  const deliveryAddress = getAddressByType(ResellerAddressType.DELIVERY);

  const homeAddressData = pick(homeAddress, 'complement', 'number', 'zipCode');
  const deliveryAddressData = pick(formatterDeliveryAddress(deliveryAddress), 'complement', 'number', 'zipCode');

  const isDeliveryAddressSameAsHomeAddress = isEqual(homeAddressData, deliveryAddressData);

  const hasDeliveryAddress = !!deliveryAddress && !isDeliveryAddressSameAsHomeAddress;

  const attendanceInfoData = {
    geraId: resellerRegistry.data?.geraId,
    socialName: resellerRegistry.data?.socialName,
    cpCs: resellerRegistry.data?.commercialStructure?.parentStructure.name,
  };

  const initialAddressData = formatterInitialAddressData(homeAddress, deliveryAddress);

  const useCopyAddressData = (addressData: Address) => {
    const { onCopy } = useCopyDataWrapperInfo({
      text: generateAddressOnCopyText(addressData),
      successMessage: 'Dados de endereço copiados',
      section: 'endereco',
    });

    return onCopy;
  };

  const copyHomeAddressButton = {
    action: useCopyAddressData(homeAddress),
    label: COPY_ADDRESS_BUTTON_LABEL,
    icon: COPY_ADDRESS_BUTTON_ICON,
  };

  const copyDeliveryAddressButton = {
    action: useCopyAddressData(deliveryAddress),
    label: COPY_ADDRESS_BUTTON_LABEL,
    icon: COPY_ADDRESS_BUTTON_ICON,
  };

  const editAddressButton = {
    label: 'Alterar dados',
    tooltipText: disabledButtonTooltip,
    disabled: isEditingDisabled,
    action: () => {
      clickedResellerInfoButtons({
        section: 'alterar-dados-endereco-entrega',
      }),
        events.emit('OPEN_DRAWER_INFO', {
          size: 'medium',
          title: 'Altere os dados do endereço de entrega:',
          content: (
            <EditResellerAddress
              initialAddress={initialAddressData}
              attendanceInfo={attendanceInfoData}
              commercialStructureCode={resellerRegistry.data?.commercialStructure?.code}
            />
          ),
          footer: <EditResellerDrawerFooter />,
        });
    },
    icon: <PencilIcon size={18} data-testid="icon-button" />,
  };

  const homeAddressWrapperButtons = (): DataWrapperButton[] => {
    const wrapperButtons = [copyHomeAddressButton, editAddressButton];

    if (!isDeliveryAddressSameAsHomeAddress) {
      wrapperButtons.pop();
    }

    return wrapperButtons;
  };

  const deliveryAddressWrapperButtons: DataWrapperButton[] = [copyDeliveryAddressButton, editAddressButton];

  return (
    <>
      <DataWrapper title="Endereço" data-testid="reseller-contact" wrapperButtons={homeAddressWrapperButtons()}>
        <ResellerAddress address={homeAddress} addressHeaderLabel={hasDeliveryAddress ? 'Residencial' : undefined} />
        <Flex
          align="center"
          gap="$2"
          css={{
            margin: '$4 0 0 $8',
          }}
        >
          <Checkbox id="is-delivery-address" checked={isDeliveryAddressSameAsHomeAddress} disabled />
          <Text as="label" css={{ fontWeight: '$medium' }} size="bodyMediumShort" htmlFor="is-delivery-address">
            Mesmo endereço de entrega.
          </Text>
        </Flex>
      </DataWrapper>
      {hasDeliveryAddress && (
        <DataWrapper wrapperButtons={deliveryAddressWrapperButtons}>
          <ResellerAddress address={deliveryAddress} addressHeaderLabel="Entrega" />
        </DataWrapper>
      )}
    </>
  );
};

export { ResellerAddresses };
