import { Button, Flex, Text } from '@grupoboticario/flora-react';
import { ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { DataWrapper, InfoWrapper, TextInfo } from '@/components';
import {
  NOT_SPECIFIED,
  RequirementItem,
  RequirementsListButtonLabel,
  useCopyDataWrapperInfo,
  useListContentHandler,
} from '@/components/reseller-details';

interface CopyBlockToClipboardProps {
  copyButtonLabel: string;
  copyTitleMessage: string;
  copyTextSuccess: string;
}
interface RequirementsListProps {
  title: string;
  listItems: RequirementItem[];
  buttonLabel?: RequirementsListButtonLabel;
  copyBlockToClipboard?: CopyBlockToClipboardProps;
}

const RequirementsList = ({
  title,
  listItems,
  buttonLabel = { showMore: 'Mostrar mais', showLess: 'Mostrar menos' },
  copyBlockToClipboard,
}: RequirementsListProps): JSX.Element => {
  const { isListExpanded, toggleListItems, itemsToShow, isListExpansible } = useListContentHandler<RequirementItem>(
    listItems,
    3,
  );

  const { copyButtonLabel, copyTextSuccess, copyTitleMessage } = copyBlockToClipboard ?? {};

  const requirementsItems = listItems.map(item => item.text) ?? [];
  const { onCopy } = useCopyDataWrapperInfo({
    text: `${copyTitleMessage}:\r\n${requirementsItems.join('\r\n')}`,
    successMessage: copyTextSuccess as string,
    section: title,
  });

  return listItems.length > 0 ? (
    <DataWrapper
      css={{ gridColumn: 'span 2' }}
      wrapperButtons={
        copyBlockToClipboard
          ? [
              {
                action: onCopy,
                label: copyButtonLabel as string,
                icon: <TwoPiledSquaresIcon size={16} />,
              },
            ]
          : undefined
      }
    >
      <InfoWrapper isFullWidth>
        <Text size="exceptionsRestrictedRegular" color="$nonInteractiveAuxiliar">
          {title}
        </Text>

        <Flex css={{ marginTop: '$2' }} direction="column" gap="$4" data-testid="list-item">
          {itemsToShow.map(item => (
            <Flex align="center" justify="start" gap="$2" data-testid="items" key={`${title}__${item.text}`}>
              <CheckCircleIcon color="$nonInteractiveAuxiliar" size="16" />
              <Text size="bodyLargeShortRegular" color="$nonInteractivePredominant">
                {item.text}
              </Text>
            </Flex>
          ))}
          {isListExpansible && (
            <Flex align="center" justify="center" css={{ marginTop: '$7' }}>
              <Button
                hierarchy="secondary"
                data-testid="btn-list-item-toggle"
                onClick={toggleListItems}
                size="small"
                icon={isListExpanded ? <ArrowUpIcon size="16" /> : <ArrowDownIcon size="16" />}
              >
                {isListExpanded ? buttonLabel.showLess : buttonLabel.showMore}
              </Button>
            </Flex>
          )}
        </Flex>
      </InfoWrapper>
    </DataWrapper>
  ) : (
    <TextInfo isFullWidth title={title} fallbackText={NOT_SPECIFIED} />
  );
};
export { RequirementsList };
