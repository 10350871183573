import { InfoWrapper, Detail } from '@/components';
import { Grid, Text } from '@grupoboticario/flora-react';

interface ServiceCenterBusinessHoursInfoProps {
  days?: string;
  hours: string;
  isOpenOnHolidays?: boolean;
}

const ServiceCenterBusinessHoursInfo = ({
  days,
  hours,
  isOpenOnHolidays = false,
}: ServiceCenterBusinessHoursInfoProps): JSX.Element => (
  <InfoWrapper isFullWidth>
    <Grid templateColumns="repeat(2, 1fr)" align="center" data-testid="business-hours-info">
      <Detail text={isOpenOnHolidays ? 'Feriado' : `${days}`} />
      <Text css={{ marginLeft: '$8' }}>{hours}</Text>
    </Grid>
  </InfoWrapper>
);

export { ServiceCenterBusinessHoursInfo };
