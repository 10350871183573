import { CrossCircleIcon, ExclamationMarkTriangleFillIcon, ShoppingBagIcon } from '@grupoboticario/flora-react-icons';
import { TagStatus } from '@/components';
import { RiskAnalysis } from '@/types';

interface ResellerTagStatusProps {
  hasFirstOrder: boolean;
  status?: RiskAnalysis;
}

const ResellerTagStatus = ({ hasFirstOrder, status }: ResellerTagStatusProps): JSX.Element | null => {
  return (
    <>
      {status === RiskAnalysis.VERY_HIGH_RISK && (
        <TagStatus
          variant="danger"
          size="small"
          statusText="Cadastro não aprovado na análise antifraude."
          statusIcon={<CrossCircleIcon />}
        />
      )}

      {status === RiskAnalysis.NOT_PROCESSED && (
        <TagStatus
          variant="warning"
          size="small"
          statusText="Análise cadastral em andamento."
          statusIcon={<ExclamationMarkTriangleFillIcon />}
        />
      )}

      {hasFirstOrder && (
        <TagStatus size="small" statusText="Primeiro pedido realizado." statusIcon={<ShoppingBagIcon />} />
      )}
    </>
  );
};

export { ResellerTagStatus };
