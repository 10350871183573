import { Text, useMediaQuery, CSS, ButtonProps, Button, Flex } from '@grupoboticario/flora-react';
import { ColorThemeProps } from '@grupoboticario/flora';

type VariantType = 'success' | 'warning' | 'danger';

interface TagStatusProps {
  statusText: string;
  variant?: VariantType;
  size?: 'small' | 'normal';
  statusIcon: ButtonProps['icon'];
  css?: CSS;
}

const handleTagStatusInfo = (variant: VariantType) => {
  if (variant === 'warning') {
    return {
      bgColor: 'rgba(251, 198, 121, 0.1)',
      textColor: '$alert-standard' as ColorThemeProps,
    };
  }

  if (variant === 'danger') {
    return {
      bgColor: 'rgba(255, 102, 102, 0.1)',
      textColor: '$error-standard' as ColorThemeProps,
    };
  }

  return {
    bgColor: 'rgba(13, 171, 1, 0.1)',
    textColor: '$success-standard' as ColorThemeProps,
  };
};

const TagStatus = ({ statusText, variant = 'success', statusIcon, css }: TagStatusProps): JSX.Element => {
  const isTabletMediaQuery = useMediaQuery('(min-width: 768px)');
  const { bgColor, textColor } = handleTagStatusInfo(variant);

  return (
    <Text
      color={textColor}
      size="bodyMediumStandard"
      css={{
        alignItems: 'flex-start',
        background: bgColor,
        borderRadius: '$small',
        display: 'inline-block',
        marginBottom: '$2',
        padding: '$1 $5 $1 $2',
        textOverflow: 'ellipsis',
        width: '100%',
        ...css,
      }}
    >
      <Flex align="center">
        {isTabletMediaQuery && <Button hierarchy="tertiary" has="iconOnly" icon={statusIcon} aria-label={statusText} />}
        {statusText}
      </Flex>
    </Text>
  );
};

export { TagStatus };
