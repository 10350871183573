import { DataWrapper, NoteField } from '@/components';
import {
  InstallmentOptionList,
  InstructionBox,
  RequirementItem,
  RequirementsList,
} from '@/components/reseller-details';
import { BankSlipPayment } from '@/types';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';
import { compact } from 'lodash';

interface BankSlipPaymentViewProps {
  bankSlipPaymentData: BankSlipPayment;
}

const getConditionOptions = (hasPaymentCash = false, hasThirtyDaysPayment = false): RequirementItem[] =>
  compact([hasPaymentCash && 'A vista', hasThirtyDaysPayment && '30 dias']).map(condition => ({ text: condition }));

const BankSlipPaymentView = ({ bankSlipPaymentData }: BankSlipPaymentViewProps) => {
  const {
    note,
    hasMinimumInstallmentValue,
    minimumInstallmentValue,
    hasPaymentCash,
    hasThirtyDaysPayment,
    hasInstallmentOptions,
    consumesAvailableCredit,
    installmentOptions,
  } = bankSlipPaymentData;

  const showConditionsBlock = hasPaymentCash || hasThirtyDaysPayment;
  return (
    <DataWrapper title="Boleto bancário" data-testid="bank-slip-payment">
      {!!consumesAvailableCredit && (
        <InstructionBox
          title="Permite consumir o limite de crédito disponível"
          icon={<CheckCircleIcon css={{ color: '$8' }} size="32" />}
          isFullWidth
        />
      )}

      {showConditionsBlock && (
        <RequirementsList
          title="Condições Gerais"
          listItems={getConditionOptions(hasPaymentCash, hasThirtyDaysPayment)}
        />
      )}

      <InstallmentOptionList
        installmentOptions={installmentOptions}
        hasInstallmentOptions={hasInstallmentOptions}
        hasMinimumInstallmentValue={hasMinimumInstallmentValue}
        minimumInstallmentValue={minimumInstallmentValue}
      />

      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { BankSlipPaymentView };
