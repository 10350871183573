import { useEffect, useState } from 'react';
import { Flex, Text } from '@grupoboticario/flora-react';
import { differenceInSeconds, intervalToDuration } from 'date-fns';
import { ClockTimerIcon } from '@grupoboticario/flora-react-icons';
import { Loading } from '@/components';

interface ServiceTimerProps {
  startedTime?: Date;
}

const initialTimeString = '0h:0m:0s';

export const ServiceTimer = ({ startedTime }: ServiceTimerProps): JSX.Element => {
  const [timer, setTimer] = useState(initialTimeString);
  const [isTimerLoad, setIsTimerLoad] = useState(false);

  useEffect(() => {
    let timerInterval: NodeJS.Timer;
    if (startedTime) {
      setIsTimerLoad(true);
      timerInterval = setInterval(() => {
        const timeDifferenceInSeconds = differenceInSeconds(new Date(), startedTime);

        const duration = {
          hours: 0,
          days: 0,
          ...intervalToDuration({
            start: 0,
            end: timeDifferenceInSeconds * 1000,
          }),
        };

        const totalHours = duration.hours + duration.days * 24;

        const formattedDuration = [
          `${String(totalHours).padStart(2, '0')}h`,
          `${String(duration.minutes).padStart(2, '0')}m`,
          `${String(duration.seconds).padStart(2, '0')}s`,
        ].join(':');

        setTimer(formattedDuration);
        setIsTimerLoad(false);
      }, 1000);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [startedTime]);

  return (
    <Flex
      css={{
        textAlign: 'center',
        marginLeft: '$8',
        paddingLeft: '$8',
        borderLeft: '1px solid $nonInteractiveAltAuxiliar',
      }}
      justify="center"
      direction="column"
      align="flex-start"
      gap="$1"
    >
      {isTimerLoad ? (
        <Flex css={{ justifyContent: 'center' }}>
          <Loading />
        </Flex>
      ) : (
        <>
          <Text color="$nonInteractiveAuxiliar" size="exceptionsRestrictedRegular">
            Tempo de atendimento
          </Text>
          <Flex>
            <ClockTimerIcon
              css={{
                color: '$brand-1',
              }}
            />
            <Text
              color={startedTime ? '$nonInteractivePredominant' : '$nonInteractiveAuxiliar'}
              css={{ fontSize: '$2xl', paddingLeft: '$1' }}
              data-testid="timer-text"
            >
              {timer}
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};
