import useSWR, { Key, SWRConfiguration } from 'swr';
import { HTTPError } from 'ky';
import { FetcherOptions, fetcher } from '@/api';

function useRequest<ReturnType, ErrorType = HTTPError>(
  swrKey: Key,
  url: string,
  fetcherOptions: FetcherOptions | undefined = undefined,
  options: SWRConfiguration = {},
) {
  return useSWR<ReturnType, ErrorType>(
    swrKey,
    () =>
      fetcher.get<ReturnType>(url, {
        timeout: 30000,
        ...(fetcherOptions ?? {}),
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      ...options,
    },
  );
}

export { useRequest };
