import { fetcher } from '@/api';

const baseUrl = 'services';

export async function finishService(attendanceId: string) {
  const response = await fetcher.patch(`${baseUrl}/${attendanceId}/finish`);
  return response.json();
}

export async function cancelService(attendanceId: string) {
  const response = await fetcher.patch(`${baseUrl}/${attendanceId}/cancel`);

  return response.json();
}
