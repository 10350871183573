import { useEditResellerFormState } from '@/components/reseller-details/hooks';
import { TagStatus } from '@/components/shared';
import { Flex } from '@grupoboticario/flora-react';
import { ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons';
import { EditResellerAddressForm } from '../edit-reseller-address-form';
import { InitialAddressData } from '../types';
import { AttendanceInfo, AttendanceInfoData } from '@/components/reseller-details';

interface EditResellerAddressProps {
  initialAddress: InitialAddressData | undefined;
  attendanceInfo: AttendanceInfoData;
  commercialStructureCode: string | undefined;
}

const EditResellerAddress = ({ initialAddress, attendanceInfo, commercialStructureCode }: EditResellerAddressProps) => {
  const { editResellerFormState } = useEditResellerFormState();

  return (
    <Flex data-testid="edit-reseller-address-wrapper" direction="column" gap="$6" css={{ marginTop: '$5' }}>
      <TagStatus
        variant="warning"
        statusText="Não será possível realizar alterações
        de endereços cujos CEP's resultarem na alocação das IR’s
        em outros CP's. Nesses casos por favor, verifique com seu
        líder operacional para prosseguir com as alterações."
        statusIcon={<ExclamationMarkHexagonIcon />}
      />
      {editResellerFormState.hasError && !editResellerFormState.isSubmitting && (
        <TagStatus
          variant="danger"
          statusText="Não foi possível salvar as alterações. Por favor, tente novamente clicando no botão ao final da página."
          statusIcon={<ExclamationMarkHexagonIcon />}
        />
      )}

      <AttendanceInfo attendanceInfo={attendanceInfo} />
      <EditResellerAddressForm
        initialAddress={initialAddress}
        commercialStructureCode={commercialStructureCode}
        resellerGeraId={attendanceInfo.geraId}
      />
    </Flex>
  );
};

export { EditResellerAddress };
