import { Box, Card, Flex } from '@grupoboticario/flora-react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useClickAway, useKeyPress } from 'react-use';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import { SearchResultItem, UserSearchEmptyState } from '@/components/user-search/components';
import type { ResellerVO, SearchResellerResponse } from '@/types';
import { events, useEventTracker } from '@/api';
import { Loading, TagStatus } from '@/components';

interface SearchResultListProps extends SearchResellerResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowResultList?: any;
  openServices: number;
  maxOpenServices: number;
}

const SearchResultList = ({
  resellers,
  isLoading,
  setShowResultList,
  openServices,
  maxOpenServices,
  noResultsFound,
  isForbidden,
}: SearchResultListProps): JSX.Element => {
  const isPressed = useKeyPress('Escape');
  const ref = useRef<HTMLInputElement | null>(null);
  const { clickedUserSearched } = useEventTracker();

  useClickAway(ref, () => {
    setShowResultList(false);
  });

  const canAddReseller = useMemo(
    () => !(openServices >= maxOpenServices),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openServices],
  );

  const handleResultItemClick = useCallback(
    (reseller: ResellerVO) => {
      if (canAddReseller) {
        events.emit('ADD_RESELLER_TO_USER_LIST', reseller);
      }

      clickedUserSearched(reseller.geraId);
      setShowResultList(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canAddReseller],
  );

  useEffect(() => {
    const [isEscPressed] = isPressed;
    if (isEscPressed) {
      setShowResultList(false);
    }
  }, [isPressed, setShowResultList]);

  return (
    <Card
      ref={ref}
      role="listbox"
      css={{
        border: '1px solid $nonInteractiveAltAuxiliar',
        marginTop: '$2',
        position: 'absolute',
        top: '$14',
        width: '100%',
        zIndex: 10,
      }}
      variant="active"
      data-testid="search-result-list"
      padded={false}
    >
      {isLoading && (
        <Flex align="center" justify="center" css={{ height: '200px' }}>
          <Loading />
        </Flex>
      )}

      {!isLoading && noResultsFound && (
        <UserSearchEmptyState
          title="Nenhum resultado encontrado"
          message="tente buscar com um código da VD+, CPF ou e-mail."
        />
      )}

      {!isLoading && isForbidden && (
        <UserSearchEmptyState
          title="Atenção!"
          message="A IR pesquisada não faz parte de uma CS atendida por sua operação. Por favor, informe o código da IR para sua liderança."
        />
      )}

      {!isLoading && !noResultsFound && !isForbidden && (
        <Box
          as="ul"
          css={{
            '& > li': {
              cursor: !canAddReseller ? 'default' : 'pointer',
            },
          }}
        >
          <>
            {resellers &&
              resellers.map((result: ResellerVO) => (
                <SearchResultItem
                  onClick={() => handleResultItemClick(result)}
                  resultData={result}
                  key={result.geraId}
                />
              ))}
            <Flex
              as="li"
              css={{
                margin: '0 $4 $4 0',
                '@fullscreen': {
                  marginLeft: '$8',
                },
                padding: '0 $3',
              }}
            >
              {!canAddReseller && (
                <TagStatus
                  variant="danger"
                  size="small"
                  statusText="Limite de consulta atingido, por favor feche uma consulta aberta para abrir uma nova"
                  statusIcon={<CrossCircleIcon />}
                />
              )}
            </Flex>
          </>
        </Box>
      )}
    </Card>
  );
};

export { SearchResultList };
