import { DataWrapper, NoteField } from '@/components';
import { InstructionBox } from '@/components/reseller-details';
import { OnlineCardPaymentType } from '@/types';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';

interface OnlinePaymentViewProps {
  onlinePaymentViewData: OnlineCardPaymentType;
}

const OnlinePaymentView = ({ onlinePaymentViewData }: OnlinePaymentViewProps) => {
  const { note } = onlinePaymentViewData;
  return (
    <DataWrapper title="Cartão online" data-testid="online-card-payment">
      <InstructionBox
        title="Permite compra com cartão on-line"
        icon={<CheckCircleIcon css={{ color: '$8' }} size="32" />}
        isFullWidth
      />

      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { OnlinePaymentView };
