import { useRequest } from '@/api';
import { ServiceCenterStatusType, ServiceCenter } from '@/types';
import { HTTPError } from 'ky';
import { mutate } from 'swr';

export function useGetServiceCenterByCsCode(id: string | undefined, status?: ServiceCenterStatusType) {
  const { data, error } = useRequest<ServiceCenter, HTTPError>(
    id ? ['service-centers/info', id] : null,
    `service-centers/info/${id}`,
    status && {
      searchParams: {
        status,
      },
    },
  );

  return {
    isLoading: !data && !error,
    data,
    error,
    retry: () => mutate(['service-centers/infos', id]),
  };
}
