import { rest } from 'msw';
import {
  fakeSearchServiceList,
  notChangeCommercialStructureMockData,
  resellerCredit,
  resellerRegistry,
} from '@/mocks/data';
import { apiPath } from '@/mocks/utils/apiPath';
import { delayedResponse } from '@/mocks/utils/delayedResponse';
import { ResellerVO, RiskAnalysis, SearchType } from '@/types';

const userList = fakeSearchServiceList;

const searchResellers = rest.get(apiPath('v1/resellers/search'), (req, _res, ctx) => {
  let result: ResellerVO[] = [];

  if (req.url.searchParams.has(SearchType.GERA_ID)) {
    const geraId = req.url.searchParams.get(SearchType.GERA_ID);
    result = userList.filter(item => item.geraId === geraId);
  }

  if (req.url.searchParams.has(SearchType.CPF)) {
    const cpf = req.url.searchParams.get(SearchType.CPF);
    result = userList.filter(item => item.cpf === cpf);
  }

  if (req.url.searchParams.has(SearchType.EMAIL)) {
    const email = req.url.searchParams.get(SearchType.EMAIL);
    result = userList.filter(item => item.email === email);
  }

  if (result.length) {
    return delayedResponse(ctx.status(200), ctx.json(result));
  }

  if (req.url.searchParams.get(SearchType.GERA_ID) === '500') {
    return delayedResponse(ctx.status(500), ctx.json({ message: 'Internal Server Error' }));
  }

  return delayedResponse(ctx.status(404), ctx.json({ message: 'Not Found' }));
});

const getResellerRegistry = rest.get(apiPath('v1/resellers/:geraId/cadastro'), (_req, _res, ctx) => {
  if (_req.params?.geraId === fakeSearchServiceList[1].geraId) {
    return delayedResponse(ctx.status(200), ctx.json({}));
  }

  if (_req.params?.geraId === fakeSearchServiceList[2].geraId) {
    return delayedResponse(ctx.status(200));
  }

  return delayedResponse(ctx.status(200), ctx.json(resellerRegistry));
});

const getResellerCredit = rest.get(apiPath('v1/resellers/:geraId/creditosrevendedora'), (_req, _res, ctx) => {
  return delayedResponse(ctx.status(200), ctx.json(resellerCredit));
});

const getRisk = rest.get(apiPath('v1/risk/*'), (_req, _res, ctx) => {
  return delayedResponse(ctx.status(200), ctx.json({ riskAnalysis: RiskAnalysis.LOW_RISK }));
});

const checkCommercialStructure = rest.get(apiPath('v1/resellers/checkcommercialstructure'), (req, _res, ctx) => {
  if (req.url.searchParams.get('cep') === '22240000') {
    return delayedResponse(ctx.status(200), ctx.json(notChangeCommercialStructureMockData));
  }

  if (req.url.searchParams.get('cep') === '50050500') {
    return delayedResponse(ctx.status(500), ctx.json({ message: 'Request failed with status code 500' }));
  }

  return delayedResponse(ctx.status(200), ctx.json(notChangeCommercialStructureMockData));
});

const updateResellerAddress = rest.patch(apiPath('v1/resellers/:geraId/address'), async (req, _res, ctx) => {
  const body = await req.json();
  return delayedResponse(ctx.status(200), ctx.json({ body }));
});

const updateResellerContact = rest.patch(apiPath('v1/resellers/:geraId/contacts'), async (req, _res, ctx) => {
  const body = await req.json();

  return delayedResponse(ctx.status(200), ctx.json({ body }));
});

const updateResellerNames = rest.patch(
  apiPath('v1/resellers/:geraId/dadospessoais'),

  async (req, _res, ctx) => {
    const body = await req.json();

    return delayedResponse(ctx.status(200), ctx.json({ body }));
  },
);
const updateResellerDocumentStatus = rest.patch(
  apiPath('v1/resellers/:geraId/documents'),

  async (req, _res, ctx) => {
    const body = await req.json();

    return delayedResponse(ctx.status(200), ctx.json({ body }));
  },
);

export const reseller = [
  searchResellers,
  getResellerCredit,
  getResellerRegistry,
  getRisk,
  checkCommercialStructure,
  updateResellerAddress,
  updateResellerContact,
  updateResellerNames,
  updateResellerDocumentStatus,
];
