import { ServiceCenterLimit } from '@/components/reseller-details';
import { TagStatus } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { Flex } from '@grupoboticario/flora-react';
import { ExclamationMarkTriangleFillIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterLockAndLimit = () => {
  const lockAndLimit = useResellerServiceCenter().csData?.lockAndLimit;
  const hasBlockAfterFirstOrder = !!lockAndLimit?.hasBlockAfterFirstOrder;
  const canUnlockAfterPayingFirstOrder = hasBlockAfterFirstOrder && !!lockAndLimit?.unlockAfterPayingFirstOrder;
  const hasTagStatusContainer = hasBlockAfterFirstOrder || canUnlockAfterPayingFirstOrder;

  return (
    <Flex direction="column" gap="$6">
      {hasTagStatusContainer && (
        <Flex direction="column" css={{ marginBottom: '$1' }}>
          {hasBlockAfterFirstOrder && (
            <TagStatus
              variant="warning"
              statusText="Bloquear cadastro após realização do primeiro pedido."
              statusIcon={<ExclamationMarkTriangleFillIcon css={{ color: '$3' }} />}
            />
          )}
          {canUnlockAfterPayingFirstOrder && (
            <TagStatus
              variant="warning"
              statusText="Desbloquear cadastro após confirmação do pagamento do primeiro pedido."
              statusIcon={<ExclamationMarkTriangleFillIcon css={{ color: '$3' }} />}
            />
          )}
        </Flex>
      )}

      <ServiceCenterLimit />
    </Flex>
  );
};

export { ServiceCenterLockAndLimit };
