import { ResellerVO, Service, ServiceStatus } from '@/types';

export const fakeServiceList: Service[] = [
  {
    _id: '64bea6441fc14732f9be616d',
    description: 'Atendimento aberto por 384654',
    status: ServiceStatus.OPEN,
    history: [
      {
        status: ServiceStatus.OPEN,
        user: '384654',
        createdAt: '2023-07-24T16:26:44.878Z',
      },
    ],
    users: [
      {
        userId: '1233',
        email: 'mock@email.com',
        name: 'Mock User',
      },
    ],
    reseller: {
      geraId: '1234567',
      cpf: '12962611079',
      hasFirstOrder: false,
      name: 'MARCELO ANTONIO MARQUES',
    },
    updatedAt: '2023-07-24T16:26:44.880Z',
  },
  {
    _id: '64bed6041fc14732f9be6315',
    description: 'Atendimento aberto por 384654',
    status: ServiceStatus.IN_ATTENDANCE,
    history: [
      {
        status: ServiceStatus.IN_ATTENDANCE,
        user: '384654',
        createdAt: '2023-07-24T19:50:28.285Z',
      },
    ],
    users: [
      {
        userId: '1233',
        email: 'mock@email.com',
        name: 'Mock User',
      },
    ],
    reseller: {
      geraId: '10018375',
      cpf: '40136472141',
      hasFirstOrder: false,
      name: 'DÉBORA FÁTIMA DA SILVA',
    },
    updatedAt: '2023-07-24T19:50:28.286Z',
  },
  {
    _id: '64bed60b988d667e30a8b39a',
    description: 'Atendimento aberto por 384654',
    status: ServiceStatus.OPEN,
    history: [
      {
        status: ServiceStatus.OPEN,
        user: '384654',
        createdAt: '2023-07-24T19:50:35.083Z',
      },
    ],
    users: [
      {
        userId: '1233',
        email: 'mock@email.com',
        name: 'Mock User',
      },
    ],
    reseller: {
      geraId: '10009710',
      cpf: '80312314000',
      hasFirstOrder: false,
      name: 'NOME DE REGISTRO',
    },
    updatedAt: '2023-07-24T19:50:35.085Z',
  },
  {
    _id: '64bed6311fc14732f9be6322',
    description: 'Atendimento aberto por 384654',
    status: ServiceStatus.OPEN,
    history: [
      {
        status: ServiceStatus.OPEN,
        user: '384654',
        createdAt: '2023-07-24T19:51:13.877Z',
      },
    ],
    users: [
      {
        userId: '1233',
        email: 'mock@email.com',
        name: 'Mock User',
      },
    ],
    reseller: {
      geraId: '10018369',
      cpf: '11426728247',
      hasFirstOrder: false,
      name: 'CHANDLER GRAY2',
    },
    updatedAt: '2023-07-24T19:51:13.878Z',
  },
];

export const fakeSearchServiceList: ResellerVO[] = [
  {
    attendanceId: '42154773010',
    name: 'Gayelord Ludee',
    cpf: '42154773010',
    geraId: '9394376',
    email: 'gludee0@privacy.gov.au',
    hasFirstOrder: true,
    serviceStatus: ServiceStatus.IN_ATTENDANCE,
  },
  {
    attendanceId: '90740618834',
    name: 'Thibaud Grayland',
    cpf: '90740618834',
    geraId: '7524722',
    email: 'tgrayland1@dell.com',
    hasFirstOrder: true,
    serviceStatus: ServiceStatus.IN_ATTENDANCE,
  },
  {
    attendanceId: '92971403917',
    name: 'Elijah Suermeier',
    cpf: '92971403917',
    geraId: '6603566',
    email: 'esuermeier2@youku.com',
    hasFirstOrder: true,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '69055334492',
    name: 'Julie LinNey',
    cpf: '69055334492',
    geraId: '7139812',
    email: 'jlinney3@360.cn',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '48449923243',
    name: 'Merola Fotitt',
    cpf: '48449923243',
    geraId: '2385898',
    email: 'mfotitt4@cam.ac.uk',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '60307894295',
    name: 'Viva Codling',
    cpf: '60307894295',
    geraId: '7968797',
    email: 'repeatedemail@email.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '95383147830',
    name: 'Kimbra Griffen',
    cpf: '95383147830',
    geraId: '1225819',
    email: 'kgriffen6@chron.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '66320550013',
    name: 'Arabele Redier',
    cpf: '66320550013',
    geraId: '3128570',
    email: 'aredier7@tiny.cc',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '96763190521',
    name: 'Griffy Emmins',
    cpf: '96763190521',
    geraId: '1071691',
    email: 'gemmins8@ox.ac.uk',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '01412527127',
    name: 'Maddie Wigginton',
    cpf: '01412527127',
    geraId: '6084802',
    email: 'mwigginton9@wunderground.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '56710436038',
    name: 'Lorrie Prosek',
    cpf: '56710436038',
    geraId: '4289258',
    email: 'lproseka@bluehost.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '12811472246',
    name: 'Luella Dewen',
    cpf: '12811472246',
    geraId: '5942911',
    email: 'ldewenb@addthis.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '59976696787',
    name: 'Flor Catonnet',
    cpf: '59976696787',
    geraId: '8992857',
    email: 'fcatonnetc@tmall.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '33579451281',
    name: 'Aggy Bembridge',
    cpf: '33579451281',
    geraId: '3235948',
    email: 'abembridged@toplist.cz',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '12117055898',
    name: 'Oralla Minghella',
    cpf: '12117055898',
    geraId: '4319301',
    email: 'ominghellae@ehow.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '42985732495',
    name: 'Holt Poppy',
    cpf: '42985732495',
    geraId: '7611812',
    email: 'hpoppyf@intel.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '42955210114',
    name: 'Giana Tirrey',
    cpf: '42955210114',
    geraId: '6377357',
    email: 'gtirreyg@businessinsider.com',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '65612702628',
    name: 'Gannon Saffe',
    cpf: '65612702628',
    geraId: '6602763',
    email: 'gsaffeh@umich.edu',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '01060181730',
    name: 'Juieta Gieves',
    cpf: '01060181730',
    geraId: '3705413',
    email: 'jgievesi@admin.ch',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
  {
    attendanceId: '83810044080',
    name: 'Karlen Rois',
    cpf: '83810044080',
    geraId: '2540300',
    email: 'kroisj@odnoklassniki.ru',
    hasFirstOrder: false,
    serviceStatus: ServiceStatus.OPEN,
  },
];
