import { DataWrapper, NoteField } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';

import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import { ServiceCenterBusinessHoursInfo } from '../service-center-business-hours-info';
import { businessHoursFormatter, hourFormatter } from './utils';
import { generateBusinessHoursOnCopyText, useCopyDataWrapperInfo } from '@/components/reseller-details';

const ServiceCenterBusinessHours = (): JSX.Element => {
  const { csData } = useResellerServiceCenter();
  const dayOfTheWeek = businessHoursFormatter(csData?.businessHours?.days);
  const holidaysBusinessHours = hourFormatter(csData?.businessHours?.holidays?.hours ?? []);

  const isOpenOnHoliday = csData?.businessHours?.holidays?.openOnHolidays;
  const note = csData?.businessHours?.note;

  const { onCopy } = useCopyDataWrapperInfo({
    text: generateBusinessHoursOnCopyText(dayOfTheWeek, holidaysBusinessHours, note),
    successMessage: 'Horário de funcionamento copiado',
    section: 'horário de funcionamento',
  });

  return (
    <DataWrapper
      title="Horário de funcionamento"
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar horários',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      {dayOfTheWeek.length
        ? dayOfTheWeek.map(officeHour => (
            <ServiceCenterBusinessHoursInfo days={officeHour.days} hours={officeHour.hours} key={officeHour.id} />
          ))
        : null}

      {isOpenOnHoliday && (
        <ServiceCenterBusinessHoursInfo hours={holidaysBusinessHours} isOpenOnHolidays={isOpenOnHoliday} />
      )}

      {csData?.businessHours?.note?.length ? <NoteField text={note} /> : null}
    </DataWrapper>
  );
};

export { ServiceCenterBusinessHours };
