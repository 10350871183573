import * as yup from 'yup';
import { debounce } from 'lodash';
import { HTTPError } from 'ky';
import { checkCommercialStructure } from '@/api';
import { getPostalService } from '@/api/utils/';
import { cleanupSpecialCharacters } from '@/utils';
import { InitialAddressData } from '../../types';

const INPUT_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const ASYNC_VALIDATION_TIMEOUT_IN_MS = 100;

async function validateCepAndCommercialStructure(
  zipCode: string,
  initialZipCode: string,
  resolve: (value: boolean | yup.ValidationError) => void,
  context: yup.TestContext,
  commercialStructureCode: string | undefined,
) {
  if (zipCode === initialZipCode) {
    return resolve(true);
  }

  try {
    await getPostalService(zipCode);
  } catch (error) {
    const errors = error as HTTPError;
    if (errors.response.status === 500) {
      return resolve(
        context.createError({
          message: 'Não foi possível consultar o CEP.',
        }),
      );
    }

    return resolve(
      context.createError({
        message: 'Não foi possível encontrar o CEP informado.',
      }),
    );
  }

  const cpError = context.createError({
    message: 'Alocação em outro CP, leia o aviso no topo.',
  });
  try {
    if (!commercialStructureCode) {
      return resolve(cpError);
    }
    const { canChange } = await checkCommercialStructure(commercialStructureCode, zipCode);

    if (!canChange) {
      return resolve(context.createError(cpError));
    }

    return resolve(canChange);
  } catch (error) {
    const errors = error as HTTPError;
    if (errors.response.status === 500) {
      return resolve(
        context.createError({
          message: 'Não foi possível consultar a Alocação.',
        }),
      );
    }
    return resolve(context.createError(cpError));
  }
}

const validateCepAndCommercialStructureDebounced = debounce(
  validateCepAndCommercialStructure,
  ASYNC_VALIDATION_TIMEOUT_IN_MS,
);

export const editResellerAddressSchema: yup.ObjectSchema<InitialAddressData> = yup.object({
  zipCode: yup
    .string()
    .required(INPUT_ERROR_MESSAGE)
    .length(9, 'O CEP precisa ter 8 dígitos.')
    .test({
      test(value: string | undefined) {
        if (!value) {
          return false;
        }
        return new Promise(resolve => {
          validateCepAndCommercialStructureDebounced(
            cleanupSpecialCharacters(value),
            cleanupSpecialCharacters(this.parent?.initialZipCode),
            resolve,
            this,
            this.parent?.csCode,
          );
        });
      },
    }),
  street: yup.string().required(INPUT_ERROR_MESSAGE),
  number: yup.string().required(INPUT_ERROR_MESSAGE),
  reference: yup.string().optional(),
  city: yup.string().required(INPUT_ERROR_MESSAGE),
  state: yup.string().required(INPUT_ERROR_MESSAGE),
  complement: yup.string().optional(),
  landmark: yup.string().required(INPUT_ERROR_MESSAGE),
});
