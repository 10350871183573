export enum FederativeUnit {
  PR = 'PR',
  SC = 'SC',
  RS = 'RS',
  ES = 'ES',
  MG = 'MG',
  RJ = 'RJ',
  SP = 'SP',
  DF = 'DF',
  GO = 'GO',
  MT = 'MT',
  MS = 'MS',
  AL = 'AL',
  BA = 'BA',
  CE = 'CE',
  MA = 'MA',
  PB = 'PB',
  PE = 'PE',
  PI = 'PI',
  RN = 'RN',
  SE = 'SE',
  AC = 'AC',
  AP = 'AP',
  AM = 'AM',
  PA = 'PA',
  RO = 'RO',
  RR = 'RR',
  TO = 'TO',
}

export enum StatesOfBrazil {
  PARANA = 'Paraná',
  SANTA_CATARINA = 'Santa Catarina',
  RIO_GRANDE_DO_SUL = 'Rio Grande do Sul',
  ESPIRITO_SANTO = 'Espírito Santo',
  MINAS_GERAIS = 'Minas Gerais',
  RIO_DE_JANEIRO = 'Rio de Janeiro',
  SAO_PAULO = 'São Paulo',
  DISTRITO_FEDERAL = 'Distrito Federal',
  GOIAS = 'Goiás ',
  MATO_GROSSO = 'Mato Grosso',
  MATO_GROSSO_DO_SUL = 'Mato Grosso do Sul',
  ALAGOAS = 'Alagoas',
  BAHIA = 'Bahia',
  CEARA = 'Ceará',
  MARANHAO = 'Maranhão',
  PARAIBA = 'Paraíba',
  PERNAMBUCO = 'Pernambuco',
  PIAUI = 'Piauí',
  RIO_GRANDE_DO_NORTE = 'Rio Grande do Norte',
  SERGIPE = 'Sergipe',
  ACRE = 'Acre',
  AMAPA = 'Amapá',
  AMAZONAS = 'Amazonas',
  PARA = 'Pará',
  RONDONIA = 'Rondônia',
  RORAIMA = 'Roraima',
  TOCANTINS = 'Tocantins',
}
