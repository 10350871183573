import { rest } from 'msw';
import { configDataMock } from '@/mocks/data';
import { apiPath } from '@/mocks/utils/apiPath';
import { delayedResponse } from '@/mocks/utils/delayedResponse';

const getConfigData = rest.get(apiPath('v1/admin'), (_req, _res, ctx) => {
  return delayedResponse(ctx.status(200), ctx.json(configDataMock));
});

export const useGetConfigDataException = rest.get(apiPath('v1/config'), (_req, _res, ctx) => {
  return delayedResponse(ctx.status(500), ctx.json({}));
});

export const configData = [getConfigData];
