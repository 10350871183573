import { fetcher } from '@/api';
import { ResellerVO, SearchType } from '@/types';

export async function searchReseller(value: string, type: SearchType): Promise<ResellerVO[]> {
  const response = await fetcher.post('resellers/search', {
    json: { [type]: value },
  });

  return response.json();
}
