import { fetcher } from '@/api';
import { Service } from '@/types';

export async function addAttendanceToList(geraId: string): Promise<Service> {
  const response = await fetcher.post('services', {
    json: { resellerId: geraId },
  });

  return response.json();
}
