import { fetcher } from '@/api';
export interface CheckCommercialStructureData {
  canChange: boolean;
}

export const checkCommercialStructure = (
  commercialStructureCode: string,
  zipCode: string,
): Promise<CheckCommercialStructureData> => {
  return fetcher.get<CheckCommercialStructureData>('resellers/checkcommercialstructure', {
    searchParams: {
      csCode: commercialStructureCode,
      cep: zipCode,
    },
  });
};
