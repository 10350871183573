import { DataWrapper, TextInfo } from '@/components';
import { PhoneInfo, generateContactsOnCopyText, useCopyDataWrapperInfo } from '@/components/reseller-details';
import { useResellerServiceCenter } from '@/contexts';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterContact = (): JSX.Element => {
  const { csData } = useResellerServiceCenter();
  const contactsInfo = csData?.franchise?.contacts;

  const { onCopy } = useCopyDataWrapperInfo({
    text: generateContactsOnCopyText(contactsInfo),
    successMessage: 'Lista de contatos copiada',
    section: 'contatos',
  });

  return (
    <DataWrapper
      title="Contatos"
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar contatos',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      {contactsInfo?.phones.length &&
        contactsInfo.phones.map(phoneData => (
          <PhoneInfo
            key={phoneData.phone}
            title={phoneData.primary ? 'Telefone preferencial' : 'Telefone'}
            phoneNumber={phoneData.phone}
            canCopyToClipboard
            isWhatsapp={phoneData.whatsapp}
            isFullWidth={contactsInfo.phones.length === 1}
          />
        ))}

      {!!csData?.franchise?.contacts.email && (
        <TextInfo canCopyToClipboard isFullWidth title="E-Mail" text={csData?.franchise?.contacts.email} />
      )}
    </DataWrapper>
  );
};

export { ServiceCenterContact };
