import { Flex } from '@grupoboticario/flora-react';
import { AttendanceInfoData, EditResellerDrawerErrorMessage, AttendanceInfo } from '@/components/reseller-details';
import { EditResellerNameForm } from './components';
import { EditNameDataType } from './types';

export interface EditResellerNameProps {
  attendanceInfo: AttendanceInfoData;
  resellerNames: EditNameDataType;
}

const EditResellerName = ({ attendanceInfo, resellerNames }: EditResellerNameProps) => (
  <Flex data-testid="edit-reseller-contact-wrapper" direction="column" gap="$6" css={{ marginTop: '$5' }}>
    <EditResellerDrawerErrorMessage />
    <AttendanceInfo attendanceInfo={attendanceInfo} isEditingName />
    <EditResellerNameForm resellerNames={resellerNames} resellerGeraId={attendanceInfo.geraId} />
  </Flex>
);

export { EditResellerName };
