import { Flex, Heading, Text, TextProps } from '@grupoboticario/flora-react';
import { ColorThemeProps } from '@grupoboticario/flora';

type HeadingLevelType = '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6 | undefined;

export interface TextOrHeadingProps {
  color?: ColorThemeProps;
  headingLevel?: HeadingLevelType;
  text: string | string[];
  textSize?: TextProps['size'];
  variant?: 'text' | 'heading';
}

const TextOrHeading = ({
  color,
  headingLevel = 6,
  text,
  textSize,
  variant = 'text',
}: TextOrHeadingProps): JSX.Element => {
  const textArr = [text].flat();

  return (
    <Flex direction="column">
      {variant === 'text'
        ? textArr.map(textToRender => (
            <Text key={`text__${textToRender}`} size={textSize} color={color}>
              {textToRender}
            </Text>
          ))
        : textArr.map(textToRender => (
            <Heading key={`heading__${textToRender}`} level={headingLevel} color={color}>
              {textToRender}
            </Heading>
          ))}
    </Flex>
  );
};

export { TextOrHeading };
