import { useRequest } from '@/api';
import { ResellerCreditResponse, ResellerCredit } from '@/types';

export function useGetResellerCredit(geraId: string): ResellerCreditResponse {
  const { data, error } = useRequest<ResellerCredit>(
    geraId ? ['resellers/creditosrevendedora', geraId] : null,
    `resellers/${geraId}/creditosrevendedora`,
  );

  return {
    isLoading: !data && !error,
    data,
    error,
  };
}
