import React from 'react';
import { Box, Flex, Text, CSS } from '@grupoboticario/flora-react';

interface EmptyStateProps {
  title: string;
  titleSize?: CSS['fontSize'];
  text: string;
  textSize?: CSS['fontSize'];
  icon?: React.ReactElement;
}

const EmptyState = ({ title, text, titleSize = '$lg', textSize = '$md', icon }: EmptyStateProps): JSX.Element => {
  return (
    <Flex align="center" justify="center" direction="column" css={{ height: '100%' }}>
      <Box css={{ color: '$nonInteractiveAltAuxiliar' }}>{icon}</Box>
      <Text
        color="$nonInteractiveAuxiliar"
        align="center"
        css={{
          padding: '$6 0 $4 0',
          fontSize: titleSize,
        }}
      >
        {title}
      </Text>
      <Text
        color="$nonInteractiveAuxiliar"
        size="bodyLargeShortRegular"
        align="center"
        css={{ maxWidth: '464px', fontSize: textSize }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export { EmptyState };
