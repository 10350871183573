import { UserList } from '@/components/user-list/components/user-list';
import { UserListProvider } from '@/components/user-list/context';

interface UserListAppProps {
  collapsed?: boolean;
  toggleColumn?: () => void;
}

const UserListApp = ({ collapsed, toggleColumn }: UserListAppProps) => {
  return (
    <UserListProvider isCollapsed={collapsed} toggleColumn={toggleColumn}>
      <UserList />
    </UserListProvider>
  );
};

export { UserListApp };
