import { Box, Input, Toaster, useMeasure } from '@grupoboticario/flora-react';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { SearchIconButton, SearchResultList } from '@/components/user-search/components';
import { ValidateSearch } from '@/components/user-search/types';
import { validateSearchType } from '@/components/user-search/utils';
import { events, useEventTracker, useGetConfigData, useGetMyAttendances, useSearchReseller } from '@/api';
import { ResellerVO, SearchType } from '@/types';
import { cleanupSpecialCharacters } from '@/utils';

const SEARCH_FIELD = 'searchField';
const ERROR_API_MESSAGE = 'Falha ao processar a solicitação, por favor, tente novamente.';
const ERROR_TOAST_MESSAGE = 'Desculpe, algo deu errado.';
const ERROR_FORBIDDEN_MESSAGE =
  'A IR pesquisada não faz parte de uma CS atendida por sua operação. Por favor, informe o código da IR para sua liderança.';
const NOT_FOUND_MESSAGE = 'tente buscar com um código da VD+, CPF ou e-mail.';

export const GENERIC_ERROR_INPUT_MESSAGE = 'Busca inválida. Por favor, informe um código, CPF ou e-mail válidos.';
export const EMAIL_ERROR_INPUT_MESSAGE = 'Busca inválida. Por favor, informe um e-mail válido.';
export const CPF_ERROR_INPUT_MESSAGE = 'Busca inválida. Por favor, informe um CPF válido.';

const defaultMaxOpenServices = 1;

const UserSearchApp = (): JSX.Element => {
  const { userSearched, userSearchedError } = useEventTracker();
  const [showResultList, setShowResultList] = useState(false);
  const [searchResult, setSearchResult] = useState<ResellerVO[]>();
  const [searchParams, setSearchParams] = useState<ValidateSearch>({} as ValidateSearch);
  const [searchFieldErrorType, setSearchFieldErrorType] = useState<SearchType>();
  const [ref, { width }] = useMeasure();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  const displayPlaceHolder = width < 263;
  const watchSearchField = watch(SEARCH_FIELD);
  const { error, resellers, isLoading, noResultsFound, isForbidden, refetch } = useSearchReseller(
    searchParams.value,
    searchParams.type,
  );
  const { myAttendances } = useGetMyAttendances();
  const { data: configData } = useGetConfigData();

  const hasError = isForbidden || noResultsFound || error;

  useEffect(() => {
    events.on('SELECT_RESELLER_IN_LIST', () => {
      reset({ searchField: '' });
    });

    return () => {
      events.off('SELECT_RESELLER_IN_LIST');
    };
  }, [reset, resetField]);

  const onSubmit = (data: FieldValues) => {
    const { type, value, isValid } = validateSearchType(data.searchField.trim());
    setSearchFieldErrorType(undefined);
    const isOnlyNumbers = /^\d+$/.test(value);
    const isInvalidEmail = /.*@.*/.test(value);
    if (isOnlyNumbers && value.length >= 10 && !isValid) {
      setSearchFieldErrorType(SearchType.CPF);
      return;
    }

    if (value && !isOnlyNumbers && isInvalidEmail && !isValid) {
      setSearchFieldErrorType(SearchType.EMAIL);
      return;
    }

    if (type === SearchType.UNKNOWN) {
      setSearchFieldErrorType(SearchType.UNKNOWN);
      return;
    }

    setSearchParams({
      value: value.toLowerCase(),
      type,
    });
  };

  const handleErrorMessage = useMemo(() => {
    if (searchFieldErrorType === SearchType.EMAIL) {
      return EMAIL_ERROR_INPUT_MESSAGE;
    }

    if (searchFieldErrorType === SearchType.CPF) {
      return CPF_ERROR_INPUT_MESSAGE;
    }

    return GENERIC_ERROR_INPUT_MESSAGE;
  }, [searchFieldErrorType]);

  useEffect(() => {
    let errorMessage = '';
    if (isForbidden) {
      errorMessage = ERROR_FORBIDDEN_MESSAGE;
    } else if (noResultsFound) {
      errorMessage = NOT_FOUND_MESSAGE;
    } else if (error) {
      errorMessage = ERROR_API_MESSAGE;
    }
    if (errorMessage.length > 0) {
      userSearchedError({ errorMessage: errorMessage });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForbidden, noResultsFound, error]);

  useEffect(() => {
    if (error) {
      Toaster.notify({
        kind: 'error',
        duration: 60000,
        button: 'Fechar',
        closeWhenClick: true,
        description: ERROR_TOAST_MESSAGE,
        origin: 'right-bottom',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!hasError && !!searchParams.value && !isLoading) {
      userSearched({ searchCode: searchParams.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.value, resellers, hasError]);

  useEffect(() => {
    setSearchResult(resellers);
  }, [resellers]);

  useEffect(() => {
    const searchType = searchParams?.type;
    const cleanupValue: string =
      searchType !== SearchType.EMAIL ? cleanupSpecialCharacters(watchSearchField) : watchSearchField;
    const value = searchType === SearchType.CPF ? cleanupValue.padStart(11, '0') : cleanupValue;
    setShowResultList(value?.toLowerCase() === searchParams.value?.toLowerCase() && !!searchParams.value);

    if (searchParams.value && searchParams.type) {
      refetch({ cancelRefetch: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Box
      css={{
        backgroundColor: '$light-dark-1',
        position: 'relative',
        width: '100%',
      }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        ref={ref}
        css={{ paddingBottom: searchFieldErrorType ? '$4' : 'unset' }}
      >
        <Input
          {...register(SEARCH_FIELD, { required: true })}
          rightIcon={<SearchIconButton />}
          id="searchField"
          role="textbox"
          optionalText=""
          labelText={displayPlaceHolder ? 'Buscar' : 'Buscar:'}
          placeholder={displayPlaceHolder ? '' : 'CPF, e-mail, código'}
          aria-labelledby="Buscar IR por CPF, e-mail ou código VD+"
          invalidText={error ? ERROR_API_MESSAGE : handleErrorMessage}
          invalid={!!searchFieldErrorType || !!error || !!errors.searchField}
        />
      </Box>

      {showResultList && !searchFieldErrorType && !error && !errors.searchField && (
        <SearchResultList
          resellers={searchResult}
          isLoading={isLoading}
          setShowResultList={setShowResultList}
          openServices={myAttendances.length}
          maxOpenServices={configData?.services?.maxOpenServices ?? defaultMaxOpenServices}
          noResultsFound={noResultsFound}
          isForbidden={isForbidden}
        />
      )}
    </Box>
  );
};

export { UserSearchApp };
