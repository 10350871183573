import { FranchiseContacts } from '@/types';
import { formatPhoneNumber } from '@/utils';
import { concat } from 'lodash';

const NO_PHONE_INFORMED = 'Nenhum telefone informado';
const NO_EMAIL_INFORMED = 'Nenhum e-mail informado';

export const generateContactsOnCopyText = (contacts: FranchiseContacts | undefined) => {
  if (!contacts) {
    return ['Telefones:', NO_PHONE_INFORMED, '', 'E-mail:', NO_EMAIL_INFORMED].join(`\r\n`);
  }

  const phoneText = concat(
    'Telefones:',
    contacts.phones.length > 0
      ? contacts.phones.map(phone => {
          const formattedPhone = formatPhoneNumber(phone.phone);
          return phone.whatsapp ? `${formattedPhone} (possui Whatsapp)` : formattedPhone;
        })
      : NO_PHONE_INFORMED,
  ).join('\r\n');

  const emailText = `E-mail:\r\n${contacts.email ?? NO_EMAIL_INFORMED}`;

  return `${phoneText}\r\n\r\n${emailText}`;
};
