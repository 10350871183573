import { api as prefixUrl } from '@/config';
// eslint-disable-next-line import/no-unresolved
import { authService } from '@vdi/auth-service';
import ky from 'ky';

export const instance = ky.create({
  prefixUrl,
  hooks: {
    beforeRequest: [
      async request => {
        const { token } = await authService.getTokenOrRedirect();
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});
