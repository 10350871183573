import { NOT_SPECIFIED, PhoneInfo, RequirementsList } from '@/components/reseller-details';

import { PaymentVoucherSpecification, SendPaymentVoucher } from '@/types';
import { RequirementsListButtonLabel } from '@/components/reseller-details/components/service-center-data/types';
import { TextInfo } from '@/components/shared';
import {
  paymentVoucherSpecificationValueLabelMap,
  sendPaymentVouchersValueLabelMap,
} from '@/components/reseller-details/labels';

interface PaymentVoucherViewProps {
  toSendData: SendPaymentVoucher[] | undefined;
  proofOfPaymentOptions: PaymentVoucherSpecification[] | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
}

const buttonLabel: RequirementsListButtonLabel = {
  showMore: 'Mostrar mais condições',
  showLess: 'Mostrar menos condições',
};

export const PaymentVoucherView = ({
  toSendData,
  proofOfPaymentOptions,
  phoneNumber,
  email,
}: PaymentVoucherViewProps) => {
  const proofOfPaymentOptionsList =
    proofOfPaymentOptions?.map(proofOfPayment => ({
      text: paymentVoucherSpecificationValueLabelMap[proofOfPayment],
    })) ?? [];

  const toSendDataList =
    toSendData
      ?.filter(sendData => sendData !== SendPaymentVoucher.OPERATIONAL_MANAGER)
      .map(sendData => ({
        text: sendPaymentVouchersValueLabelMap[sendData],
      })) ?? [];

  return (
    <>
      <RequirementsList
        title="Especificações dos dados para envio"
        listItems={proofOfPaymentOptionsList}
        buttonLabel={buttonLabel}
      />

      <RequirementsList title="Opções de envio do comprovante" listItems={toSendDataList} buttonLabel={buttonLabel} />

      <TextInfo title="E-mail" text={email} canCopyToClipboard fallbackText={NOT_SPECIFIED} />
      <PhoneInfo title="Telefone" phoneNumber={phoneNumber ?? NOT_SPECIFIED} canCopyToClipboard={!!phoneNumber} />
    </>
  );
};
