import { rest } from 'msw';
import { fakeSearchServiceList, fakeServiceList } from '@/mocks/data';
import { apiPath } from '@/mocks/utils/apiPath';
import { delayedResponse } from '@/mocks/utils/delayedResponse';

import { Service, ServiceStatus } from '@/types';

const serviceListMock = [...fakeServiceList];
let serviceList = fakeSearchServiceList;

const getService = rest.get(apiPath('v1/attendance/:id'), (req, _res, ctx) => {
  const serviceId = req.params?.id;

  const foundService = serviceList.find(service => service.attendanceId === serviceId);

  if (!foundService) {
    return delayedResponse(ctx.status(404));
  }

  return delayedResponse(ctx.status(200), ctx.json(foundService));
});

const getMyAttendances = rest.get(apiPath('v1/services'), (_req, _res, ctx) => {
  return delayedResponse(ctx.status(200), ctx.json(serviceList));
});

const addService = rest.post(apiPath('v1/services'), async (req, _res, ctx) => {
  const { resellerId } = await req.json();

  const foundService: Service | undefined = serviceListMock.find(service => service.reseller.geraId === resellerId);

  const newService = {
    ...foundService,
    serviceStatus: ServiceStatus.OPEN,
    _id: foundService?._id,
    reseller: foundService?.reseller.geraId,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceList = [newService as any, ...serviceList];

  return delayedResponse(ctx.status(200), ctx.json(newService));
});

const removeAttendancesFromList = rest.delete(apiPath('v1/service'), (req, _res, ctx) => {
  const id = req.url.searchParams.get('id');
  if (id === fakeSearchServiceList[1].attendanceId) {
    return delayedResponse(ctx.status(500), ctx.json({}));
  }

  serviceList = serviceList.filter(user => user.attendanceId !== id);
  return delayedResponse(ctx.status(200));
});

const startCustomerService = rest.post(apiPath('v1/services/:serviceId/start'), async (req, _res, ctx) => {
  const body = await req.json();

  return delayedResponse(ctx.status(200), ctx.json({ body }));
});

export const attendances = [getService, getMyAttendances, addService, removeAttendancesFromList, startCustomerService];
