import { events } from '@/api';
import { UpdateResellerData } from '@/types';
import { useEffect, useState } from 'react';

export const useEditResellerFormState = () => {
  const [editResellerFormState, setEditResellerFormState] = useState<UpdateResellerData>({
    isSubmitting: false,
    hasError: false,
  });

  const [hasFormChanged, setHasFormChanged] = useState(false);

  useEffect(() => {
    events.on('UPDATE_RESELLER_DATA', load => setEditResellerFormState(load));
    events.on('HAS_CHANGES_ON_FORM', hasChanges => setHasFormChanged(hasChanges));
    return () => {
      events.off('UPDATE_RESELLER_DATA');
      events.off('HAS_CHANGES_ON_FORM');
    };
  }, []);

  return {
    editResellerFormState,
    hasFormChanged,
  };
};
