import { PaymentLocationInfo, SendPaymentVoucher, PaymentVoucherSpecification, PixKey, AccountType } from '@/types';

export const paymentLocationValueLabelMap: {
  [key in PaymentLocationInfo]: string;
} = Object.freeze({
  [PaymentLocationInfo.IN_PERSON]: 'Pagamento presencial',
  [PaymentLocationInfo.ON_DELIVERY]: 'Pagamento na entrega',
});

export const sendPaymentVouchersValueLabelMap: {
  [key in SendPaymentVoucher]: string;
} = Object.freeze({
  [SendPaymentVoucher.OPERATIONAL_MANAGER]: 'Enviar dados completos para líder operacional',
  [SendPaymentVoucher.WHATSAPP]: 'Enviar por Whatsapp',
  [SendPaymentVoucher.EMAIL]: 'Enviar por e-mail',
});

export const paymentVoucherSpecificationValueLabelMap: {
  [key in PaymentVoucherSpecification]: string;
} = Object.freeze({
  [PaymentVoucherSpecification.INCLUDE_ORDER_INFO]: 'Incluir dados do pedido',
  [PaymentVoucherSpecification.INCLUDE_RESELLER_INFO]: 'Incluir dados da IR',
});

export const pixKeyTypeValueLabelMap: {
  [key in PixKey]: string;
} = Object.freeze({
  [PixKey.CPF_CNPJ]: 'CPF ou CNPJ',
  [PixKey.PHONE_NUMBER]: 'Celular',
  [PixKey.EMAIL]: 'E-mail',
  [PixKey.RANDOM]: 'Aleatória',
});

export const accountTypeValueLabelMap: {
  [key in AccountType]: string;
} = Object.freeze({
  [AccountType.CURRENT_ACCOUNT]: 'Corrente',
  [AccountType.SAVINGS_ACCOUNT]: 'Poupança',
});
