import React, { ReactNode, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useGetResellerCredit,
  useGetResellerDocumentStatus,
  useGetResellerRegistry,
  useGetResellerRisk,
  useGetService,
} from '@/api';
import {
  ResellerCreditResponse,
  ResellerDocumentStatusResponse,
  ResellerRegistryResponse,
  ResellerRiskAnalysisResponse,
  ServiceRouteParamsType,
} from '@/types';
import { useGTMTrackerStore } from '@/store';
import { useShallow } from 'zustand/react/shallow';

export interface ResellerDetailsValue {
  resellerRegistry: ResellerRegistryResponse;
  resellerCredit: ResellerCreditResponse;
  riskAnalysisData: ResellerRiskAnalysisResponse;
  resellerDocumentStatus: ResellerDocumentStatusResponse;
  csId?: string;
}

interface ResellerDetailsProviderProps {
  children: ReactNode;
}

const ResellerDetailsContext = React.createContext<ResellerDetailsValue | undefined>(undefined);

const ResellerDetailsProvider = ({ children }: ResellerDetailsProviderProps) => {
  const { serviceId } = useParams<ServiceRouteParamsType>();
  const { service } = useGetService(serviceId);

  const geraId = service?.reseller.geraId;
  const assistantID = service?.users[0].userId;
  const { setIntentionID } = useGTMTrackerStore(useShallow(state => state));

  useEffect(() => {
    setIntentionID(geraId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geraId, assistantID]);

  const resellerRegistry = useGetResellerRegistry(geraId);
  const resellerCredit = useGetResellerCredit(geraId);
  const riskAnalysisData = useGetResellerRisk(resellerRegistry.data?.cpf);
  const resellerDocumentStatus = useGetResellerDocumentStatus(geraId);
  const csId = (resellerRegistry.data?.commercialStructure?.parentStructure.name ?? '')
    .split(' ')
    .find(str => /^\d+$/.test(str));

  const contextValue = useMemo(
    () => ({
      resellerRegistry,
      resellerCredit,
      riskAnalysisData,
      resellerDocumentStatus,
      csId,
    }),
    [resellerRegistry, csId, resellerCredit, riskAnalysisData, resellerDocumentStatus],
  );

  return (
    <ResellerDetailsContext.Provider value={contextValue} key={geraId}>
      {children}
    </ResellerDetailsContext.Provider>
  );
};

const useResellerDetails = (): ResellerDetailsValue => {
  const context = React.useContext(ResellerDetailsContext);

  if (context === undefined) {
    throw new Error('useResellerDetails deve ser utilizado dentro de um ResellerDetailsContext');
  }

  return context;
};

export { ResellerDetailsContext, ResellerDetailsProvider, useResellerDetails };
