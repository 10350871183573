import { ResellerDocumentEnum, ResellerDocumentStatusEnum } from '@/types';

export const DocumentValueLabelMap: {
  [key in ResellerDocumentEnum]: string;
} = Object.freeze({
  [ResellerDocumentEnum.ADDRESS_PROOF]: 'comprovante de residência',
  [ResellerDocumentEnum.CPF]: 'CPF',
  [ResellerDocumentEnum.RG]: 'RG',
});

export const DocumentStatusValueLabelMap: {
  [key in ResellerDocumentStatusEnum]: string;
} = Object.freeze({
  [ResellerDocumentStatusEnum.CHECKED]: 'validado',
  [ResellerDocumentStatusEnum.NOT_CHECKED]: 'não validado',
  [ResellerDocumentStatusEnum.PENDING]: 'pendente',
  [ResellerDocumentStatusEnum.NOT_DEFINED]: 'não validado',
});
