import { useRequest } from '@/api/hooks';
import { ResellerDocumentStatusResponse, ResellerDocuments } from '@/types';

export function useGetResellerDocumentStatus(geraId: string): ResellerDocumentStatusResponse {
  const { data, error } = useRequest<ResellerDocuments>(
    geraId ? ['resellers/documents', geraId] : null,
    `resellers/${geraId}/documents`,
  );
  return {
    isLoading: !data && !error,
    data,
    error,
  };
}
