import { useRequest } from '@/api';
import { Service, myAttendancesKey } from '@/types';

export function useGetMyAttendances(sort?: 1 | -1): {
  isLoading: boolean;
  myAttendances: Service[];
  error?: Error;
} {
  const { data, error } = useRequest<Service[]>(myAttendancesKey, 'services', {
    searchParams: {
      sort: sort ?? 1,
    },
  });

  return {
    isLoading: !data && !error,
    myAttendances: data ?? [],
    error,
  };
}
