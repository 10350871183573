import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloraProvider } from '@grupoboticario/flora-react-flora-provider';
import { ConfigCatProvider } from 'configcat-react';
import { configcatSdkKey } from './config';
import { FeatureFlagProvider } from './contexts';
import { App } from './App';
import { DrawerInfo } from './components';
import { useLayoutEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export default function Root() {
  if (process.env.MOCK) {
    import('@/mocks/browser');
  }

  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
    :root {
      --flora--colors-brand-1: #626CC3;
      --flora--colors-brand-2: #7A8CCB;
    }
    `;
    document.head.appendChild(style);
  }, []);

  return (
    <ConfigCatProvider sdkKey={configcatSdkKey}>
      <QueryClientProvider client={queryClient}>
        <FloraProvider>
          <FeatureFlagProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/inicios/atendimento/:serviceId?" element={<App />} />
              </Routes>
            </BrowserRouter>
            <DrawerInfo />
          </FeatureFlagProvider>
        </FloraProvider>
      </QueryClientProvider>
    </ConfigCatProvider>
  );
}
