import { TextOrHeading } from '@/components';
import { Box, Flex, Image, styled, CSS, TextProps } from '@grupoboticario/flora-react';

const DEFAULT_AVATAR_SIZE = '35px';
const DEFAULT_TEXT_SIZE = 'bodyMediumStandard';

export type VariantType = 'default' | 'alt' | 'ghost' | 'success' | 'alert' | 'error';
type TextVariantType = 'text' | 'heading';

type TextPropsType = Pick<TextProps, 'size'>;
interface AvatarProps {
  css?: CSS;
  imagePath?: string;
  name: string;
  size?: number | string;
  variant?: VariantType;
  textSize?: TextPropsType['size'];
  textVariant?: TextVariantType;
}

const getUserNameInitials = (userName: string): string => {
  if (!userName) return '--';

  const nameArray = userName.trim().split(' ');

  if (nameArray.length <= 1) {
    return userName.substring(0, 2).toUpperCase();
  }

  return `${nameArray.shift()?.charAt(0)}${nameArray.pop()?.charAt(0)}`.trim().toUpperCase();
};

const colorDefault = 'rgba(255, 255, 255, 0.9)';
const StyledAvatar = styled(Flex, {
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  borderRadius: '100%',
  variants: {
    variant: {
      default: {
        backgroundColor: '$brand-2',
        color: '$light-light',
      },
      alt: {
        backgroundColor: '$light-light',
        color: '$brand-2',
        border: '1px solid $brand-2',
      },
      ghost: {
        backgroundColor: '$nonInteractiveAltAuxiliar',
        color: '$light-light',
      },
      success: {
        backgroundColor: '$success-standard',
        color: colorDefault,
      },
      alert: {
        backgroundColor: '$alert-standard',
        color: colorDefault,
      },
      error: {
        backgroundColor: '$error-standard',
        color: colorDefault,
      },
    },
  },

  defaultVariants: {
    variant: 'default',
  },
});

const Avatar = ({
  css,
  imagePath,
  name,
  size = DEFAULT_AVATAR_SIZE,
  variant,
  textSize = DEFAULT_TEXT_SIZE,
  textVariant = 'text',
}: AvatarProps) => {
  const nameInitials = getUserNameInitials(name);

  return (
    <Box
      css={{
        height: size,
        width: size,
        ...css,
      }}
    >
      {imagePath ? (
        <Box
          as="figure"
          css={{
            borderRadius: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Image css={{ width: '105%' }} src={imagePath} alt={name} role="img" />
        </Box>
      ) : (
        <Box
          css={{
            height: '100%',
            textTransform: 'uppercase',
            overflow: 'hidden',
          }}
        >
          <StyledAvatar variant={variant}>
            <TextOrHeading headingLevel={6} text={nameInitials} textSize={textSize} variant={textVariant} />
          </StyledAvatar>
        </Box>
      )}
    </Box>
  );
};

export { Avatar };
