import {
  BankSlipPaymentView,
  CreditCardPaymentView,
  DebitCardPaymentView,
  DepositAndTransferPaymentView,
  OnlinePaymentView,
  PixPaymentView,
  CashPaymentView,
} from '@/components/reseller-details';
import { useResellerServiceCenter } from '@/contexts';
import { Flex } from '@grupoboticario/flora-react';

const ServiceCenterPaymentMethods = (): JSX.Element | null => {
  const { csData } = useResellerServiceCenter();

  if (csData?.paymentMethods === undefined) {
    return null;
  }

  const {
    bankSlipPayment,
    cashPayment,
    creditCardPayment,
    debitCardPayment,
    depositAndTransferPayment,
    onlineCardPayment,
    pixPayment,
  } = csData.paymentMethods;

  return (
    <Flex direction="column" gap="$6" data-testid="payment-methods-tab">
      {bankSlipPayment.isEnabled && <BankSlipPaymentView bankSlipPaymentData={bankSlipPayment} />}
      {creditCardPayment.isEnabled && <CreditCardPaymentView creditCardPaymentData={creditCardPayment} />}
      {debitCardPayment.isEnabled && <DebitCardPaymentView debitCardPaymentData={debitCardPayment} />}
      {pixPayment.isEnabled && <PixPaymentView pixPaymentData={pixPayment} />}
      {depositAndTransferPayment.isEnabled && (
        <DepositAndTransferPaymentView depositAndTransferPaymentData={depositAndTransferPayment} />
      )}
      {cashPayment.isEnabled && <CashPaymentView cashPaymentData={cashPayment} />}
      {onlineCardPayment.isEnabled && <OnlinePaymentView onlinePaymentViewData={onlineCardPayment} />}
    </Flex>
  );
};

export { ServiceCenterPaymentMethods };
