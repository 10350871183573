import { Toaster } from '@grupoboticario/flora-react';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addNewOpenService, events, useGetMyAttendances } from '@/api';
import { ResellerVO, Service, ServiceRouteParamsType } from '@/types';

enum Order {
  ASC = 1,
  DESC = -1,
}

const ERROR_API_MESSAGE = 'Falha ao processar a solicitação, por favor, tente novamente.';

const toastConfig = {
  closeWhenClick: true,
  button: 'Fechar',
  duration: 60000,
  description: ERROR_API_MESSAGE,
};

interface UserListValue {
  hasUsers: boolean;
  isCollapsed: boolean;
  isLoading: boolean;
  selectedUserId?: string;
  users: Service[];
  setSelectedUserId: (userId: string) => void;
  toggleColumn: () => void;
}

interface UserListProviderProps {
  isCollapsed: boolean;
  children: ReactNode;
  toggleColumn: () => void;
}

const UserListContext = React.createContext<UserListValue | undefined>(undefined);

const UserListProvider = ({ children, isCollapsed, toggleColumn }: UserListProviderProps) => {
  const [selectedServiceId, setSelectedServiceId] = useState<string | undefined>(undefined);
  const { myAttendances, isLoading } = useGetMyAttendances(Order.DESC);
  const { serviceId } = useParams<ServiceRouteParamsType>();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (
      serviceId &&
      myAttendances.some(attendance => {
        const attendanceId = attendance._id;
        return attendanceId === serviceId;
      })
    ) {
      setSelectedServiceId(serviceId);
    } else {
      navigate(`/inicios/atendimento`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, isLoading]);

  useEffect(() => {
    const addOpenServiceToList = async (reseller: ResellerVO) => {
      const { addedService, error } = await addNewOpenService(reseller);

      if (error) {
        return Toaster.notify({
          kind: 'error',
          origin: 'right-bottom',
          ...toastConfig,
        });
      }
      const addedServiceId = (addedService as unknown as Service)._id;

      if (addedServiceId) {
        navigate(`/inicios/atendimento/${addedServiceId}`);
        events.emit('SELECT_RESELLER_IN_LIST', addedServiceId);
        setSelectedServiceId(addedServiceId);
      }

      return addedService;
    };

    events.on('ADD_RESELLER_TO_USER_LIST', reseller => {
      addOpenServiceToList(reseller);
    });

    return () => {
      events.off('ADD_RESELLER_TO_USER_LIST');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId, navigate]);

  const contextValue = useMemo(
    () => ({
      hasUsers: myAttendances.length > 0,
      isCollapsed,
      isLoading,
      selectedUserId: selectedServiceId,
      users: myAttendances,
      setSelectedUserId: setSelectedServiceId,
      toggleColumn,
    }),
    [isCollapsed, isLoading, selectedServiceId, myAttendances, setSelectedServiceId, toggleColumn],
  );

  return <UserListContext.Provider value={contextValue}>{children}</UserListContext.Provider>;
};

const useUserList = (): UserListValue => {
  const context = React.useContext(UserListContext);

  if (context === undefined) {
    throw new Error('useUserList deve ser utilizado dentro de um UserListContext');
  }

  return context;
};

export { UserListContext, UserListProvider, useUserList };
