interface Config {
  api: string;
  configcatSdkKey: string;
}

const config: Config = process.env.MOCK
  ? {
      api: 'https://api.example.com/',
      configcatSdkKey: 'aaaaaaaaaaaaaaaaaaaaaa/aaaaaaaaaaaaaaaaaaaaaa',
    }
  : {
      api: process.env.BFF_BASE_URL,
      configcatSdkKey: process.env.CONFIGCAT_SDK_KEY,
    };

export const { api, configcatSdkKey } = config;
