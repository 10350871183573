import { ConfigData } from '@/types';

export const configDataMock: ConfigData = {
  services: {
    maxOpenServices: 50,
    tabFormSettings: [
      {
        _id: 'test-id-1',
        formFieldType: 'text',
        isRequired: true,
        labelText: 'Text field',
        optionalText: '(opcional)',
        name: 'text-name',
        errorMessage: 'Validation error for text field',
      },
      {
        _id: 'test-id-2',
        formFieldType: 'textArea',
        isRequired: false,
        labelText: 'Text area field',
        name: 'textarea-name',
        errorMessage: 'Validation error for text area field',
      },
      {
        _id: 'test-id-3',
        formFieldType: 'checkbox',
        isRequired: true,
        labelText: 'Checkbox field',
        name: 'checkbox-name',
        errorMessage: 'Validation error for checkbox field',
        fieldOptions: [
          { label: 'Checkbox field 1', value: 'cb-1' },
          { label: 'Checkbox field 2', value: 'cb-2' },
        ],
      },
      {
        _id: 'test-id-4',
        formFieldType: 'select',
        isRequired: true,
        labelText: 'Reasons',
        name: 'select-name',
        optionalText: 'Selecione uma opção da lista',
        errorMessage: 'Validation error for select field',
        fieldOptions: [
          { label: 'Select field 1', value: 'select-1' },
          { label: 'Select field 2', value: 'select-2' },
        ],
      },
      {
        _id: 'test-id-5',
        formFieldType: 'select',
        isRequired: false,
        labelText: 'SubReasons',
        name: 'select-subreason',
        optionalText: 'Selecione uma opção da lista',
        errorMessage: 'Validation error for select field',
        fieldOptions: [
          { label: 'Select field 1', value: 'select-3' },
          { label: 'Select field 2', value: 'select-4' },
        ],
      },
      {
        _id: 'test-id-6',
        formFieldType: 'radio',
        isRequired: true,
        labelText: 'Radio field',
        name: 'radio-name',
        errorMessage: 'Validation error for radio field',
        fieldOptions: [
          { label: 'Radio field 1', value: 'radio-a' },
          { label: 'Radio field 2', value: 'radio-b' },
        ],
      },

      {
        _id: 'test-id-7',
        formFieldType: 'radio',
        isRequired: false,
        labelText: 'Radio field3',
        name: 'radio-name3',
        errorMessage: 'Validation error for radio field',
        fieldOptions: [
          { label: 'Radio field 3', value: 'radio-1' },
          { label: 'Radio field 4', value: 'radio-2' },
        ],
      },
      {
        _id: 'test-id-8',
        formFieldType: 'radio',
        isRequired: false,
        labelText: 'Radio field 2',
        name: 'radio-name2',
        errorMessage: 'Validation error for radio field',
        fieldOptions: [
          { label: 'Radio field 5', value: 'radio-1' },
          { label: 'Radio field 6', value: 'radio-2' },
        ],
      },
    ],
  },
};
