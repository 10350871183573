import { InfoWrapper, Detail } from '@/components/shared';
import { formatPhoneNumber } from '@/utils';
import { Flex, Text } from '@grupoboticario/flora-react';
import { SocialWhatsappIcon } from '@grupoboticario/flora-react-icons';

interface PhoneInfoProps {
  title: string;
  phoneNumber: string;
  isWhatsapp?: boolean;
  canCopyToClipboard?: boolean;
  isFullWidth?: boolean;
}

const PhoneInfo = ({
  title,
  phoneNumber,
  isWhatsapp,
  canCopyToClipboard,
  isFullWidth,
}: PhoneInfoProps): JSX.Element => (
  <InfoWrapper isFullWidth={isFullWidth}>
    <Flex align="center" justify="space-between">
      <Detail canCopyToClipboard={canCopyToClipboard} text={formatPhoneNumber(phoneNumber)} title={title} />
      {isWhatsapp && (
        <Flex css={{ color: '$nonInteractiveAuxiliar', paddingTop: '$3' }}>
          <SocialWhatsappIcon size={16} />
          <Text size="auxiliarMini" color="$nonInteractiveAuxiliar" css={{ paddingLeft: '$2' }}>
            Whatsapp ativo
          </Text>
        </Flex>
      )}
    </Flex>
  </InfoWrapper>
);

export { PhoneInfo };
