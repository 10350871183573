import { useGTMTrackerStore } from '@/store';
// eslint-disable-next-line import/no-unresolved
import { authService } from '@vdi/auth-service';
import { useShallow } from 'zustand/react/shallow';

export function useAccountInfo() {
  const { setAssistantID } = useGTMTrackerStore(useShallow(state => state));
  setAssistantID(authService.userData.geraId);

  return {
    user: {
      ...authService.userData,
    },
  };
}
