import { DataWrapper, NoteField, TextInfo } from '@/components';
import { AllowedCategories, InstructionBox, ServiceCenterProductList } from '@/components/reseller-details';
import { productCategoryValueLabelMap } from '@/components/reseller-details/labels';
import { useResellerServiceCenter } from '@/contexts';
import { formatCurrency } from '@/utils';
import { CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import React from 'react';

interface InstructionProductIncrement {
  title: string;
  icon?: React.ReactElement;
  tagTitle?: string;
}

export const defineInstructionsForProductIncrement = (
  allowIncrement = false,
  hasRequiredQuantity = false,
): InstructionProductIncrement => {
  return {
    title: allowIncrement
      ? 'Incluir produtos na primeira compra.'
      : 'Não permite adicionar produtos na primeira compra.',
    icon: allowIncrement ? (
      <CheckCircleIcon css={{ color: '$8' }} size="32" />
    ) : (
      <CrossCircleIcon css={{ color: '$alert-standard' }} size="32" />
    ),
    tagTitle: hasRequiredQuantity ? 'Obrigatório' : undefined,
  };
};

const ServiceCenterOrderIncrement = () => {
  const { csData } = useResellerServiceCenter();

  const instructionsAttribute = defineInstructionsForProductIncrement(
    csData?.orderIncrement?.allowIncrement,
    csData?.orderIncrement?.hasRequiredQuantity,
  );

  const formattedMinimunValue = formatCurrency(Number(csData?.orderIncrement?.minimumValue));

  const formattedMaximumValue = formatCurrency(Number(csData?.orderIncrement?.maximumValue));

  const products = csData?.orderIncrement?.specificProducts ?? [];
  return (
    <DataWrapper title="Incremento de pedido">
      <InstructionBox
        title={instructionsAttribute.title}
        icon={instructionsAttribute.icon}
        tagTitle={instructionsAttribute.tagTitle}
        isFullWidth
      />
      <TextInfo title="Valor mínimo" text={formattedMinimunValue ?? 'Não especificado'} />
      <TextInfo title="Valor máximo" text={formattedMaximumValue ?? 'Não especificado'} />
      {csData?.orderIncrement?.hasSpecificProducts ? (
        <ServiceCenterProductList products={products} />
      ) : (
        <TextInfo isFullWidth title="Produtos permitidos" text="Não especificado" />
      )}
      <AllowedCategories
        tagsValue={
          csData?.orderIncrement?.hasCategories
            ? (csData.orderIncrement.categories ?? []).map(category => productCategoryValueLabelMap[category])
            : undefined
        }
      />
      {csData?.orderIncrement?.note && <NoteField text={csData?.orderIncrement?.note} />}
    </DataWrapper>
  );
};

export { ServiceCenterOrderIncrement };
