import { useRequest } from '@/api';
import { FormFieldType } from '@/types';
import { HTTPError } from 'ky';

const configKey = 'CONFIG';

interface ConfigData {
  services: {
    maxOpenServices: number;
    tabFormSettings: FormFieldType[];
  };
}
interface ConfigDataResponse {
  error?: HTTPError;
  isLoading: boolean;
  data?: ConfigData;
}

export function useGetConfigData(): ConfigDataResponse {
  const { data, error } = useRequest<ConfigData>(configKey, 'admin');

  return {
    isLoading: !data && !error,
    data,
    error,
  };
}
