import { Flex, ScrollArea, TabPanel } from '@grupoboticario/flora-react';
import { ResellerTab } from '@/types';

interface ResellerTabPanelProps {
  tabList: ResellerTab[];
}

const ResellerTabPanel = ({ tabList }: ResellerTabPanelProps): JSX.Element => (
  <Flex direction="column" css={{ overflow: 'hidden', height: '100%' }}>
    <ScrollArea css={{ height: '100%', '> div > div': { height: '100%' } }} orientation="vertical">
      {tabList.length &&
        tabList.map(panel => (
          <TabPanel css={{ padding: '0', marginRight: '$4', height: '100%' }} value={panel.value} key={panel.value}>
            {panel.component}
          </TabPanel>
        ))}
    </ScrollArea>
  </Flex>
);

export { ResellerTabPanel };
