import { Tabs } from '@grupoboticario/flora-react';
import { ResellerTab } from '@/types';
import { ResellerTabList, ResellerTabPanel } from '@/components/reseller-details';

interface ResellerTabsProps {
  tabDefaultValue: string;
  tabList: ResellerTab[];
}

const ResellerTabs = ({ tabDefaultValue, tabList }: ResellerTabsProps): JSX.Element => (
  <Tabs defaultValue={tabDefaultValue} css={{ height: '100%', minHeight: 0, marginBottom: '$4' }}>
    <ResellerTabList tabList={tabList} />
    <ResellerTabPanel tabList={tabList} />
  </Tabs>
);

export { ResellerTabs };
