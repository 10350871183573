import { Button, Flex, Text } from '@grupoboticario/flora-react';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';

interface ErrorMessageProps {
  title: string;
  subTitle: string;
  onRetry?: () => void;
}

const ErrorMessage = ({ title, subTitle, onRetry }: ErrorMessageProps): JSX.Element => {
  return (
    <Flex
      id="error-message-container"
      align="center"
      justify="center"
      direction="column"
      gap="$9"
      css={{
        margin: '0 $4',
        borderRadius: '$medium',
        backgroundColor: '$light-light',
        height: '98%',
      }}
    >
      <CrossCircleIcon size="2.91rem" css={{ marginBotton: '$9' }} color="$error-standard" />
      <Flex justify="center" direction="column" gap="$2">
        <Text
          css={{
            fontWeight: '$medium',
            marginLeft: '$9',
            marginRight: '$9',
          }}
        >
          {title}
        </Text>
        <Flex id="cta-button" align="stretch" direction="column" gap="$8">
          <Text
            size="bodyMediumShort"
            css={{
              fontWeight: '$medium',
              textAlign: 'center',
            }}
            color="$nonInteractiveAuxiliar"
          >
            {subTitle}
          </Text>
          {onRetry && (
            <Button
              styleSemantic="conversion"
              onClick={onRetry}
              isFull
              css={{
                fontWeight: '$medium',
                padding: '$4 $2',
              }}
            >
              Tentar novamente
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export { ErrorMessage };
