import { fetcher } from '@/api';
import { ResellerRegistry } from '@/types';

export type UpdateResellerNamesParamsType = Pick<ResellerRegistry, 'name' | 'socialName'>;

export async function updateResellerNames(geraId: string, req: UpdateResellerNamesParamsType) {
  return fetcher.patch(`resellers/${geraId}/dadospessoais`, {
    json: { ...req },
  });
}
