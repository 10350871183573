import { instance } from '@/api';

type MethodType = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';

export interface FetcherOptions {
  timeout?: number;
  method?: MethodType;
  body?: FormData | ReadableStream;
  searchParams?: {
    [key: string | number]: string | number | boolean;
  };
  json?: unknown;
  headers?: {
    [key: string]: string;
  };
  apiVersion?: number;
}

function fetcherFunction(url: string, method: MethodType, options?: FetcherOptions) {
  return instance(options?.apiVersion ? `v${options.apiVersion}/${url}` : `v1/${url}`, { ...options, method });
}

const fetcherGet = async <T>(url: string, options?: FetcherOptions) => fetcherFunction(url, 'GET', options).json<T>();
const fetcherPost = async (url: string, options?: FetcherOptions) => fetcherFunction(url, 'POST', options);
const fetcherPut = async (url: string, options?: FetcherOptions) => fetcherFunction(url, 'PUT', options);
const fetcherDelete = async (url: string, options?: FetcherOptions) => fetcherFunction(url, 'DELETE', options);
const fetcherPatch = async (url: string, options?: FetcherOptions) => fetcherFunction(url, 'PATCH', options);

const fetcher = {
  delete: fetcherDelete,
  get: fetcherGet,
  patch: fetcherPatch,
  post: fetcherPost,
  put: fetcherPut,
};

export { fetcher };
