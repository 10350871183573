import { formatCurrency } from '@/utils';

interface InstructionBoxProps {
  higherRangeValue?: number;
  smallerRangeValue?: number;
  hasFreeDeliveryOnSpecialCondition?: boolean;
}

export const generateSpecialConditionTitle = ({
  higherRangeValue,
  smallerRangeValue,
  hasFreeDeliveryOnSpecialCondition,
}: InstructionBoxProps): string => {
  const lowerValue = formatCurrency(smallerRangeValue);
  const biggerValue = formatCurrency(higherRangeValue);
  const condition = hasFreeDeliveryOnSpecialCondition ? 'gratuito' : 'especial';

  if (higherRangeValue) {
    return `Permite frete especial para compras entre ${lowerValue} e ${biggerValue}`;
  }

  return `Permite frete ${condition} a partir de ${lowerValue}`;
};
