import { Avatar, Detail, InfoWrapper } from '@/components/shared';
import { Grid } from '@grupoboticario/flora-react';

interface ResellerNameInfoProps {
  name: string;
}

const ResellerNameInfo = ({ name }: ResellerNameInfoProps): JSX.Element => (
  <InfoWrapper isFullWidth>
    <Grid gapX="$4" templateColumns="$12 auto" align="center">
      <Avatar name={name} size="$12" textVariant="heading" variant="ghost" />
      <Detail
        helpTooltip="Como a pessoa deseja ser chamada."
        text={name}
        title="Nome Social"
        variant="heading"
        isCapitalized
      />
    </Grid>
  </InfoWrapper>
);

export { ResellerNameInfo };
