import { ResellerOrigin } from '@/types';

export const resellerOriginValueLabelMap: {
  [key in ResellerOrigin]: string;
} = Object.freeze({
  [ResellerOrigin.SALES_FORCE]: 'Força de Vendas',
  [ResellerOrigin.SPONTANEOUS]: 'Espontâneo',
  [ResellerOrigin.VIRTUAL_STORE]: 'Loja Virtual',
  [ResellerOrigin.VIRTUAL_STORE_ORDER]: 'Pedido Loja Virtual',
  [ResellerOrigin.LEGACY_IMPORTED]: 'Importado do Legado',
  [ResellerOrigin.LOAD_IMPORTED]: 'Importado Via Carga',
  [ResellerOrigin.SITE]: 'Site',
  [ResellerOrigin.SEND_SCREEN]: 'Tela de Envio',
  [ResellerOrigin.RESELLER_REGISTER]: 'Cadastro de Intenção de Revenda',
  [ResellerOrigin.REGISTER_REQUEST]: 'Solicitação de Cadastro',
  [ResellerOrigin.SUPERVISOR_APP]: 'APP da supervisora',
});
