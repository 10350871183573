import { Flex, Grid, Text } from '@grupoboticario/flora-react';
import { AttendanceInfoData } from '../types';
import { TextInfo } from '@/components';

interface AttendanceInfoProps {
  attendanceInfo: AttendanceInfoData;
  isEditingName?: boolean;
}

const AttendanceInfo = ({ attendanceInfo, isEditingName }: AttendanceInfoProps) => {
  const attendanceInfoText = [
    {
      title: 'Nome Social',
      text: attendanceInfo.socialName,
    },
    {
      title: 'Código',
      text: attendanceInfo.geraId,
    },
    {
      title: 'CP / CS',
      text: attendanceInfo.cpCs,
    },
  ];

  if (isEditingName) {
    attendanceInfoText.shift();
  }

  return (
    <Flex data-testid="attendance-info" direction="column" gapY="$2">
      <Text as="h3" size="bodyMediumShort" css={{ paddingLeft: '$3' }}>
        Consulta Aberta
      </Text>

      <Grid
        gap="0.125rem"
        templateColumns={`repeat(${isEditingName ? 2 : 3}, 1fr)`}
        css={{
          borderRadius: '$medium',
          background: '$light-dark-1',
          width: '100%',
        }}
      >
        {attendanceInfoText.map(attendance => (
          <TextInfo
            key={`attendanceInfo__${attendance.title}`}
            title={attendance.title}
            text={attendance.text}
            isCapitalized
            wrapperCss={{
              background: 'unset',
              padding: '$4',
            }}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export { AttendanceInfo };
