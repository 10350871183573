import { DataWrapper, NoteField } from '@/components/shared';
import { DebitCardPayment } from '@/types';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';
import {
  PaymentLocationGeneralConditions,
  generalPaymentConditionsClipboardText,
  notesClipBoardText,
  useCopyDataWrapperInfo,
} from '@/components/reseller-details';

interface DebitCardPaymentViewProps {
  debitCardPaymentData: DebitCardPayment;
}

const DebitCardPaymentView = ({ debitCardPaymentData }: DebitCardPaymentViewProps): JSX.Element => {
  const { note, paymentLocations } = debitCardPaymentData;

  const { onCopy } = useCopyDataWrapperInfo({
    text: [
      `${generalPaymentConditionsClipboardText(paymentLocations, 'débito')}`,
      '',
      `${notesClipBoardText(note)}`,
    ].join(`\r\n`),
    successMessage: 'Lista de condições copiada',
    section: 'condições',
  });

  return (
    <DataWrapper
      title="Cartão de débito"
      data-testid="debit-card-payment"
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar Condições',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      <PaymentLocationGeneralConditions conditions={paymentLocations} />
      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { DebitCardPaymentView };
