import { useEditResellerFormState } from '@/components/reseller-details/hooks';
import { LoadButton } from '@/components/shared';
import { Checkbox, DrawerFooter, Flex, Text } from '@grupoboticario/flora-react';
import { useState } from 'react';

export const EditResellerDrawerFooter = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { editResellerFormState, hasFormChanged } = useEditResellerFormState();

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <DrawerFooter css={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Flex align="center" gap="$2">
        <Checkbox id="edit-address-checkbox" checked={isChecked} onCheckedChange={handleCheckbox} />
        <Text as="label" css={{ fontWeight: '$light' }} size="bodyMediumShort" onClick={handleCheckbox}>
          Confirmo que os dados alterados foram revisados e estão corretos.
        </Text>
      </Flex>

      <LoadButton
        isLoading={!editResellerFormState.hasError && editResellerFormState.isSubmitting}
        disabled={!isChecked || !hasFormChanged}
        type="submit"
        form="reseller-form"
        styleSemantic={!editResellerFormState.hasError ? 'neutral' : 'warning'}
        label={!editResellerFormState.hasError ? 'Salvar alterações' : 'Tentar Novamente'}
      />
    </DrawerFooter>
  );
};
