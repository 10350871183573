import { Grid, Flex } from '@grupoboticario/flora-react';
import {
  UserSearchApp,
  CloseServiceModal,
  UserListApp,
  ServiceControl,
  ResellerDetails,
  ResellerEmptyState,
} from './components';
import { useParams } from 'react-router-dom';
import { ServiceRouteParamsType } from './types';

export const App = () => {
  const { serviceId } = useParams<ServiceRouteParamsType>();

  return (
    <Grid
      templateRows="100%"
      templateColumns="4fr 8fr"
      css={{
        minHeight: 'calc(100vh - 48px)',
      }}
    >
      <Flex
        direction="column"
        css={{
          borderRight: '1px solid $nonInteractiveOutline',
        }}
      >
        <Flex css={{ width: '100%', padding: '$4 $4 0 $4' }}>
          <UserSearchApp />
        </Flex>
        <CloseServiceModal />
        <UserListApp />
      </Flex>

      {serviceId ? (
        <Flex css={{ margin: '0 $4' }} gap="$4" direction="column">
          <ServiceControl key={serviceId ?? null} />
          <ResellerDetails />
        </Flex>
      ) : (
        <ResellerEmptyState />
      )}
    </Grid>
  );
};
