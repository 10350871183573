import { Container, Flex, Text } from '@grupoboticario/flora-react';

interface UserSearchEmptyStateProps {
  title?: string;
  message?: string;
}

const UserSearchEmptyState = ({ title, message }: UserSearchEmptyStateProps): JSX.Element => (
  <Container>
    <Flex direction="column" align="center" justify="center" css={{ height: '200px' }}>
      <Text align="center" color="$nonInteractiveAuxiliar" size="bodyMediumStandard">
        {title}
      </Text>
      <Text align="center" color="$nonInteractiveAuxiliar" size="auxiliarMini" css={{ maxWidth: '260px' }}>
        {message}
      </Text>
    </Flex>
  </Container>
);

export { UserSearchEmptyState };
