import { useEditResellerFormState } from '@/components/reseller-details/hooks';
import { TagStatus } from '@/components/shared';
import { ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons';

const generalErrorMessage =
  'Não foi possível salvar as alterações. Por favor, tente novamente clicando no botão ao final da página.';

const EditResellerDrawerErrorMessage = () => {
  const { editResellerFormState } = useEditResellerFormState();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {editResellerFormState.hasError && !editResellerFormState.isSubmitting && (
        <TagStatus variant="danger" statusText={generalErrorMessage} statusIcon={<ExclamationMarkHexagonIcon />} />
      )}
    </>
  );
};

export { EditResellerDrawerErrorMessage };
