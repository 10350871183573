import { Button, ButtonProps, Spinner } from '@grupoboticario/flora-react';

interface LoadButtonProps extends ButtonProps {
  label: string;
  isLoading?: boolean;
}

export const LoadButton = ({ label, isLoading = false, ...rest }: LoadButtonProps) => {
  return (
    <Button {...rest}>
      {isLoading && (
        <Spinner label="" size="xs" speed="800ms" thickness="2px" css={{ marginRight: '$2' }} data-testid="spinner" />
      )}
      {label}
    </Button>
  );
};
