import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Modal,
  Text,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Heading,
  Button,
} from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import { ServiceStatus, TabulateModalParams, myAttendancesKey } from '@/types';
import { Divider } from '@/components';
import { cancelService, events, finishService } from '@/api';

const handleServiceBehaviorError = () => events.error({ duration: 60000 });

export const CloseServiceModal = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const [serviceToClose, setIrToClose] = useState<Pick<TabulateModalParams, 'serviceId' | 'serviceStatus'> | undefined>(
    {
      serviceId: undefined,
      serviceStatus: ServiceStatus.OPEN,
    },
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    events.on('CLOSE_SERVICE_MODAL', serviceToCloseData => {
      setIrToClose(serviceToCloseData);
      setIsOpen(!!serviceToCloseData.serviceId);
    });
    return () => {
      events.off('CLOSE_SERVICE_MODAL');
    };
  }, []);

  if (!serviceToClose) {
    return null;
  }
  const { serviceId: userId, serviceStatus } = serviceToClose;

  const handleModalOpen = () => {
    setIsOpen(currState => !currState);
  };

  const handleServiceBehaviorSuccess = () => {
    navigate('/inicios/atendimento');
    mutate(myAttendancesKey);
  };

  const handleEndResellerService = async () => {
    if (userId) {
      try {
        await cancelService(userId);
        handleServiceBehaviorSuccess();
      } catch (error) {
        handleServiceBehaviorError();
      }
    }
  };

  const handleFinishService = async () => {
    if (userId) {
      try {
        await finishService(userId);
        handleServiceBehaviorSuccess();
      } catch (error) {
        handleServiceBehaviorError();
      }
    }
  };

  const handleCancelService = async () => {
    if (userId) {
      try {
        await cancelService(userId);
        handleServiceBehaviorSuccess();
      } catch (error) {
        handleServiceBehaviorError();
      }
    }
  };

  const finishOrCancelService = () => {
    if (serviceStatus === ServiceStatus.IN_ATTENDANCE) {
      return handleFinishService();
    }

    return handleCancelService();
  };

  const handleEndServiceVersion = () => (!serviceStatus ? handleEndResellerService() : finishOrCancelService());

  const handleConfirm = () => {
    handleEndServiceVersion();
    handleModalOpen();
  };

  const isServiceIsInAttendance = serviceStatus === ServiceStatus.IN_ATTENDANCE;

  return (
    <Modal open={isOpen} onOpenChange={handleModalOpen}>
      <ModalContent size="small">
        <ModalCloseButton />
        <ModalHeader>
          <Heading level="6">
            {isServiceIsInAttendance ? 'Atendimento da IR finalizado com sucesso' : 'Deseja realmente fechar?'}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Divider css={{ marginBottom: '$4', marginTop: 0 }} />
          <Text size="bodyMediumShort">
            {isServiceIsInAttendance
              ? 'Essa consulta será fechada e para nova consulta, será necessário buscar a IR novamente.'
              : 'Para nova consulta, será necessário buscar novamente.'}
          </Text>
          <Divider css={{ marginTop: '$14', marginBottom: 0 }} />
        </ModalBody>
        <ModalFooter>
          <Grid gap="$4" templateColumns="1fr 1fr" css={{ width: '100%' }}>
            <Button hierarchy="secondary" onClick={handleModalOpen} data-testid="cancel-button">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} data-testid="continue-button">
              Continuar
            </Button>
          </Grid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
