import { useResellerServiceCenter } from '@/contexts';

import { TextInfo } from '@/components/shared';
import { RequirementsList } from '@/components/reseller-details';
import {
  identificationRequirementsLabelMap,
  proofOfAddressRequirementsLabelMap,
  proofOfAddressSpecificationsRequirementsLabelMap,
  thirdPartySupportRequirementsLabelMap,
} from '@/components/reseller-details/labels';

const PickUpRequirements = (): JSX.Element => {
  // TODO verificar no null assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pickUpStore = useResellerServiceCenter().csData!.pickUpStore!;

  const identificationRequirementsList =
    pickUpStore.identificationRequirements?.map(requirement => ({
      text: identificationRequirementsLabelMap[requirement],
    })) ?? [];

  const proofOfAddressRequirementsList =
    pickUpStore.proofOfAddress?.map(requirement => ({
      text: proofOfAddressRequirementsLabelMap[requirement],
    })) ?? [];

  const proofOfAddressSpecificationsRequirementsList =
    pickUpStore.proofOfAddressSpecifications?.map(requirement => ({
      text: proofOfAddressSpecificationsRequirementsLabelMap[requirement],
    })) ?? [];

  const thirdPartySupportInfoRequirementsList =
    pickUpStore.thirdPartySupportInfo?.map(requirement => ({
      text: thirdPartySupportRequirementsLabelMap[requirement],
    })) ?? [];

  return (
    <>
      <RequirementsList title="Documentação exigida" listItems={identificationRequirementsList} />

      <RequirementsList
        title="Comprovante de endereço aceitos"
        listItems={proofOfAddressRequirementsList}
        copyBlockToClipboard={{
          copyButtonLabel: 'Copiar comprovantes',
          copyTitleMessage: 'Comprovantes de endereço aceitos',
          copyTextSuccess: 'Lista de comprovantes copiada',
        }}
        buttonLabel={{
          showMore: 'Mostrar mais comprovantes aceitos',
          showLess: 'Mostrar menos comprovantes aceitos',
        }}
      />

      <RequirementsList
        title="Condições gerais"
        copyBlockToClipboard={{
          copyButtonLabel: 'Copiar condições',
          copyTitleMessage: 'Condições gerais',
          copyTextSuccess: 'Lista de condições copiada',
        }}
        listItems={proofOfAddressSpecificationsRequirementsList}
        buttonLabel={{
          showMore: 'Mostrar mais condições gerais',
          showLess: 'Mostrar menos condições gerais',
        }}
      />

      {pickUpStore.hasScheduleForPickUp && (
        <TextInfo isFullWidth title="Condições de agendamento" text={pickUpStore.scheduleForPickUpNote} />
      )}

      <RequirementsList
        title="Documentação exigida em retirada por terceiros"
        listItems={thirdPartySupportInfoRequirementsList}
        copyBlockToClipboard={{
          copyButtonLabel: 'Copiar documentos',
          copyTitleMessage: 'documentação exigida em retirada por terceiros',
          copyTextSuccess: 'Lista de documentos copiada',
        }}
      />
    </>
  );
};

export { PickUpRequirements };
