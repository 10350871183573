import { DataWrapper, NoteField } from '@/components';
import { InstructionBox } from '@/components/reseller-details';
import { useResellerServiceCenter } from '@/contexts';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterLimit = () => {
  const { csData } = useResellerServiceCenter();

  if (csData?.lockAndLimit === undefined) {
    return null;
  }

  const { allowToInformAvailableLimit, note } = csData.lockAndLimit;

  return (
    <DataWrapper title="Condições de limite" data-testid="limit-wrapper">
      {allowToInformAvailableLimit ? (
        <InstructionBox
          title="Permite informar valor complementar de crédito."
          icon={<CheckCircleIcon data-testid="check-circle-icon-testid" size="32" color="$8" />}
          isFullWidth
        />
      ) : null}

      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { ServiceCenterLimit };
