import { DataWrapper, NoteField, TextInfo } from '@/components';
import { PaymentVoucherInstruction, PaymentVoucherView, useCopyDataWrapperInfo } from '@/components/reseller-details';
import { accountTypeValueLabelMap } from '@/components/reseller-details/labels';
import { AccountType, DepositAndTransferPayment } from '@/types';
import { cleanupSpecialCharacters, formatCpfOrCnpj } from '@/utils';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

interface DepositAndTransferPaymentViewProps {
  depositAndTransferPaymentData: DepositAndTransferPayment;
}

const DepositAndTransferPaymentView = ({ depositAndTransferPaymentData }: DepositAndTransferPaymentViewProps) => {
  const {
    requestsPaymentVoucher,
    accountNumber,
    accountType,
    bank,
    bankBranch,
    cpfCnpj,
    note,
    paymentVoucherEmail,
    paymentVoucherWhatsapp,
    sendTo,
    specifications,
  } = depositAndTransferPaymentData;

  const bankAccountData = [
    {
      id: 1,
      title: 'código do banco do favorecido',
      text: bank,
    },
    {
      id: 2,
      title: 'Dados da agência',
      text: bankBranch,
    },
    {
      id: 3,
      title: 'Tipo da conta',
      text: accountTypeValueLabelMap[accountType as AccountType],
    },
    {
      id: 4,
      title: 'Dados da conta',
      text: accountNumber,
    },
    {
      id: 5,
      title: 'CNPJ / CPF do favorecido',
      text: formatCpfOrCnpj(cpfCnpj),
      isFullWidth: true,
    },
  ];

  const bankAccountClipboardText = [
    `Dados da conta do favorecido:`,
    '',
    `Banco: ${bank}`,
    `Agência: ${bankBranch}`,
    `Tipo de conta: ${accountTypeValueLabelMap[accountType as AccountType]}`,
    `Conta: ${accountNumber}`,
    `CPF ou CNPJ do favorecido: ${cleanupSpecialCharacters(cpfCnpj ?? '')}`,
  ].join('\r\n');

  const { onCopy } = useCopyDataWrapperInfo({
    text: bankAccountClipboardText,
    successMessage: 'Lista de dados bancários copiada',
    section: 'dados bancários',
  });

  return (
    <DataWrapper
      title="Depósito e transferência"
      data-testid="deposit-transfer-payment"
      wrapperButtons={[
        {
          action: onCopy,
          label: 'Copiar dados bancários',
          icon: <TwoPiledSquaresIcon size={16} />,
        },
      ]}
    >
      <PaymentVoucherInstruction
        requestPaymentTitle="Solicitar o envio do comprovante do depósito ou transferência feito"
        requestsPaymentVoucher={requestsPaymentVoucher}
        sendTo={sendTo}
      />

      {bankAccountData.length > 0 &&
        bankAccountData.map(bankAccount => (
          <TextInfo
            key={bankAccount.id}
            title={bankAccount.title}
            text={bankAccount.text}
            isFullWidth={bankAccount.isFullWidth ?? false}
          />
        ))}

      <PaymentVoucherView
        toSendData={sendTo}
        proofOfPaymentOptions={specifications}
        phoneNumber={paymentVoucherWhatsapp}
        email={paymentVoucherEmail}
      />

      {!!note && <NoteField text={note} />}
    </DataWrapper>
  );
};

export { DepositAndTransferPaymentView };
