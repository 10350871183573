import { InstructionBox, PickUpDateLimit, PickUpRequirements } from '@/components/reseller-details';
import { pickUpInfoInstructionsLabelMap } from '@/components/reseller-details/labels';
import { DataWrapper, NoteField } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';

const ServiceCenterPickUp = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pickUpStore = useResellerServiceCenter().csData!.pickUpStore!;

  return (
    <DataWrapper title="Retirada">
      {(pickUpStore.pickUpStoreInfo ?? []).map(info => (
        <InstructionBox
          key={`${pickUpInfoInstructionsLabelMap[info]}__pickup-reqs-tags`}
          title={pickUpInfoInstructionsLabelMap[info]}
          icon={<CheckCircleIcon css={{ color: '$8' }} size="32" />}
          isFullWidth
        />
      ))}

      <PickUpRequirements />

      <PickUpDateLimit
        minimumPeriod={{
          hasMinimumPickUpPeriod: pickUpStore.hasMinimumPickUpPeriod,
          minimumPickUpPeriodInfo: pickUpStore.minimumPickUpPeriodInfo,
          minimumPickUpPeriodQty: pickUpStore.minimumPickUpPeriodQty,
        }}
        maximumPeriod={{
          hasMaximumPickUpPeriod: pickUpStore.hasMaximumPickUpPeriod,
          maximumPickUpPeriodInfo: pickUpStore.maximumPickUpPeriodInfo,
          maximumPickUpPeriodQty: pickUpStore.maximumPickUpPeriodQty,
        }}
      />

      {pickUpStore.note && <NoteField text={pickUpStore.note} />}
    </DataWrapper>
  );
};

export { ServiceCenterPickUp };
