import { ResellerVO } from '@/types';

type ServiceResellerType = Pick<ResellerVO, 'name' | 'cpf' | 'geraId' | 'hasFirstOrder'>;
export enum ServiceStatus {
  OPEN = 'open',
  IN_ATTENDANCE = 'inAttendance',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  CLOSED_TIME = 'closedByTime',
}

export interface ServiceStatusInfo {
  status: ServiceStatus;
  user: string;
  createdAt: string;
}

interface Users {
  userId: string;
  email: string;
  name: string;
}

export interface Service {
  _id: string;
  description: string;
  status: ServiceStatus;
  history: ServiceStatusInfo[];
  users: Users[];
  updatedAt?: string;
  reseller: ServiceResellerType;
}

export interface ServiceStart {
  createdAt: string;
  type: ServiceStatus;
}
