import { events, updateResellerNames, useEventTracker } from '@/api';
import { FormInput } from '@/components/form';
import { useEditReseller } from '@/components/reseller-details/hooks';
import { FormWrapper } from '@/components/shared';
import { formName, myAttendancesKey } from '@/types';
import { Box, Flex, Text } from '@grupoboticario/flora-react';
import { FormProvider } from 'react-hook-form';
import { EditNameDataType } from '../../types';
import { editResellerNameFormSchema } from './validators';

interface EditResellerNameFormProps {
  resellerNames: EditNameDataType;
  resellerGeraId?: string;
}

const EditResellerNameForm = ({ resellerNames, resellerGeraId }: EditResellerNameFormProps) => {
  const {
    methods,
    handleSubmit,
    hasChangesOnForm,
    setHasFormError,
    hasFormError,
    submittedResellerFormMessage,
    mutate,
  } = useEditReseller({
    schema: editResellerNameFormSchema,
    initialData: resellerNames,
    geraId: resellerGeraId,
  });

  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();

  const onSubmit = async (data: EditNameDataType) => {
    if (resellerGeraId === undefined) {
      setHasFormError(true);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerName,
        errorMessage: 'GeraID is not defined',
      });
      return hasFormError;
    }

    if (!hasChangesOnForm) {
      events.emit('CLOSE_DRAWER_INFO');
      return null;
    }

    try {
      const response = await updateResellerNames(resellerGeraId, data);
      mutate(myAttendancesKey);
      submittedResellerFormMessage();
      resellerInfoEditDataSubmitted({
        editFormName: formName.resellerName,
      });
      return response;
    } catch (err) {
      setHasFormError(!!err);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerName,
        errorMessage: err.message,
      });
      throw err;
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-address-form">
      <Text as="h3" size="bodyMediumShort" css={{ paddingLeft: '$4' }}>
        Contato
      </Text>

      <FormProvider {...methods}>
        <Box as="form" id="reseller-form" onSubmit={handleSubmit(onSubmit)} data-testid="edit-name-form">
          <FormWrapper css={{ background: '$light-dark-1', padding: '$4' }}>
            <Box css={{ gridColumn: 'span 12' }}>
              <FormInput
                registerName="socialName"
                data-testid="input-social-name"
                id="socialName"
                labelText="Nome Social:"
                optionalText="*"
              />
            </Box>

            <Box css={{ gridColumn: 'span 12' }}>
              <FormInput
                registerName="name"
                data-testid="input-registry-name"
                id="name"
                labelText="Nome de Registro:"
                optionalText="*"
              />
            </Box>
          </FormWrapper>
        </Box>
      </FormProvider>
    </Flex>
  );
};

export { EditResellerNameForm };
