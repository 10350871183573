import { events, useEventTracker } from '@/api';
import { useResellerDataEditEnabler } from '@/components/reseller-details/hooks/';
import { getPhoneNumberByType, getResellerEmail } from '@/components/reseller-details/utils';
import { DataWrapper, DataWrapperButton, TextInfo } from '@/components/shared';
import { EditResellerContact, EditResellerDrawerFooter } from '@/components/reseller-details';
import { useResellerDetails } from '@/contexts';
import { ResellerPhoneType } from '@/types';
import { PencilIcon } from '@grupoboticario/flora-react-icons';
import { cleanupSpecialCharacters, formatPhoneNumber } from '@/utils';

// ver memo
const ResellerContact = (): JSX.Element => {
  const { data } = useResellerDetails().resellerRegistry;
  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();
  const mobilePhoneNumber = getPhoneNumberByType(ResellerPhoneType.MOBILE, data?.phones);
  const homePhoneNumber = getPhoneNumberByType(ResellerPhoneType.HOME, data?.phones);

  const { clickedResellerInfoButtons } = useEventTracker();

  const attendanceInfoData = {
    geraId: data?.geraId,
    socialName: data?.socialName,
    cpCs: data?.commercialStructure?.parentStructure.name,
  };

  const email = getResellerEmail(data?.emails)?.toLocaleLowerCase();

  const resellerInitialContactData = {
    phones: data?.phones ?? [],
    emails: data?.emails ?? [],
  };

  const wrapperButtons: DataWrapperButton[] = [
    {
      disabled: isEditingDisabled,
      label: 'Alterar dados',
      tooltipText: disabledButtonTooltip,
      action: () => {
        clickedResellerInfoButtons({
          section: 'alterar-dados-contato',
        });

        events.emit('OPEN_DRAWER_INFO', {
          size: 'medium',
          title: 'Altere os dados de contato:',
          content: (
            <EditResellerContact
              attendanceInfo={attendanceInfoData}
              resellerInitialContactData={resellerInitialContactData}
            />
          ),
          footer: <EditResellerDrawerFooter />,
        });
      },
      icon: <PencilIcon size={18} data-testid="icon-button" />,
    },
  ];

  return (
    <DataWrapper title="Contato" data-testid="reseller-contact" wrapperButtons={wrapperButtons}>
      <TextInfo
        canCopyToClipboard
        textToCopy={cleanupSpecialCharacters(mobilePhoneNumber?.phone ?? '')}
        title="Celular"
        text={formatPhoneNumber(mobilePhoneNumber?.phone)}
      />
      <TextInfo
        canCopyToClipboard
        textToCopy={cleanupSpecialCharacters(homePhoneNumber?.phone ?? '')}
        title="Telefone Residencial"
        text={formatPhoneNumber(homePhoneNumber?.phone)}
      />
      <TextInfo canCopyToClipboard isFullWidth title="E-Mail" text={email} />
    </DataWrapper>
  );
};

export { ResellerContact };
