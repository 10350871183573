import { Flex } from '@grupoboticario/flora-react';
import {
  AttendanceInfoData,
  EditResellerDrawerErrorMessage,
  AttendanceInfo,
  EditDocumentsType,
  EditResellerDocumentStatusForm,
} from '@/components/reseller-details';

interface EditDocumentStatusProps {
  attendanceInfo: AttendanceInfoData;
  initialDocumentStatusData: EditDocumentsType | undefined;
}

const EditDocumentStatus = ({ attendanceInfo, initialDocumentStatusData }: EditDocumentStatusProps) => (
  <Flex data-testid="edit-reseller-document-wrapper" direction="column" gap="$6" css={{ marginTop: '$5' }}>
    <EditResellerDrawerErrorMessage />
    <AttendanceInfo attendanceInfo={attendanceInfo} />
    <EditResellerDocumentStatusForm
      initialDocumentStatusData={initialDocumentStatusData}
      resellerGeraId={attendanceInfo.geraId}
    />
  </Flex>
);

export { EditDocumentStatus };
