const ignoredWords = ['do', 'dos', 'da', 'das', 'de'];

export const capitalizeText = (text: string) =>
  text
    .toLocaleLowerCase()
    .trim()
    .split(' ')
    .map(splitText =>
      ignoredWords.includes(splitText) ? splitText : splitText.charAt(0).toUpperCase() + splitText.slice(1),
    )
    .join(' ');
