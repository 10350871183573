import { pickUpLimitInfoLabelMap } from '@/components/reseller-details/labels';
import { TextInfo } from '@/components/shared';
import { PickUpLimitInfo, PickUpStore } from '@/types';

type MinimumPickUpDateLimitType = Pick<
  PickUpStore,
  'hasMinimumPickUpPeriod' | 'minimumPickUpPeriodQty' | 'minimumPickUpPeriodInfo'
>;

type MaximumPickUpDateLimitType = Pick<
  PickUpStore,
  'hasMaximumPickUpPeriod' | 'maximumPickUpPeriodQty' | 'maximumPickUpPeriodInfo'
>;

interface PickUpDateLimitProps {
  minimumPeriod: MinimumPickUpDateLimitType;
  maximumPeriod: MaximumPickUpDateLimitType;
}

const getMessageForPickUpLimit = (
  minimumPeriod: MinimumPickUpDateLimitType,
  maximumPeriod: MaximumPickUpDateLimitType,
) => {
  const defaultMessage = pickUpLimitInfoLabelMap[PickUpLimitInfo.NONE];
  const onlyMinimumPickUpPeriod = !maximumPeriod.hasMaximumPickUpPeriod;
  const onlyMaximumPickUpPeriod = !minimumPeriod.hasMinimumPickUpPeriod;

  if (!minimumPeriod.hasMinimumPickUpPeriod && !maximumPeriod.hasMaximumPickUpPeriod) {
    return defaultMessage;
  }

  if (onlyMinimumPickUpPeriod && minimumPeriod.minimumPickUpPeriodInfo) {
    return `Mínimo de ${minimumPeriod.minimumPickUpPeriodQty} ${
      pickUpLimitInfoLabelMap[minimumPeriod.minimumPickUpPeriodInfo]
    }`;
  }

  if (onlyMaximumPickUpPeriod && maximumPeriod.maximumPickUpPeriodInfo) {
    return `Máximo de ${maximumPeriod.maximumPickUpPeriodQty} ${
      pickUpLimitInfoLabelMap[maximumPeriod.maximumPickUpPeriodInfo]
    }`;
  }

  if (maximumPeriod.maximumPickUpPeriodInfo) {
    return `Entre ${minimumPeriod.minimumPickUpPeriodQty} à ${maximumPeriod.maximumPickUpPeriodQty} ${
      pickUpLimitInfoLabelMap[maximumPeriod.maximumPickUpPeriodInfo]
    }`;
  }

  return defaultMessage;
};

const PickUpDateLimit = ({ minimumPeriod, maximumPeriod }: PickUpDateLimitProps): JSX.Element => {
  const message = getMessageForPickUpLimit(minimumPeriod, maximumPeriod);

  return <TextInfo isFullWidth title="Prazo de retirada" text={message} />;
};

export { PickUpDateLimit };
