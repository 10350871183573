import { events } from '@/api';
import { Divider } from '@/components';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from '@grupoboticario/flora-react';
import { ReactNode, useEffect, useState } from 'react';

export interface DrawerInfoData {
  title: string;
  content: ReactNode | string;
  footer?: ReactNode;
  size?: 'small' | 'medium';
}

export const DrawerInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [info, setInfo] = useState<DrawerInfoData>();

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    events.on('OPEN_DRAWER_INFO', data => {
      setIsOpen(true);
      setInfo(data);
    });

    events.on('CLOSE_DRAWER_INFO', () => {
      setIsOpen(false);
    });

    return () => {
      events.off('OPEN_DRAWER_INFO');
      events.off('CLOSE_DRAWER_INFO');
    };
  }, []);

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerContent
        css={{
          width: '100%',
          maxWidth: info?.size === 'medium' ? '700px' : '500px',
        }}
      >
        <DrawerCloseButton />
        <DrawerHeader>{info?.title}</DrawerHeader>
        <DrawerBody
          css={{
            paddingTop: 0,
            height: '100vh',
            minHeight: '200px',
          }}
        >
          <Divider />
          {info?.content}
        </DrawerBody>
        <DrawerFooter css={{ flexDirection: 'column' }}>
          <Divider />
          {info?.footer ? (
            info?.footer
          ) : (
            <Button isFull onClick={handleCloseDrawer}>
              Fechar
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
