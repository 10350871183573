import { Tag, TagProps, CSS } from '@grupoboticario/flora-react';

interface TagListProps {
  tagsValue: string[];
  tagVariant?: TagProps['variant'] | 'assorted-8';
}

const TagList = ({ tagsValue, tagVariant }: TagListProps): JSX.Element => {
  const assorted8: CSS = tagVariant === 'assorted-8' ? { color: '$8', borderColor: '$8' } : {};
  return (
    <>
      {tagsValue.map(tagValue => (
        <Tag
          data-testid="tag-item"
          key={tagValue}
          shape="ghost"
          variant={tagVariant as TagProps['variant']}
          css={{ fontWeight: '$medium', ...assorted8 }}
        >
          {tagValue}
        </Tag>
      ))}
    </>
  );
};

export { TagList };
