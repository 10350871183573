export enum BusinessUnit {
  BOTICARIO = 'Boticario',
  EUDORA = 'Eudora',
  VDF = 'VDF',
}

export enum UserFeature {
  VIEW_SERVICE_CENTER_ROUTER = 'view-checklist',
  VIEW_ATTENDANCE_ROUTE = 'view-attendance',
  VIEW_ADMIN_ROUTE = 'view-admin',
  CREATE_SERVICE_CENTER = 'create-checklist',
}

export enum UserRole {
  ADMIN = 'administrador',
  ASSISTENTE_VENDAS = 'attendant',
  LIDER_OPERACIONAL = 'supervisor',
}
export interface UserInfo {
  businessUnit: BusinessUnit;
  email: string;
  geraId: string;
  name: string;
  roles: UserRole[];
  features: UserFeature[];
}
