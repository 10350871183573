import { Flex } from '@grupoboticario/flora-react';
import { AttendanceInfoData, EditResellerDrawerErrorMessage, AttendanceInfo } from '@/components/reseller-details/';
import { EditResellerContactForm } from './components';
import { EditContactData } from './types';

interface EditResellerContactProps {
  attendanceInfo: AttendanceInfoData;
  resellerInitialContactData: EditContactData | undefined;
}

const EditResellerContact = ({ attendanceInfo, resellerInitialContactData }: EditResellerContactProps) => (
  <Flex data-testid="edit-reseller-contact-wrapper" direction="column" gap="$6" css={{ marginTop: '$5' }}>
    <EditResellerDrawerErrorMessage />
    <AttendanceInfo attendanceInfo={attendanceInfo} />
    <EditResellerContactForm initialData={resellerInitialContactData} resellerGeraId={attendanceInfo.geraId} />
  </Flex>
);

export { EditResellerContact };
