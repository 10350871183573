interface DaysAndHours {
  days: string;
  hours: string;
}

export const generateBusinessHoursOnCopyText = (
  normalDaysData: DaysAndHours[],
  holidaysData: string,
  note?: string,
) => {
  const daysAndHours = normalDaysData.map(dayData => `${dayData.days}: ${dayData.hours}`);

  if (holidaysData) {
    daysAndHours.push(`Feriados: ${holidaysData}`);
  }

  const workingHoursText = `Horário de funcionamento:\r\n${
    daysAndHours.length > 0 ? daysAndHours.join('\r\n') : 'Horário de funcionamento não informado'
  }`;

  const noteText = note ? `\r\n\r\nObservações:\r\n${note}` : '';

  return `${workingHoursText}${noteText}`;
};
