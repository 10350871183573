import { CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import { DataWrapper, NoteField, TextInfo } from '@/components/shared';
import { useResellerServiceCenter } from '@/contexts';
import { InstructionBox, NOT_SPECIFIED, ServiceCenterProductList } from '@/components/reseller-details';
import { starterKitInstructionValueLabelMap } from '@/components/reseller-details/labels';

const getInstructions = (allowStarterKit: boolean, onlyIfStarterKitIsUnavailable: boolean) => {
  if (!allowStarterKit) {
    return {
      message: 'Não permite adicionar produtos na primeira compra.',
      icon: <CrossCircleIcon data-testid="cross-circle-icon-testid" size="32" color="$alert-standard" />,
    };
  }

  return {
    message: onlyIfStarterKitIsUnavailable
      ? 'Se o kit inicial estiver indisponível, realizar primeiro pedido com produtos.'
      : 'Permite realizar primeiro pedido com produtos, independente da disponibilidade do kit inicial.',
    icon: <CheckCircleIcon data-testid="check-circle-icon-testid" size="32" color="$8" />,
  };
};

const StarterPackInfo = (): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const starterPack = useResellerServiceCenter().csData!.starterPack!;
  const instructions = getInstructions(starterPack.allowStarterKit, starterPack.onlyIfStarterKitIsUnavailable);

  const starterKitInstructions = starterPack.hasStarterKitInstruction
    ? (starterPack.starterKitInstruction ?? []).map(instruction => starterKitInstructionValueLabelMap[instruction])
    : undefined;

  return (
    <DataWrapper title="Início com produto">
      <InstructionBox title={instructions.message} icon={instructions.icon} isFullWidth />
      <TextInfo title="Orientações" text={starterKitInstructions} fallbackText={NOT_SPECIFIED} isFullWidth />

      {starterPack.hasSpecificProducts ? (
        <ServiceCenterProductList products={starterPack.specificProducts ?? []} />
      ) : (
        <TextInfo title="Produtos permitidos" fallbackText={NOT_SPECIFIED} isFullWidth />
      )}
      {starterPack.note && <NoteField text={starterPack.note} />}
    </DataWrapper>
  );
};

export { StarterPackInfo };
