import { events, useEventTracker } from '@/api';
import { ServiceStatus, myAttendancesKey } from '@/types';
import { Toaster } from '@grupoboticario/flora-react';
// eslint-disable-next-line import/no-unresolved
import { useModalTabulation } from '@vdi/tabulation';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

interface UseHandleCloseServiceParams {
  serviceId: string | undefined;
  serviceStatus?: ServiceStatus;
  geraId?: string;
  registryName?: string;
}

export const useHandleCloseService = ({
  serviceId,
  serviceStatus,
  geraId,
  registryName,
}: UseHandleCloseServiceParams) => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { startFinishService } = useEventTracker();

  const tabulateModal = useModalTabulation({
    header: {
      title: 'Registre o atendimento:',
    },
    footer: {
      cancelButtonLabel: 'Cancelar',
      confirmButtonLabel: 'Confirmar',
    },
    status: {
      closeOnSuccess: true,
      loadingTitle: 'Carregando',
      loadingDescription: 'Aguarde um momento',
      errorDescription: 'Ops... aconteceu um erro',
      onTabulateSuccess: () => {
        Toaster.notify({
          description: 'Atendimento finalizado com sucesso',
          origin: 'right-top',
          kind: 'success',
        });
        navigate('/inicios/atendimento');
        mutate(myAttendancesKey);
      },
    },
    form: {
      reCodeLabel: 'CÓDIGO',
      reNameLabel: 'NOME DE REGISTRO',
      reCode: geraId,
      reName: registryName,
      reTypeLabel: 'IR Atendida',
      requiredMessage: 'Campo obrigatório',
      reasonLabel: 'Motivo',
      reasonDisabled: false,
    },
    api: {
      baseUrl: process.env.BFF_BASE_URL,
      formFieldsEndpoint: '/v1/services/tab/fields',
      timeout: 15000,
      reasonsEndpoint: '/v1/services/tab/listreason',
      sendTabulationEndpoint: `/v1/services/${serviceId}/tab`,
    },
  });

  const handleCloseService = () => {
    if (serviceId && serviceStatus === ServiceStatus.IN_ATTENDANCE) {
      startFinishService({
        interactionDetail: ServiceStatus.FINISHED,
      });
      return tabulateModal.open();
    }

    return events.emit('CLOSE_SERVICE_MODAL', {
      serviceId,
      serviceStatus,
    });
  };

  return { handleCloseService };
};
