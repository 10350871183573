import { CheckCircleIcon } from '@grupoboticario/flora-react-icons';
import { InstructionBox } from '@/components/reseller-details';
import { TextInfo } from '@/components/shared';

interface SpecialConditionsProps {
  deliveryTitle: string;
  normalDeliveryCost?: string;
  specialDeliveryCost?: string;
}

const DeliveryValuesAndConditions = ({
  deliveryTitle,
  normalDeliveryCost,
  specialDeliveryCost,
}: SpecialConditionsProps): JSX.Element => {
  return (
    <>
      <InstructionBox title={deliveryTitle} icon={<CheckCircleIcon css={{ color: '$8' }} size="32" />} isFullWidth />

      {specialDeliveryCost !== undefined && (
        <>
          <TextInfo canCopyToClipboard title="Valor do frete normal" text={normalDeliveryCost} />
          <TextInfo canCopyToClipboard title="Valor do frete especial" text={specialDeliveryCost} />
        </>
      )}
    </>
  );
};

export { DeliveryValuesAndConditions };
