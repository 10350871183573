import { events } from '@/api';
import { resellerRegistryKey } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual } from 'lodash';
import { useState, useEffect } from 'react';
import { UseFormReturn, FieldValues, DeepPartial, useForm, useWatch } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import { useUpdateResellerFormHandler } from './useUpdateResellerFormHandler';
import { AnyObjectSchema } from 'yup';

export interface EditResellerValue {
  hasChangesOnForm: boolean;
  methods: UseFormReturn;
}

interface EditResellerProviderProps<T extends FieldValues> {
  initialData: DeepPartial<T>;
  schema?: AnyObjectSchema;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFormatter?: (data: any) => any;
  geraId?: string;
  registryKey?: string;
  formName?: string;
}

export const useEditReseller = <T extends FieldValues>({
  schema,
  initialData,
  dataFormatter,
  geraId,
  registryKey = resellerRegistryKey,
}: EditResellerProviderProps<T>) => {
  const [hasChangesOnForm, setHasChangesOnForm] = useState(true);
  const { mutate } = useSWRConfig();

  const { submittedResellerFormMessage, hasFormError, setHasFormError } = useUpdateResellerFormHandler(
    geraId,
    registryKey,
  );

  const methods = useForm<T>({
    ...(schema ? { resolver: yupResolver(schema) } : {}),
    mode: 'onBlur',
    defaultValues: initialData,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, defaultValues, errors },
    control,
    getValues,
  } = methods;

  const watchValues = useWatch({ control });

  useEffect(() => {
    setHasChangesOnForm(
      dataFormatter
        ? !isEqual(dataFormatter(defaultValues), dataFormatter(watchValues))
        : !isEqual(defaultValues, watchValues),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchValues]);

  useEffect(() => {
    events.emit('HAS_CHANGES_ON_FORM', hasChangesOnForm);
  }, [hasChangesOnForm]);

  useEffect(() => {
    events.emit('UPDATE_RESELLER_DATA', {
      isSubmitting,
      hasError: hasFormError,
    });
  }, [hasFormError, isSubmitting]);

  return {
    hasChangesOnForm,
    methods,
    isSubmitting,
    defaultValues,
    handleSubmit,
    getValues,
    setHasFormError,
    hasFormError,
    submittedResellerFormMessage,
    mutate,
    errors,
  };
};
