import { useGetService } from '@/api';
import { ServiceRouteParamsType, ServiceStatus } from '@/types';
import { useParams } from 'react-router-dom';

export const useResellerDataEditEnabler = () => {
  const { serviceId } = useParams<ServiceRouteParamsType>();
  const { service } = useGetService(serviceId);

  const isServiceStatusOpen = service?.status === ServiceStatus.OPEN;

  return {
    isEditingDisabled: isServiceStatusOpen ?? false,
    disabledButtonTooltip: isServiceStatusOpen
      ? 'Para realizar a ação, inicie o controle de atendimento no topo da página da consulta aberta'
      : undefined,
  };
};
