import { events, updateResellerAddress, useEventTracker } from '@/api';
import { FormInput } from '@/components/form';
import { useEditReseller } from '@/components/reseller-details/hooks';
import { FormWrapper } from '@/components/shared';
import { ResellerAddressType, formName } from '@/types';
import { formatCep, cleanupSpecialCharacters } from '@/utils';
import { Box, Flex, Text } from '@grupoboticario/flora-react';
import { FormProvider } from 'react-hook-form';
import { InitialAddressData } from '../types';
import { useZipCodeUpdateHandler } from './hooks/useZipCodeUpdateHandler';
import { editResellerAddressSchema } from './validators';
import { useEffect } from 'react';

interface EditResellerAddressFormProps {
  initialAddress: InitialAddressData | undefined;
  commercialStructureCode: string | undefined;
  resellerGeraId?: string;
}

const EditResellerAddressForm = ({
  initialAddress,
  commercialStructureCode,
  resellerGeraId,
}: EditResellerAddressFormProps) => {
  const initialAddressFormattedValue = {
    ...initialAddress,
    complement: initialAddress?.complement ?? '',
    reference: initialAddress?.reference ?? '',
    type: ResellerAddressType.DELIVERY,
    zipCode: formatCep(initialAddress?.zipCode),
    csCode: commercialStructureCode,
    initialZipCode: formatCep(initialAddress?.zipCode),
  };

  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();
  const { methods, handleSubmit, hasChangesOnForm, setHasFormError, submittedResellerFormMessage, errors } =
    useEditReseller<InitialAddressData>({
      schema: editResellerAddressSchema,
      initialData: initialAddressFormattedValue,
      geraId: resellerGeraId,
    });

  const { onZipCodeChange, isReadOnly } = useZipCodeUpdateHandler(initialAddress, methods);

  useEffect(() => {
    if (errors.zipCode) {
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerAddress,
        errorMessage: errors.zipCode.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.zipCode]);

  const onSubmit = async (data: InitialAddressData) => {
    if (resellerGeraId === undefined) {
      setHasFormError(true);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerAddress,
        errorMessage: 'Gera ID não pode ser nulo',
      });

      throw new Error('Gera ID não pode ser nulo');
    }
    const formattedData = {
      ...data,
      type: ResellerAddressType.DELIVERY,
      zipCode: cleanupSpecialCharacters(data.zipCode),
    };

    if (!hasChangesOnForm) {
      events.emit('CLOSE_DRAWER_INFO');
      return null;
    }

    try {
      const response = await updateResellerAddress(resellerGeraId, formattedData);
      resellerInfoEditDataSubmitted({
        editFormName: formName.resellerAddress,
      });
      submittedResellerFormMessage();
      return response;
    } catch (err) {
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerAddress,
        errorMessage: err.message,
      });
      setHasFormError(true);
      throw err;
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-address-form">
      <Text as="h3" size="bodyMediumShort" css={{ paddingLeft: '$4' }}>
        Endereço
      </Text>

      <FormProvider {...methods}>
        <Box as="form" onSubmit={handleSubmit(onSubmit)} id="reseller-form">
          <FormWrapper css={{ background: '$light-dark-1', padding: '$4' }}>
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                {...(methods.register, { mode: 'onChange' })}
                registerName="zipCode"
                data-testid="input-zipcode"
                id="cep"
                labelText="CEP:"
                placeholder="_____-___"
                mask="xxxxx-xxx"
                optionalText="*"
                onChange={onZipCodeChange}
              />
            </Box>
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName="street"
                data-testid="input-street"
                id="street"
                labelText="Logradouro:"
                placeholder="Rua, Avenida, Alameda etc"
                optionalText="*"
                readOnly={isReadOnly.street}
              />
            </Box>
            <Box css={{ gridColumn: 'span 4' }}>
              <FormInput
                registerName="number"
                data-testid="input-number"
                id="number"
                labelText="Número:"
                placeholder="__"
                optionalText="*"
              />
            </Box>
            <Box css={{ gridColumn: 'span 4' }}>
              <FormInput
                registerName="complement"
                data-testid="input-complement"
                id="complement"
                labelText="Complemento:"
              />
            </Box>
            <Box css={{ gridColumn: 'span 4' }}>
              <FormInput
                registerName="landmark"
                data-testid="input-landmark"
                id="landmark"
                labelText="Bairro:"
                placeholder="Ex. Centro"
                optionalText="*"
                readOnly={isReadOnly.landmark}
              />
            </Box>
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName="city"
                data-testid="input-city"
                id="city"
                labelText="Cidade:"
                placeholder="Ex. São Paulo"
                optionalText="*"
                readOnly
              />
            </Box>
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName="state"
                data-testid="input-state"
                id="state"
                labelText="Estado:"
                placeholder="Ex. São Paulo"
                optionalText="*"
                readOnly
              />
            </Box>
            <Box css={{ gridColumn: 'span 12' }}>
              <FormInput
                registerName="reference"
                data-testid="input-reference"
                id="reference"
                labelText="Ponto de referência:"
              />
            </Box>
          </FormWrapper>
        </Box>
      </FormProvider>
    </Flex>
  );
};

export { EditResellerAddressForm };
