import { useEffect, useState } from 'react';

const getItemsToShow = <T>(items: T[], isListExpansible: boolean, isListExpanded: boolean) => {
  if (!isListExpansible || (isListExpansible && isListExpanded)) {
    return items;
  }

  return items.slice(0, 3);
};

export function useListContentHandler<T>(items: T[], thresholdToShowControlButton = 5) {
  const isListExpansible = items.length > thresholdToShowControlButton;

  const [isListExpanded, setIsListExpanded] = useState<boolean>(false);
  const [itemsToShow, setItemsToShow] = useState(() => {
    return isListExpansible ? items.slice(0, 3) : items;
  });

  const toggleListItems = () => setIsListExpanded(prevState => !prevState);

  useEffect(() => {
    setItemsToShow(getItemsToShow<T>(items, isListExpansible, isListExpanded));
  }, [isListExpanded, isListExpansible, items]);

  return {
    toggleListItems,
    itemsToShow,
    isListExpanded,
    isListExpansible,
  };
}
